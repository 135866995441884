import React, { Component, useEffect, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
// import { useLastLocation } from "react-router-last-location";

import Home from "./Pages/Home/Home";
import Layout from "components/Layout/Layout";
// import { getLastLocation, saveLastLocation } from "components/utils/utilFunctions";
import objectPath from "object-path";
import LoaderFixed from "./Loader/LoaderFixed";

export const Routes = withRouter(({ history }) => {
  return (
    <Layout history={history}>
      <Suspense fallback={<LoaderFixed />}>
      <Switch>
        <Route path="/" component={Home} />
      </Switch>
      </Suspense>
    </Layout>
  );
});
export default Routes;
