import React, { Component } from "react";

import "./Receipt.scss";
import "./Home.scss";
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from "axios";
import $, { parseHTML } from "jquery";
import GoogleMapReact from "google-map-react";
import Slider from "react-slick";
import DatePicker, { registerLocale } from "react-datepicker";

import ro from "date-fns/locale/ro";
import getDay from "date-fns/getDay";
import { css, cx } from "emotion";

import ReactGA, { event } from "react-ga";
import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";

import distance from "google-distance-matrix";

import htmlParse from "html-react-parser";

import objectPath from "object-path";

import { TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { actions } from "store/reducers/builderReducer";

import * as helperFunctions from "utils/helper_functions";
import * as constants from "../../../utils/constants";
import HomeHelper from "./HomeHelper";

import Popup from "reusableComponents/Popup/Popup";
import AnimationItem from "reusableComponents/AnimationItem/AnimationItem";

import { Checkbox } from "reusableComponents/Checkbox/Checkbox";
import PopupFrame from "reusableComponents/Popup/Frame";
import Button from "reusableComponents/Button/Button";
import Footer from "components/HomeFooter/HomeFooter";
import ContentPlainTextModal from "reusableComponents/Popup/ContentPlainText";
import ContentProgramModal from "reusableComponents/Popup/ContentProgram";
import Dropdown from "reusableComponents/Dropdown/Dropdown";

import PromoCyrcle from "reusableComponents/PromoIcon/PromoIcon";

import defaultBgImage from "assets/img/background.png";
import q from "assets/img/q.png";
import pby from "assets/img/pby.png";
import pin_map from "assets/img/pin_contact.png";

import { ReceiptCheckboxSelect } from "reusableComponents/Receipt/ReceiptCheckboxSelect";

import CookiesRequest from "components/CookiesRequest/CookiesRequest";
import { ProductItem } from "reusableComponents/ProductItem/ProductItem";
import FeedbackPopup from "reusableComponents/Popup/FeedbackPopup/FeedbackPopup";
import ProceedPopup from "reusableComponents/Popup/ProceedPopup/ProceedPopup";

import Tips from "components/Tips/Tips";
import PromoCode from "components/PromoCode/PromoCode";
import PaymentTypeForm from "components/PaymentTypeForm/PaymentMethods";
import Loader from "components/Loader/Loader";
import LayoutLoaderFixed from "components/Loader/LoaderFixed";
import { SimpleDropdown } from "reusableComponents/SimpleDropdown/SimpleDropdown";

import * as cssGen from "./CssGenerator";
import PopupItemContainer from "./../../../reusableComponents/PopupItemContainer/PopupItemContainer";

let Decimal = require("decimal.js");

registerLocale("ro", ro);
export const AnyReactComponent = ({ img_src }) => (
  <div className="react-pin-image">
    <img src={img_src} className="react_map" style={{}} />
  </div>
);

function parse(string) {
  if (typeof string === "string") {
    return htmlParse(string);
  }
  return "";
}

class Home extends Component {
  constructor(props) {
    super(props);
    this._page_container_ref = React.createRef();
    this._command_section_ref = React.createRef();
    this.scroll_container_receipt = React.createRef();
    this._nav_bar_ref = React.createRef();
    this.command_receipt_items_container_ref = React.createRef();
    /**
     * @type {import("./CssGenerator").IHomeState}
     */
    const state = {
      day_closed: [],
      sectionsOrdered: JSON.parse(JSON.stringify(constants.sectionsOrdered)),
      preferenceUpdate: 0,
      date_blocate_array: [],
      sliderImages: [],
      markers: [],
      pin: pin_map,
      id_venue: "",
      client_nume: "",
      client_telefon: "",
      client_email: "",
      client_persoane: "1",
      program_list_elements: [],
      client_mesaj: "",
      id_venue: "",
      showErrors: false,
      trimitere_ok: false,
      eroare: false,
      name_venue: "",
      open_loader: { active: true },
      disabled_color: "#bfbfbf",
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },

      sel_command_cat_index: 0,
      isFetchingScrollAnim: {},
      command_categories: [],
      product_items: [],
      shipping_form: JSON.parse(JSON.stringify(constants.lazy_form_init)),
      shippingFromShowErrors: false,
      command_cart_products: {},
      deliver: false,
      modals: [],

      minDeliveryDate: null,
      minDeliveryDateBasedOnCart: null,
      strictDeliveryDateBasedOnCart: null,

      command_closed_content_popup: {
        type: "program",
        command_time_program: [
          {
            day_st: "Marți",
            day_end: "Miercuri",
            h_st: "12:00",
            h_end: "20:00",
          },
          {
            day_st: "Sâmbătă",
            day_end: "Duminică",
            h_st: "12:00",
            h_end: "21:00",
          },
        ],
      },
      receipt_dropdown: JSON.parse(constants.lazy_receipt_dropdown_init),

      zoom: 17,
      delivery_price_array: [],
      free_delivery_from: Number.MAX_VALUE,

      enable_tips: undefined,
      tip_percentages: undefined,
      tips_other_text: "",
      tips_main_text: "",
      tipValue: null,

      always_show_location: undefined,

      facebook_pixel_id: undefined,
      facebook_pixel_email: undefined,

      custom_order_popup_text: undefined,

      disable_online_payment: undefined,
      enable_cash_payment: undefined,
      enable_card_payment: undefined,
      default_payment_type: undefined,

      first_screen_logo_width_desktop: undefined,
      first_screen_logo_width_mobile: undefined,

      first_screen_description_style_classname: undefined,

      productDeliveryRules: {},

      termsAndConditonLink: undefined,
      confTermsLink: undefined,
      cookiesTermsLink: undefined,

      promoValue: "",
      valid_hours_reservation: [],
      valid_hours_reservation_stay: [],

      date_blocate_array: [],
      ore_blocate_array: [],
      hour_reservation: "",
      hour_reservation_stay: "",
      date_string_reservation: "",

      venue_counties: [],
      venue_county_names: [],
    };
    this.state = state;
  }
  scroll_Y_window = 0;
  scroll_y_locked;
  mob_input_focus;
  venues_optional_popup = constants.venes_option_pop_const;

  checkCookies = () => {
    this.setState({
      cookies: this.get_info_parsed_l_st_venue(this.state.id_venue, "cookies"),
    });
  };
  setupCustomCss = () => {
    this.setState({
      reservationDatepickerCss: cssGen.generateReservationDatepickerCss(
        this.state
      ),
      hourSelectCss: cssGen.generateHourSelectCss(this.state),
      sizesSelectCss: cssGen.generateSizeSelectCss(this.state),
      cartDatepickerCss: cssGen.generateCartDatePickerCss(this.state),
      reservationFormularCss: cssGen.generateReservationFormularCss(this.state),
      productItemCss: cssGen.generateProductItemCss(this.state),
    });
  };
  eventListenerSetup = (add) => {
    if (add) {
      window.addEventListener("scroll", this.on_scroll_window);
      window.addEventListener("wheel", this.on_scroll_window);
      window.addEventListener("resize", this.on_resize_window);
      window.addEventListener("focusin", this.focusReactPicker);
    } else {
      window.removeEventListener("scroll", this.on_scroll_window);
      window.removeEventListener("wheel", this.on_scroll_window);
      window.removeEventListener("resize", this.on_resize_window);
      window.removeEventListener("focusin", this.focusReactPicker);
    }
  };
  focusReactPicker = (event) => {
    let target = event.target;
    if (
      target.tagName &&
      target.tagName === "INPUT" &&
      target.parentNode.classList.contains("react-datepicker__input-container")
    ) {
      target.blur();
    }
  };
  checkDevice = () => {
    const is_touch_device =
      "ontouchstart" in window || "onmsgesturechange" in window ? true : false;
    const windowClientHeight = window.innerHeight;
    if (is_touch_device && window.innerWidth <= 1024) {
      this.setState({
        touchDeviceClientHeight: windowClientHeight,
        isIosMobile:
          navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
      });
    }
  };
  detect_mobile_screen_size = () => {
    const is_mobile = window.innerWidth <= 1024;
    if (is_mobile !== this.state.is_mobile) {
      this.setState({ is_mobile: is_mobile });
    }
  };
  locationHash = "";
  checkHashLink = () => {
    const hash = window.location.hash;
    if (hash) {
      this.locationHash = hash.slice(1, hash.length);
    }
  };
  componentDidMount() {
    this.eventListenerSetup(1);
    this.checkDevice();
    this.detect_mobile_screen_size();
    this.checkHashLink();

    if (this.props.preferenceUpdate) {
      this.updatePreferencesAndProductCategoriesFromStore();
    }
  }
  componentWillUnmount() {
    this.eventListenerSetup();
  }
  componentDidUpdate = (prevProps) => {
    if (
      this.props.preferenceUpdate &&
      prevProps.preferenceUpdate !== this.props.preferenceUpdate
    ) {
      this.updatePreferencesAndProductCategoriesFromStore();
    }
  };
  generateOrderSection = (
    reservations_prio,
    photos_prio,
    orders_prio,
    disabledSections = {}
  ) => {
    const defaultMapping = {
      first_screen_section: 100,
      command_section: 0,
      reservation_section: 0,
      gallery_section: 0,
      contact_section: -20,
      footer_section: -30,
    };
    const dbMapping = {
      ...defaultMapping,
      ...(reservations_prio ? { reservation_section: reservations_prio } : {}),
      ...(photos_prio ? { gallery_section: photos_prio } : {}),
      ...(orders_prio ? { command_section: orders_prio } : {}),
    };
    return JSON.parse(JSON.stringify(constants.sectionsOrdered))
      .filter((pageNameKey) => Boolean(!disabledSections[pageNameKey]))
      .sort((sectionA, sectionB) => {
        const aPriority = dbMapping[sectionA]
          ? Number(dbMapping[sectionA])
            ? Number(dbMapping[sectionA])
            : 0
          : 0;
        const bPriority = dbMapping[sectionB]
          ? Number(dbMapping[sectionB])
            ? Number(dbMapping[sectionB])
            : 0
          : 0;
        if (aPriority === bPriority) return 0;
        return aPriority > bPriority ? -1 : 1;
      });
  };
  updatePreferencesAndProductCategoriesFromStore = () => {
    const isForOrders =
      this.props.preferences.url_for_online_orders &&
      this.props.preferences.url_for_online_orders ===
      window.location.host.replace("www.", "");
    if (
      !isForOrders &&
      this.props.preferences.redirect_to_digital_menu === "1"
    ) {
      this.props.history.push("/meniu");
      return;
    }
    const venue_counties = Array.isArray(this.props.preferences.venue_counties)
      ? this.props.preferences.venue_counties
      : [];
    const venue_county_names = venue_counties.reduce(
      (final, current) => ({
        ...final,
        [current.id_county]: current.county_name,
      }),
      {}
    );
    this.setState(
      {
        id_venue: this.props.preferences.id_venue,
        ig_page_url:
          this.props.preferences.ig_page_url != null
            ? this.props.preferences.ig_page_url
            : undefined,
        first_screen_logo: this.props.preferences.first_screen_logo || "",
        first_screen_title: parse(
          this.props.preferences.first_screen_title || ""
        ),
        name_venue: this.props.preferences.name_venue || "",
        first_screen_background_image:
          this.props.preferences.first_screen_background_image || "",
        sliderImages:
          /** use reduce to transform this.props.preferences[$key] to array */
          [
            "second_screen_slider_image_1",
            "second_screen_slider_image_2",
            "second_screen_slider_image_3",
            "second_screen_slider_image_4",
            "second_screen_slider_image_5",
          ].reduce((final, current) => {
            if (this.props.preferences[current]) {
              return [...final, this.props.preferences[current]];
            }
            return final;
          }, []),

        second_screen_title: this.props.preferences.second_screen_title || "",
        reservation_promotion_message_from:
          this.props.preferences.reservation_promotion_message_from || "",

        second_screen_subtitle:
          typeof this.props.preferences.second_screen_subtitle === "string"
            ? parse(this.props.preferences.second_screen_subtitle)
            : "",
        second_screen_menu_link:
          this.props.preferences.second_screen_menu_link || "",
        third_screen_right_image:
          this.props.preferences.third_screen_right_image || "",
        last_screen_address: this.props.preferences.last_screen_address || "",
        last_screen_phone: this.props.preferences.last_screen_phone || "",
        last_screen_email: this.props.preferences.last_screen_email || "",
        last_screen_program: this.props.preferences.last_screen_program,
        last_screen_hours: this.props.preferences.last_screen_hours,
        last_screen_latitude: this.props.preferences.last_screen_latitude,
        last_screen_longitude: this.props.preferences.last_screen_longitude,

        general_color: this.props.preferences.general_color || "white",
        background_color: this.props.preferences.background_color,
        background_image: this.props.preferences.background_image,
        product_separator_color:
          this.props.preferences.product_separator_color || "#262626",
        text_color: this.props.preferences.text_color,
        inner_text_color:
          this.props.preferences.white_theme == "1" ? "white" : "black",
        white_theme: this.props.preferences.white_theme,

        center: {
          lat: parseFloat(this.props.preferences.last_screen_latitude || 0),
          lng: parseFloat(this.props.preferences.last_screen_longitude || 0),
        },

        program_venue: Array.isArray(this.props.preferences.program)
          ? this.props.preferences.program.map((item) => {
            if (
              helperFunctions.compare_dates(
                new Date(`2020/02/02 ${item.program_start}`),
                new Date(`2020/02/02 ${item.program_end}`)
              ) >= 0
            ) {
              return { ...item, forcedProgramEnd: "23:59:59" };
            }
            return item;
          })
          : [],

        program: this.convertProgramToStringFormat(
          this.props.preferences.program,
          true
        ),
        unique_key: this.props.preferences.unique_key,
        fb_page_url: this.props.preferences.fb_page_url,
        markers: [
          {
            lat: parseFloat(this.props.preferences.last_screen_latitude || 0),
            lng: parseFloat(this.props.preferences.last_screen_longitude || 0),
            img_src: this.state.pin,
          },
        ],

        disable_venue_program:
          this.props.preferences.disable_venue_program == "1",

        // hides the hour selector UI and sets automatically as "00:00:00"
        disable_delivery_hour:
          this.props.preferences.disable_delivery_hour == "1",

        disable_asap: this.props.preferences.disable_asap == "1",
        delivery_asap_only: this.props.preferences.delivery_asap_only === "1",
        disable_delivery: this.props.preferences.disable_delivery == "1", //the whole delivery, //see this.disableDeliverySetup
        disable_delivery_with_delivery:
          this.props.preferences.disable_delivery_with_delivery == "1", //the whole delivery but keeps the delivery, //see this.disableDeliverySetup
        disable_pickup: this.props.preferences.disable_pickup === "1",
        disable_non_contact: this.props.preferences.disable_non_contact === "1",
        disable_delivery_option:
          this.props.preferences.disable_delivery_option == "1",

        default_delivery: this.props.preferences.default_delivery || 1,

        disable_menu_link: this.props.preferences.disable_menu_link == "1",
        disable_program_popup:
          this.props.preferences.disable_program_popup == "1",
        menu_item_text: this.props.preferences.menu_item_text,
        download_menu_text: this.props.preferences.download_menu_text,
        default_product_image: this.props.preferences.default_product_image,
        min_order_initial: Number(this.props.preferences.min_order) || 0,
        min_order: Number(this.props.preferences.min_order) || 0,

        /** delivery rules by distance */
        delivery_price_array: Array.isArray(
          this.props.preferences.delivery_price_array
        )
          ? this.props.preferences.delivery_price_array
          : [],

        /** delivery rules by price - sorted by price_start (alias 'distance_start') */
        delivery_price_array_intervals: Array.isArray(
          this.props.preferences.delivery_price_array_intervals
        )
          ? this.props.preferences.delivery_price_array_intervals
            .map((item) => ({
              ...item,
              distance_end: Number(item.distance_end) || 0,
              distance_start: Number(item.distance_start) || 0,
              delivery_price: Number(item.delivery_price) || 0,
            }))
            .sort((a, b) => {
              if (a.distance_start === b.distance_start) {
                return 0;
              }

              return a.distance_start > b.distance_start ? 1 : -1;
            })
          : [],
        delivery_price:
          Array.isArray(this.props.preferences.delivery_price_array) &&
            this.props.preferences.delivery_price_array.length > 0
            ? Number.parseInt(
              this.props.preferences.delivery_price_array[0].delivery_price
            )
            : Number.parseInt(this.props.preferences.delivery_price),

        free_delivery_from: this.props.preferences.free_delivery_from
          ? Number(this.props.preferences.free_delivery_from)
          : Number.MAX_VALUE,

        delivery_price_text: this.props.preferences.delivery_price_text,
        min_order_text: this.props.preferences.min_order_text ? (
          <React.Fragment>
            {" "}
            {parse(this.props.preferences.min_order_text)}{" "}
          </React.Fragment>
        ) : (
          undefined
        ),
        header_call_to_action: this.props.preferences.header_call_to_action,
        first_screen_middle_button_action: this.props.preferences
          .first_screen_middle_button_action,

        age_check_needed: this.props.preferences.age_check_needed === "1",

        disable_reservations:
          this.props.preferences.disable_reservations === "1",
        disable_gallery_section:
          this.props.preferences.disable_gallery_section === "1",
        disable_order_section:
          this.props.preferences.disable_order_section === "1",

        company_name: this.props.preferences.company_name,
        cui: this.props.preferences.cui,
        reg_com: this.props.preferences.reg_com,
        company_address: this.props.preferences.company_address,
        bank_account: this.props.preferences.bank_account,
        venue_active: this.props.preferences.active == "1",
        venue_locations: helperFunctions.array_to_obj(
          this.props.preferences.locations,
          "id_venue_location"
        ),
        program_delivery: this.props.preferences.program_delivery,
        program_delivery_string: this.convertProgramToStringFormat(
          this.props.preferences.program_delivery
        ),
        blocked_program: (this.props.preferences.blocked || [])
          .filter(({ blocked_delivery }) => blocked_delivery == "1")
          .map((item) => {
            if (item.date_blocked) {
              return {
                ...item,
                /* convert '-' to '\' in date string */
                date_blocked: item.date_blocked
                  .split("")
                  .map((l) => (l === "-" ? "/" : l))
                  .join(""),
              };
            }
            return item;
          }),
        program_delivery_rules: this.props.preferences.program_delivery_rules,
        time_before_preparation: this.props.preferences.time_before_preparation
          ? Number(this.props.preferences.time_before_preparation) || 0
          : undefined,

        products_have_image: this.props.preferences.products_have_image == "1",
        some_products_have_image:
          this.props.preferences.some_products_have_image === "1",

        tip_percentages: this.props.preferences.tip_percentages,
        enable_tips: this.props.preferences.enable_tips == "1",
        tips_other_text: this.props.preferences.tips_other_text,
        tips_main_text: this.props.preferences.tips_main_text,

        always_show_location:
          this.props.preferences.always_show_location === "1",

        google_tag_gtm_id: this.props.preferences.google_tag_gtm_id,
        facebook_pixel_id: this.props.preferences.facebook_pixel_id,
        facebook_pixel_email: this.props.preferences.facebook_pixel_email,
        analytics_tracking_id: this.props.preferences.analytics_tracking_id,
        analytics_user_id: this.props.preferences.analytics_user_id,

        custom_order_popup_text: this.props.preferences.custom_order_popup_text
          ? parse(this.props.preferences.custom_order_popup_text)
          : undefined,

        disable_online_payment:
          this.props.preferences.disable_online_payment == "1",
        enable_cash_payment: this.props.preferences.enable_cash_payment == "1",
        enable_card_payment: this.props.preferences.enable_card_payment == "1",
        default_payment_type:
          this.props.preferences.default_payment_type &&
            this.getAvailablePaymentTypes().indexOf(
              this.props.preferences.default_payment_type
            ) > -1
            ? this.props.preferences.default_payment_type
            : undefined,

        first_screen_logo_width_desktop: this.props.preferences
          .first_screen_logo_width_desktop,
        first_screen_logo_width_mobile: this.props.preferences
          .first_screen_logo_width_mobile,
        first_screen_logo_className: css`
          ${this.props.preferences.first_screen_logo_width_desktop
            ? `width:${this.props.preferences.first_screen_logo_width_desktop
            }!important;`
            : ""}
          ${this.props.preferences.first_screen_logo_width_mobile
            ? `
              @media only screen and (max-width: 1024px) {
                max-width:unset!important;
                width: ${this.props.preferences.first_screen_logo_width_mobile
            }!important;
              }`
            : ""}
        `,
        zoom:
          Number(this.props.preferences.initial_map_zoom) || this.state.zoom,

        first_screen_description_style_classname: this.props.preferences
          .first_screen_description_style
          ? css`
              ${this.props.preferences.first_screen_description_style}
            `
          : undefined,

        termsAndConditonLink:
          this.props.preferences.termsAndConditonLink ||
          constants.defaultTermsAndConditonLink,
        confTermsLink:
          this.props.preferences.confTermsLink ||
          constants.defaultConfTermsLink,
        cookiesTermsLink:
          this.props.preferences.cookiesTermsLink ||
          constants.defaultCookiesTermsLink,
        use_cart_required_terms_checkbox:
          this.props.preferences.use_cart_required_terms_checkbox === "1",

        hide_cart_optional_checkbox:
          this.props.preferences.hide_cart_optional_checkbox === "1",
        cart_required_terms_text: this.props.preferences
          .cart_required_terms_text
          ? parse(String(this.props.preferences.cart_required_terms_text))
          : undefined,

        required_terms_checkbox_warning: this.props.preferences
          .required_terms_checkbox_warning
          ? parse(
            String(this.props.preferences.required_terms_checkbox_warning)
          )
          : undefined,

        //not use
        // powerByPhoto:
        //   this.props.preferences.white_theme == "1"
        //     ? "https://api.thefootballbrain.app/uploads/default_images/powered_by_poloniq_black.png"
        //     : "https://api.thefootballbrain.app/uploads/default_images/powered_by_poloniq_white.png",

        reservation_max_people: this.props.preferences.reservation_max_people,
        reservation_min_people: this.props.preferences.reservation_min_people,

        sectionsOrdered: this.generateOrderSection(
          this.props.preferences.reservations_prio,
          this.props.preferences.photos_prio,
          this.props.preferences.orders_prio,
          {
            command_section: this.props.preferences.disable_order_section,
            reservation_section: this.props.preferences.disable_reservations,
          }
        ),

        exclude_shipping_for_min_order:
          this.props.preferences.exclude_shipping_for_min_order === "1",

        enable_promocode: this.props.preferences.enable_promocode === "1",
        menu_section_background_color: this.props.preferences
          .menu_section_background_color,
        category_nav_bar_scroll_duration:
          Number(this.props.preferences.category_nav_bar_scroll_duration) ||
          undefined,
        use_reservation_stay_form:
          this.props.preferences.use_reservation_stay_form === "1",

        initial_popup_text: this.props.preferences.initial_popup_text
          ? parse(
            (this.props.preferences.initial_popup_text || "").replace(
              /[\n]/g,
              "<br>"
            )
          )
          : undefined,
        venue_counties,
        venue_county_names,

        /** display additional value to be added to the cart in order for the delivery price to be 0  */
        free_delivery_rule_indicator:
          this.props.preferences.free_delivery_rule_indicator === "1",

        no_min_order_for_pickup:
          this.props.preferences.no_min_order_for_pickup === "1",
        pickup_skip_blocked: this.props.preferences.pickup_skip_blocked === "1",
        //###preferences

        preferenceUpdate: this.props.preferenceUpdate,
      },
      () => {
        if (this.state.id_venue) {
          this.checkCookies();
          this.test_underage_status();
          this.setupCustomCss();
          // this.google_analytics_setup();
          // this.facebook_pixels_setup();
          // this.google_tag_setup();
          this.setupProductCatalog()
            .then(() => {
              return this.setMinDateDeliveryRule();
            })
            .then(() => {
              return this.setMinDateDeliveryRulesBasedOnCart();
            })
            .then(() => {
              return this.filterCommandCartProductsBasedOnRules();
            })
            .then(() => {
              return this.test_right_interval();
            })
            .then(() => {
              this.callBlockedIntervals();
              this.configureInitialPopup();
              return this.shippingFormInitialValidation();
            })
            .then(() => {
              this.check_for_prev_transaction(() => {
                this.discount_price_setup();
              });
              this.onOrderTotalChange();
              //###price_change
              setTimeout(() => {
                this.props.setLayoutLoader({ layoutLoader: { open: false } });
                this.scrollToHashSection();
              }, 300);
            })
            .catch((err) => {
              console.log("Error", err);
              this.props.setLayoutLoader({ layoutLoader: { open: false } });
            });
        } else {
          this.props.setLayoutLoader({ layoutLoader: { open: false } });
          this.on_open_generic_modal({
            element: (
              <PopupFrame
                no_close_btn={true}
                general_color={"white"}
                BgStyle={{ ...this.configure_theme_bg_style() }}
              >
                <ContentPlainTextModal
                  color={this.state.general_color}
                  text={"Ne pare rău, dar a apărut o eroare."}
                />
              </PopupFrame>
            ),
            key: "error_call",
          });
          this.onOrderTotalChange();
          //###price_change
        }
      }
    );
  };

  filterCommandCartProductsBasedOnRules = () => {
    return new Promise((resolve, reject) => {
      const {
        command_cart_products,
        strictDeliveryDateBasedOnCart,
        currentMinDeliveryDate,
      } = this.state;
      if (!strictDeliveryDateBasedOnCart && !currentMinDeliveryDate) {
        resolve();
        return;
      }

      const productsToRemove = {};
      const productsToRemoveFromBundle = {};

      const lines = this.getIndividualCartLines();
      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        if (productsToRemove[line.id_product]) {
          productsToRemoveFromBundle[line.id_bundle] = {
            ...(productsToRemoveFromBundle[line.id_bundle] || {}),
            [line.id_product]: true,
          };
          continue;
        }
        if (line.has_delivery_rule === "1") {
          const productRule = this.getMinDeliveryDateAndStrictDeliveryDate([
            line,
          ]);
          if (productRule.minDeliveryDateBasedOnCart) {
            if (
              currentMinDeliveryDate &&
              helperFunctions.compare_dates_string(
                productRule.minDeliveryDateBasedOnCart,
                currentMinDeliveryDate
              ) > 0
            ) {
              productsToRemove[line.id_product] = true;
              productsToRemoveFromBundle[line.id_bundle] = {
                ...(productsToRemoveFromBundle[line.id_bundle] || {}),
                [line.id_product]: true,
              };
              continue;
            }
          }
          if (productRule.strictDeliveryDateBasedOnCart) {
            if (
              strictDeliveryDateBasedOnCart &&
              productRule.strictDeliveryDateBasedOnCart !==
              strictDeliveryDateBasedOnCart
            ) {
              productsToRemove[line.id_product] = true;
              productsToRemoveFromBundle[line.id_bundle] = {
                ...(productsToRemoveFromBundle[line.id_bundle] || {}),
                [line.id_product]: true,
              };
            }
          }
        }
      }
      Object.entries(productsToRemoveFromBundle).forEach(
        ([id_bundle, productsBundle]) => {
          if (
            Object.keys(command_cart_products[id_bundle]).length ===
            Object.keys(productsBundle).length
          ) {
            delete command_cart_products[id_bundle];
          } else {
            Object.keys(productsBundle).forEach((id_product) => {
              delete command_cart_products[id_bundle][id_product];
            });
          }
        }
      );
      this.setState({ command_cart_products }, () => {
        this.setCartProductLocalStorage();
        this.onOrderTotalChange();
        //###price_change
        resolve();
      });
    });
  };

  getFormPreselectedValues = () => {
    return {
      ...(Object.keys(this.state.venue_locations).length === 1
        ? { id_venue_location: Object.keys(this.state.venue_locations)[0] }
        : {}),
      ...(this.state.venue_counties.length === 1
        ? { id_county: this.state.venue_counties[0].id_county }
        : {}),
    };
  };

  shippingFormInitialValidation = () => {
    return new Promise((resolve, reject) => {
      let shipping_form = {
        //preselects location is is only one
        ...JSON.parse(JSON.stringify(constants.lazy_form_init)),
        ...this.getFormPreselectedValues(),
        delivery_type: this.state.default_delivery,
      };
      let shipping_hour_items = JSON.parse(
        JSON.stringify(constants.try_hours_delivery)
      );
      let tipValue = {};

      //get shippingform from localStorage
      const shippingFormLocalStorage = this.get_info_parsed_l_st_venue(
        this.state.id_venue,
        "shipping_form"
      );
      if (helperFunctions.is_object(shippingFormLocalStorage)) {
        Object.keys(shippingFormLocalStorage).forEach((property) => {
          if (
            shippingFormLocalStorage[property] !== null &&
            shippingFormLocalStorage[property] !== undefined
          ) {
            shipping_form[property] = shippingFormLocalStorage[property];
          }
        });
        // shipping_form.message = "makeit01";
        shipping_form.errors = {};
        for (let field in shipping_form) {
          this.find_error_for_shipping_field(
            field,
            shipping_form[field],
            shipping_form
          );
        }
      } else {
        this.set_info_parsed_l_st_venue(
          this.state.id_venue,
          "shipping_form",
          null
        );
      }

      //disable asap when program delivery forbidens
      if (shipping_form.delivery_asap && this.state.forbiden_interval) {
        shipping_form.delivery_asap = false;
      }
      //check delivery type
      const all_options_delivery = this.get_delivery_options_filtered();
      if (shipping_form.delivery_type) {
        if (
          all_options_delivery.find(
            (item) => item.id === shipping_form.delivery_type
          ) == null
        ) {
          if (all_options_delivery.length === 0) {
            this.setState({
              disable_delivery: true,
            });
          } else {
            shipping_form.delivery_type = this.state.disable_delivery_option
              ? all_options_delivery[0].id
              : 1;
          }

          if (this.hardcodeCondition()) {
            shipping_form.date = null;
            shipping_form.hour = null;
          }
        }
      }
      //for restaurants that are not shipping anything, or deactivated all available shipping methods one by one
      if (this.state.disable_delivery || all_options_delivery.length === 0) {
        this.disableDeliverySetup(shipping_form);
      }
      if (this.state.disable_asap) {
        shipping_form.delivery_asap = false;
      }

      //check if chosen date is bigger or equal to today if is the case empty the date and hour fields
      if (shipping_form.date) {
        const hour = shipping_form.hour || "00:00:00";
        const dateobj = new Date(shipping_form.date + " " + hour);
        const today_date_obj = new Date();
        if (helperFunctions.compare_dates(dateobj, today_date_obj) < 0) {
          shipping_form.hour = null;
          shipping_form.date = null;
        }
      }

      //check if chosen date is bigger than minDeliveryDate = program_delivery_rules where day_of_week = today | if is the case empty the date and hour fields
      if (shipping_form.date && this.state.minDeliveryDate) {
        const hour = shipping_form.hour || "00:00:00";
        const dateobj = new Date(shipping_form.date + " " + hour);
        const minDate = new Date(this.state.minDeliveryDate);
        if (helperFunctions.compare_dates(dateobj, minDate) < 0) {
          shipping_form.hour = null;
          shipping_form.date = null;
        }
      }

      //check currentMinDeliveryDate = MAX(command-cart-delivery-rules, program_delivery_rules)
      if (shipping_form.date && this.state.currentMinDeliveryDate) {
        const hour = shipping_form.hour || "00:00:00";
        const dateobj = new Date(shipping_form.date + " " + hour);
        const minDate = new Date(this.state.currentMinDeliveryDate);
        if (helperFunctions.compare_dates(dateobj, minDate) < 0) {
          shipping_form.hour = null;
          shipping_form.date = null;
        }
      }
      //check strictDeliveryDateBasedOnCart
      if (shipping_form.date && this.state.strictDeliveryDateBasedOnCart) {
        if (
          shipping_form.date + " 00:00:00" !==
          this.state.strictDeliveryDateBasedOnCart
        ) {
          shipping_form.hour = null;
          shipping_form.date = null;
        }
      }

      //check date and hour intervals
      if (shipping_form.date) {
        let validHours = this.generate_valid_hours(shipping_form.date, {
          custom_shipping_form: shipping_form,
        });
        if (validHours.indexOf(shipping_form.hour) === -1) {
          shipping_form.hour = null;
        }
        shipping_hour_items =
          validHours.length === 0 ? shipping_hour_items : validHours;
      } else {
        shipping_form.hour = null;
      }
      if (this.check_disable_delivery_hour()) {
        shipping_form.hour = "00:00:00";
      }

      let disable_asap_from_state;
      const todayStrig = helperFunctions.date_to_string(new Date());

      //check asap based on delivery rules
      if (
        this.state.strictDeliveryDateBasedOnCart &&
        (this.state.strictDeliveryDateBasedOnCart !==
          todayStrig + " 00:00:00" ||
          this.generate_valid_hours(todayStrig).length === 0)
      ) {
        disable_asap_from_state = true;

        //dev
        window.dev_disabled_asapt_from_state_reason =
          "check asap based on delivery rules";

        shipping_form.delivery_asap = false;
      }

      //check asap based on blocked interval and program
      if (!disable_asap_from_state) {
        let asapNotPosible;

        const todayValidHours_ = this.generate_valid_hours(todayStrig);

        if (
          todayValidHours_.length === 0
          // ||
          // (this.state.blocked_program || []).some(
          //   (item) => item.date_blocked === todayStrig
          // )
        ) {
          asapNotPosible = true;
        }
        if (asapNotPosible) {
          disable_asap_from_state = true;

          //dev
          window.dev_disabled_asapt_from_state_reason = "asapNotPosible";

          shipping_form.delivery_asap = false;
        }
      }

      if (!disable_asap_from_state && this.state.currentMinDeliveryDate) {
        if (
          helperFunctions.compare_dates_string(
            this.state.currentMinDeliveryDate,
            todayStrig
          ) > 0
        ) {
          disable_asap_from_state = true;

          //dev
          window.dev_disabled_asapt_from_state_reason =
            "currentMinDeliveryDate";

          shipping_form.delivery_asap = false;
        }
      }

      //if we want the asap checkboxes hidden and asap checked
      if (this.state.delivery_asap_only) {
        shipping_form.delivery_asap = true;
        disable_asap_from_state = true;

        //dev
        window.dev_disabled_asapt_from_state_reason = "delivery_asap_only";

        shipping_form.date = null;
        shipping_form.hour = null;
      }

      //payments
      const availablePaymentTypes = this.getAvailablePaymentTypes();
      if (availablePaymentTypes.indexOf(shipping_form.payment_type) === -1) {
        shipping_form.payment_type =
          availablePaymentTypes.indexOf("online") > -1
            ? "online"
            : this.state.default_payment_type || availablePaymentTypes[0];
      } else {
        if (this.state.default_payment_type) {
          shipping_form.payment_type = this.state.default_payment_type;
        }
      }
      //check tips
      const tipsFromLocalStorage = this.get_info_parsed_l_st_venue(
        this.state.id_venue,
        "tips"
      );
      if (this.state.enable_tips) {
        if (helperFunctions.is_object(tipsFromLocalStorage)) {
          tipValue = tipsFromLocalStorage;
        } else {
          this.set_info_parsed_l_st_venue(this.state.id_venue, "tips", null);
        }
      } else {
        this.set_info_parsed_l_st_venue(this.state.id_venue, "tips", null);
      }

      //let app know if terms checkbox is required by doing this:
      if (shipping_form.required_terms_checkbox) {
        shipping_form.required_terms_checkbox = true;
      } else {
        shipping_form.required_terms_checkbox = false;
      }

      //check if id_county is still amoung the current list items of venue
      shipping_form.id_county = this.state.venue_counties.some(
        (_item) => _item.id_county === shipping_form.id_county
      )
        ? shipping_form.id_county
        : this.state.venue_counties?.[0]?.id_county || "";

      shipping_form.errors = {};

      let forbiden_interval = this.state.forbiden_interval;
      if (this.state.disable_delivery_with_delivery) {
        disable_asap_from_state = false;
        forbiden_interval = false;
        shipping_form.delivery_asap = true;
        shipping_form.address = "    ";
      }

      /** asap not posible because of blocked interval is applyed to now */
      const disable_asap_from_blocked = HomeHelper.asapDisabledFromBlockedIntervals()(
        this.state.blocked_program
      );
      if (disable_asap_from_blocked) {
        shipping_form.delivery_asap = false;
      }

      this.setState(
        {
          shipping_form,
          tipValue,
          shipping_hour_items,
          disable_asap_from_state,
          forbiden_interval,
          disable_asap_from_blocked,
        },
        () => {
          this.setFormDataLocalStorage();
          if (this.state.shipping_form.id_county) {
            this.onChangeCounty(this.state.shipping_form.id_county);
          }
          resolve();
        }
      );
    });
  };
  setupProductCatalog = () => {
    return new Promise((resolve, reject) => {
      const productCategories = this.props.productCategories || [];
      const { id_venue } = this.state;
      const state_load = {
        command_cart_products: {},
        productDeliveryRules: {},
        command_categories: [],
        product_items: [],
      };
      const product_items = [];
      const command_categories = [];

      const productDeliveryRules = {};
      productCategories.forEach(
        ({
          id_category,
          id_venue,
          category_name,
          date_added,
          last_update,
          products,
        }) => {
          if (Array.isArray(products) && products.length > 0) {
            command_categories.push({
              name: category_name,
              id: id_category,
            });
            products.forEach((productItem, index) => {
              const {
                id_product,
                id_tax,
                product_name,
                product_description,
                product_price,
                product_weight,
                product_image,
                has_assoc,
                has_delivery_rule,
                product_delivery_rules,
              } = productItem;

              if (has_assoc == "1") {
                productItem.assoc = Array.isArray(productItem.assoc)
                  ? productItem.assoc
                  : [];
                productItem.assoc = productItem.assoc.map((itAssoc, index) => {
                  const itemAssocNew = {
                    ...itAssoc,
                    id: itAssoc.id_assoc,
                    id_product: itAssoc.id_assoc,
                    title: itAssoc.assoc_name,
                    product_name: itAssoc.assoc_name,
                    price: Number(itAssoc.assoc_price),
                    product_package: itAssoc.assoc_package,
                    id_tax: itAssoc.assoc_tax,
                    is_size: itAssoc.is_size,
                    category_id: id_category,
                    frond_end_unique: `${itAssoc.id_assoc}-${productItem.id_product
                      }-${index}`,
                    id_size: undefined,
                  };
                  if (itemAssocNew.is_size === "1") {
                    const subArray = Array.isArray(itemAssocNew.assoc_size)
                      ? itemAssocNew.assoc_size
                      : [];
                    itemAssocNew.assoc_size = subArray.map(
                      (subItem, subIndex) => ({
                        ...subItem,
                        id: subItem.id_assoc,
                        id_product: subItem.id_assoc,
                        title: subItem.assoc_name,
                        product_name: subItem.assoc_name,
                        price: Number(subItem.assoc_price),
                        product_package: subItem.assoc_package,
                        id_tax: subItem.assoc_tax,
                        is_size: subItem.is_size,
                        category_id: id_category,
                        frond_end_unique: `${itemAssocNew.frond_end_unique}-${subItem.id_assoc
                          }-${subIndex}-${itemAssocNew.id}`,
                        id_size: itemAssocNew.id,
                      })
                    );
                  }
                  return itemAssocNew;
                });
              }
              const itemToPush = {
                ...productItem,
                title: product_name,
                description: (
                  <React.Fragment>
                    {parse(product_description || "")}
                  </React.Fragment>
                ),
                price: Number(product_price) || 0,
                category_id: id_category,
                id: id_product,
                id_tax,
                product_weight,
                ["command_item_scroll_ref_" + id_category]:
                  index === 0 ? 1 : undefined,
                last_product: index === products.length - 1,
                product_image,
              };

              product_items.push({
                ...itemToPush,
                ...this.computeProductAssoc(itemToPush),
              });
              if (has_delivery_rule === "1") {
                productDeliveryRules[id_product] = Array.isArray(
                  product_delivery_rules
                )
                  ? product_delivery_rules
                  : [];
              }
            });
          }
        }
      );
      state_load["productDeliveryRules"] = productDeliveryRules;
      state_load["command_categories"] = command_categories;
      state_load["product_items"] = product_items.map((item) => ({
        ...item,
        price: Number(item.price) || 0,
      }));

      //initialise products from localstorage
      let command_cart_local_st = this.get_info_parsed_l_st_venue(
        id_venue,
        "command_cart_products"
      );

      //filter based on newly received products
      const cartLinesLocStorageUpdated = [];
      if (Array.isArray(command_cart_local_st)) {
        /**
         * used for checking if id_target of assoc products exists in a bundle
         * helps to avoid bundles where the main product (target product) is not present
         */
        const cartLocalStorageTargetBundlePair = {};
        /**
         * this will get added to the cartLinesLocStorageUpdated
         */
        const cartLocalStorageAssocItems = [];
        /**
         */
        let arrayOfLinesForStateObj = [];

        for (let i = 0; i < command_cart_local_st.length; i++) {
          const {
            quantity,
            id,
            id_target,
            id_bundle,
            id_size,
          } = command_cart_local_st[i];
          if (!quantity || !id_bundle) {
            continue; //local storage product invalid
          }
          if (id_target) {
            /**
             * find the target product
             */
            const targetProduct = state_load["product_items"].find(
              (item) => item.id_product === id_target
            );
            if (!targetProduct) {
              continue; //local storage product invalid
            }
            /**
             * search within the assoc products array
             */
            if (
              targetProduct.has_assoc !== "1" ||
              !Array.isArray(targetProduct.assoc) ||
              targetProduct.assoc.length === 0
            ) {
              continue; //local storage product invalid
            }

            /**
             * is from within particular size assoc items
             */
            if (id_size) {
              const sizeProduct = targetProduct.assocSizes.find(
                (item) => item.id_assoc === id_size
              );
              if (!sizeProduct) {
                continue; //local storage product invalid
              } else {
                if (Array.isArray(sizeProduct.assoc_size)) {
                  const assocSizeSubElement = sizeProduct.assoc_size.find(
                    (item) => item.id_assoc === id
                  );
                  if (assocSizeSubElement) {
                    cartLocalStorageAssocItems.push({
                      quantity,
                      id,
                      id_target,
                      id_bundle,
                      id_size,
                    });
                    arrayOfLinesForStateObj.push({
                      quantity,
                      id,
                      id_target,
                      id_bundle,
                      id_size,
                      //
                      sizeProduct: {
                        id_assoc: sizeProduct.id_assoc,
                        assoc_name: sizeProduct.assoc_name,
                        assoc_tax: sizeProduct.assoc_tax,
                        assoc_price: sizeProduct.assoc_price,
                        is_size: sizeProduct.is_size,
                      },
                      assocSizeSubElement,
                    });
                  } else {
                    continue; //local storage product invalid
                  }
                } else {
                  continue; //local storage product invalid
                }
              }
            } else {
              const standAloneAssoc = targetProduct.assoc.find(
                (item) => item.id_assoc === id
              );
              if (standAloneAssoc) {
                cartLocalStorageAssocItems.push({
                  quantity,
                  id,
                  id_target,
                  id_bundle,
                  id_size: undefined,
                });
                arrayOfLinesForStateObj.push({
                  quantity,
                  id,
                  id_target,
                  id_bundle,
                  id_size: undefined,
                  //
                  targetProduct,
                  standAloneAssoc,
                });
              } else {
                continue; //local storage product invalid
              }
            }
          } else {
            /**
             * this checks if target product is found amoung the product list
             */
            const targetProduct = state_load["product_items"].find(
              (item) => item.id_product === id
            );
            if (targetProduct) {
              let sizeProduct = undefined;
              if (id_size) {
                sizeProduct = targetProduct.assocSizes.find(
                  (item) => item.id_assoc === id_size
                );
                if (!sizeProduct) {
                  continue; //local storage product invalid
                }
              }

              cartLinesLocStorageUpdated.push({
                id,
                quantity,
                id_bundle,
                id_size,
                id_target: undefined,
                sizeProduct,
              });
              arrayOfLinesForStateObj.push({
                id,
                quantity,
                id_bundle,
                id_size,
                id_target: undefined,
                targetProduct,
                sizeProduct,
              });
              cartLocalStorageTargetBundlePair[`${id_bundle}_${id}`] = true;
            }
          }

          /**
           * array of lines for local storage update - after validation with the associated products
           * add assoc products if their target is found
           */
          cartLocalStorageAssocItems.forEach((item) => {
            const { quantity, id, id_target, id_bundle, id_size } = item;
            if (cartLocalStorageTargetBundlePair[`${id_bundle}_${id_target}`]) {
              cartLinesLocStorageUpdated.push(item);
            } else {
              if (id_target) {
                arrayOfLinesForStateObj = arrayOfLinesForStateObj.filter(
                  (item) => item.id_target === id_target
                );
              }
            }
          });
        }

        /**
         * convert local storage lines to react state object used for cart
         */
        arrayOfLinesForStateObj.forEach((line) => {
          const { id, quantity, id_target, id_bundle, id_size } = line;

          /**
           * create or append to current bundle
           */
          let bundle = {};
          if (state_load.command_cart_products[id_bundle]) {
            bundle = state_load.command_cart_products[id_bundle];
          }

          let propertiesSource = {};
          /**
           * handle assoc element
           */
          if (id_target) {
            if (id_size) {
              propertiesSource = { ...line.assocSizeSubElement };
              bundle["SIZE_ELEMENT"] = line.sizeProduct;
            } else {
              propertiesSource = { ...line.standAloneAssoc };
            }
          } else {
            if (id_size) {
              bundle["SIZE_ELEMENT"] = line.sizeProduct;
            }
            propertiesSource = { ...line.targetProduct };
          }
          bundle[id] = {
            ...propertiesSource,
            id,
            quantity,
            id_target,
            id_bundle,
            id_size,
          };
          state_load.command_cart_products[id_bundle] = bundle;
        });
        /**
         * for items that have size assoc the target product must inherit price and get the info of the size
         */
        const entriesBundle = Object.entries(state_load.command_cart_products);
        for (let eIndex = 0; eIndex < entriesBundle.length; eIndex++) {
          const [id_bundle, bundle] = entriesBundle[eIndex];

          const targetEntry = Object.entries(bundle).find(
            ([id, content]) => !content.id_target
          );
          if (!targetEntry) {
            delete state_load.command_cart_products[id_bundle];
            continue;
          } else {
            const [id_target, contentTarget] = targetEntry;

            if (bundle["SIZE_ELEMENT"]) {
              state_load.command_cart_products[id_bundle][id_target] = {
                ...contentTarget,
                price: Number(bundle["SIZE_ELEMENT"].assoc_price) || 0,
                sizeItem: JSON.parse(JSON.stringify(bundle["SIZE_ELEMENT"])),
              };
              delete state_load.command_cart_products[id_bundle][
                "SIZE_ELEMENT"
              ];
              delete state_load.command_cart_products[id_bundle][id_target]
                .sizeItem.assoc_size;
            }

            delete state_load.command_cart_products[id_bundle][id_target]
              .assocSizes;
            delete state_load.command_cart_products[id_bundle][id_target]
              .assocSizeSelectedIndex;
            delete state_load.command_cart_products[id_bundle][id_target]
              .allAssoc;
            delete state_load.command_cart_products[id_bundle][id_target]
              .command_item_scroll_ref_31;
            delete state_load.command_cart_products[id_bundle][id_target]
              .assocNoSize;
            delete state_load.command_cart_products[id_bundle][id_target]
              .assocWithSize;
            delete state_load.command_cart_products[id_bundle][id_target].assoc;
          }
        }

        this.set_info_parsed_l_st_venue(
          id_venue,
          "command_cart_products",
          cartLinesLocStorageUpdated
        );
      } else {
        this.set_info_parsed_l_st_venue(
          id_venue,
          "command_cart_products",
          null
        );
      }
      this.setState(
        {
          product_items: state_load.product_items,
          command_cart_products: state_load.command_cart_products,
          command_categories: state_load.command_categories,
          productDeliveryRules: state_load.productDeliveryRules,
        },
        () => {
          this.setCartProductLocalStorage();
          resolve();
        }
      );
    });
  };

  computeAssocCategories = (item, assocNoSize, assocWithSize) => {
    let assocCategoryInitialState_json = null;
    if (item.use_category_assoc === true) {
      assocCategoryInitialState_json = JSON.stringify({
        /**
         * filter the categories based on the assoc items (that can vary by the size select)
         */
        categories: item.assoc_categories
          .filter((assocCatItem) =>
            [...assocNoSize, ...assocWithSize].some(
              (assocItem) =>
                assocItem.id_product_assoc_categ ===
                assocCatItem.id_product_assoc_categ
            )
          )
          /**
           * index by id as an object all of them
           */
          .reduce((final, current) => {
            return {
              ...final,
              [current.id_product_assoc_categ]: {
                ...current,
                min_selection: Number(current.min_selection) || 0,
                max_selection: Number(current.max_selection) || 0,
              },
            };
          }, {}),
      });
    }
    return assocCategoryInitialState_json;
  };

  /**
   * 1. separates simple assoc from size assoc and its elements
   * 2. selects the first size
   * returns the results in a object to be appended
   * @param {object} item - product
   */
  computeProductAssoc = (item) => {
    const assocSizes =
      item.has_assoc === "1" && Array.isArray(item.assoc)
        ? item.assoc.filter((assocItem) => assocItem.is_size === "1")
        : [];
    const assocNoSize =
      item.has_assoc === "1" && Array.isArray(item.assoc)
        ? item.assoc.filter((assocItem) => assocItem.is_size !== "1")
        : [];
    let assocWithSize = [];

    let assocSizeSelectedIndex = -1;
    if (assocSizes.length) {
      assocSizeSelectedIndex = 0;
      assocWithSize = Array.isArray(assocSizes[0].assoc_size)
        ? assocSizes[0].assoc_size
        : [];
    }

    return {
      assocSizes,
      assocNoSize,
      assocWithSize,
      assocSizeSelectedIndex,
      price:
        assocSizeSelectedIndex >= 0
          ? assocSizes[assocSizeSelectedIndex].price
          : item.price,
      assocCategoryInitialState_json: this.computeAssocCategories(
        item,
        assocNoSize,
        assocWithSize
      ),
    };
  };
  fillReservationFormWithValidValues = () => {
    let date = new Date();
    let tries = 0;
    let { client_persoane } = this.state;
    if (
      (this.state.reservation_min_people &&
        Number(client_persoane) < this.state.reservation_min_people) ||
      (this.state.reservation_max_people &&
        Number(client_persoane) > this.state.reservation_max_people)
    ) {
      client_persoane = this.state.reservation_min_people;
    }
    while (1) {
      const date_string = helperFunctions.date_to_string(date);
      if (
        !this.state.date_blocate_array.some(
          (item) => helperFunctions.date_to_string(item) === date_string
        )
      ) {
        const valid_hours_reservation = this.generateValidHoursRerservation(
          date_string
        );

        if (valid_hours_reservation.length > 0) {
          const valid_hours_reservation_stay = this.generateValidHoursRerservationStay(
            valid_hours_reservation[0],
            date_string
          );
          this.setState({
            date_string_reservation: date_string,
            valid_hours_reservation,
            hour_reservation: valid_hours_reservation[0],
            valid_hours_reservation_stay,
            hour_reservation_stay:
              valid_hours_reservation_stay?.[1] ||
              valid_hours_reservation_stay?.[0] ||
              "",
            client_persoane,
          });
          break;
        }
      }
      date.setDate(date.getDate() + 1);
      tries += 1;
      if (tries > 1000) {
        this.setState({
          client_persoane,
        });
        break;
      }
    }
  };
  configureInitialPopup = () => {
    if (!this.state.initial_popup_text) {
      return;
    }
    const message = this.state.initial_popup_text;
    const modal_key = `${this.state.id_venue}-optional-popoup-intial`;
    this.on_open_generic_modal({
      element: (
        <PopupFrame
          on_close_generic_modal={() => {
            this.on_close_generic_modal(modal_key, null, true);
          }}
          general_color={this.state.general_color}
          BgStyle={{ ...this.configure_theme_bg_style() }}
        >
          {
            <div
              style={{ color: this.state.general_color }}
              className="optional_mesasge_popup"
            >
              {message}
            </div>
          }
        </PopupFrame>
      ),
      key: modal_key,
    });
  };
  callBlockedIntervals = () => {
    axios
      .get("blocked/" + this.state.id_venue + "/id_venue")
      .then((response) => {
        if (Array.isArray(response.data)) {
          const today_string = helperFunctions.date_to_string(new Date());
          const {
            date_blocate_array,
            ore_blocate_array,
          } = response.data.reduce(
            (final, item) => {
              if (item.blocked_reservation === "1") {
                if (item.hour_start == null && item.hour_finish == null) {
                  final.date_blocate_array.push(
                    new Date(item.date_blocked.replace(/-/g, "/"))
                  );
                } else {
                }
                final.ore_blocate_array.push({
                  ...item,
                  date_blocked: item.date_blocked.replace(/-/g, "/"),
                });
              }
              return {
                ...final,
              };
            },
            { date_blocate_array: [], ore_blocate_array: [] }
          );
          var day_closed = [];
          var all_days = this.state.program_venue;

          Object.keys(all_days).forEach(function (key) {
            if (all_days[key].closed == 1) {
              if (parseInt(all_days[key].day_of_the_week) != 6) {
                day_closed.push(parseInt(all_days[key].day_of_the_week) + 1);
              } else {
                day_closed.push(0);
              }
            }
          });
          this.setState(
            {
              date_blocate_array,
              ore_blocate_array,
              hour_reservation: "",
              date_string_reservation: "",
              day_closed,
            },
            () => {
              const date_blocate_array = this.state.date_blocate_array;
              const ore_blocate_array = this.state.ore_blocate_array.filter(
                (item) => {
                  if (item.date_blocked === today_string) {
                    const valid_hours = this.generateValidHoursRerservation(
                      item.date_blocked
                    ).filter(
                      (hour) =>
                        helperFunctions.compare_dates(
                          new Date(item.date_blocked + " " + hour),
                          new Date()
                        ) > 0
                    );
                    if (valid_hours.length === 0) {
                      date_blocate_array.push(new Date(item.date_blocked));
                      return false;
                    }
                  }
                  return true;
                }
              );
              this.setState(
                {
                  ore_blocate_array,
                  date_blocate_array,
                  client_persoane: "1",
                },
                () => {
                  this.fillReservationFormWithValidValues();
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        console.log("Error while fetching blocked features venue", err);
      });
  };
  get_text_date = (date_string) => {
    const months = [
      "ianuarie",
      "februarie",
      "martie",
      "aprilie",
      "mai",
      "iunie",
      "iulie",
      "august",
      "septembrie",
      "octombrie",
      "noiembrie",
      "decembrie",
    ];
    const days = [
      "luni",
      "marti",
      "miercuri",
      "joi",
      "vineri",
      "sâmbătă",
      "duminică",
    ];

    const d = new Date(date_string);
    const text = `${d.getDate()} ${months[d.getMonth()]}, ${d.getFullYear()}`;
    return text;
  };
  handleChangeHours = (hour_reservation) => {
    const valid_hours_reservation_stay = this.generateValidHoursRerservationStay(
      hour_reservation,
      this.state.date_string_reservation
    );
    let { hour_reservation_stay } = this.state;
    if (
      !hour_reservation_stay ||
      valid_hours_reservation_stay.indexOf(hour_reservation_stay) === -1
    ) {
      hour_reservation_stay =
        valid_hours_reservation_stay?.[1] ||
        valid_hours_reservation_stay?.[0] ||
        "";
    }
    this.setState({
      hour_reservation_stay,
      valid_hours_reservation_stay,
      hour_reservation,
    });
  };

  handleChangeHoursReservationStay = (hour_reservation_stay) => {
    this.setState({
      hour_reservation_stay,
    });
  };

  /**
   * javascript Date() uses Sunday as the first day of the week
   * the Database uses Monday as its first
   *
   * @param {*} date_string
   * @returns
   */
  getDayOfWeekDbFromDateString = (date_string) => {
    const date = new Date(date_string);
    const day_week = date.getDay();

    return {
      0: "6",
      1: "0",
      2: "1",
      3: "2",
      4: "3",
      5: "4",
      6: "5",
    }[day_week];
  };

  /**
   *
   * @param {string|undefined} hour_reservation
   * @param {string} date_string - a short date without hour: 01/01/2000
   * @returns
   */
  generateValidHoursRerservationStay = (hour_reservation, date_string) => {
    /** hour is not filled in yet */
    if (!hour_reservation) {
      return [];
    }

    let try_valid_hours_reservation = JSON.parse(
      JSON.stringify(constants.try_hours_quarter)

      /** filter by the ${date} ${} */
    ).filter(
      (item) =>
        helperFunctions.compare_dates(
          new Date(`${date_string} ${item}`),
          new Date(`${date_string} ${hour_reservation}`)
        ) > 0
    );

    let valid_hours_reservation_stay = this.generateValidHoursRerservation(
      date_string,
      try_valid_hours_reservation,
      true
    );

    return valid_hours_reservation_stay;
  };
  generateValidHoursRerservation = (
    date_string,
    custom_try_hours,
    for_reservation_stay
  ) => {
    /** initialize with available options to filter going forward */
    let valid_hours_reservation = JSON.parse(
      JSON.stringify(custom_try_hours || constants.try_hours_delivery)
    );

    const day_of_week_number = this.getDayOfWeekDbFromDateString(date_string);
    const today_string = helperFunctions.date_to_string(new Date());

    if (this.state.ore_blocate_array.length) {
      const blocked_filtered = this.state.ore_blocate_array.filter(
        (item) => item.date_blocked === date_string
      );

      if (blocked_filtered.length > 0) {
        valid_hours_reservation = JSON.parse(
          JSON.stringify(constants.try_hours_delivery)
        ).filter((hour) =>
          //no hour is between blocked date's intervals
          blocked_filtered.every(
            ({ hour_start, hour_finish, date_blocked }) => {
              return !(
                helperFunctions.compare_dates(
                  new Date(date_blocked + " " + hour_start),
                  new Date(date_blocked + " " + hour)
                ) < 0 &&
                helperFunctions.compare_dates(
                  new Date(date_blocked + " " + hour_finish),
                  new Date(date_blocked + " " + hour)
                ) >= 0
              );
            }
          )
        );

        if (today_string === date_string) {
          valid_hours_reservation = valid_hours_reservation.filter(
            (hour) =>
              helperFunctions.compare_dates(
                new Date(date_string + " " + hour),
                new Date()
              ) > 0
          );
        }
      }
    }

    const venue_program_intervals = this.state.program_venue.filter(
      (item) => item.day_of_the_week === day_of_week_number
    );

    if (venue_program_intervals.length > 0) {
      if (venue_program_intervals.some((item) => item.closed === "1")) {
        valid_hours_reservation = [];
      } else {
        valid_hours_reservation = JSON.parse(
          JSON.stringify(valid_hours_reservation)
        ).filter((hour) =>
          venue_program_intervals.every(
            ({
              program_start,
              program_end,
              program_end_closing,
              forcedProgramEnd,
            }) => {
              return (
                helperFunctions.compare_dates(
                  new Date(date_string + " " + program_start),
                  new Date(date_string + " " + hour)
                ) <= 0 &&
                helperFunctions.compare_dates(
                  new Date(
                    date_string +
                    " " +
                    (for_reservation_stay
                      ? program_end_closing || forcedProgramEnd || program_end
                      : forcedProgramEnd || program_end)
                  ),
                  new Date(date_string + " " + hour)
                ) >= 0
              );
            }
          )
        );
      }
    }
    if (today_string === date_string) {
      valid_hours_reservation = valid_hours_reservation.filter(
        (hour) =>
          helperFunctions.compare_dates(
            new Date(date_string + " " + hour),
            new Date()
          ) > 0
      );
    }
    return valid_hours_reservation;
  };
  handleChangeDateReservation = (date) => {
    let { hour_reservation } = this.state;
    const date_string_reservation = helperFunctions.date_to_string(date);
    const valid_hours_reservation = this.generateValidHoursRerservation(
      date_string_reservation
    );

    if (valid_hours_reservation.indexOf(hour_reservation) < 0) {
      hour_reservation = "";
    }
    this.setState(
      {
        valid_hours_reservation,
        hour_reservation,
        date_string_reservation,
      },
      () => {
        this.handleChangeHours(hour_reservation);
      }
    );
  };
  isWeekday = (date) => {
    const day = getDay(date);

    var d = "";

    this.state.day_closed.forEach(function (entry, index) {
      //if (index == 0) d += "day !== " + entry;
      //else d += " && day !== " + entry;
      d += entry + ",";
    });

    return !d.includes(day);

    //return d;

    //return day !== 0 && day !== 6;
  };
  createSelectWithOptions = (e) => {
    var x, i, j, selElmnt, a, b, c;

    x = document.getElementsByClassName("custom-select");

    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];

      /*for each element, create a new DIV that will act as the selected item:*/

      a = document.createElement("DIV");

      a.setAttribute("class", "select-selected");

      a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

      x[i].appendChild(a);

      /*for each element, create a new DIV that will contain the option list:*/

      b = document.createElement("DIV");

      b.setAttribute("class", "select-items select-hide");

      for (j = 0; j < selElmnt.length; j++) {
        /*for each option in the original select element,

            create a new DIV that will act as an option item:*/

        c = document.createElement("DIV");

        c.innerHTML = selElmnt.options[j].innerHTML;

        c.addEventListener("click", function (e) {
          /*when an item is clicked, update the original select box,

                and the selected item:*/

          var y, i, k, s, h;

          s = this.parentNode.parentNode.getElementsByTagName("select")[0];

          h = this.parentNode.previousSibling;

          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;

              h.innerHTML = this.innerHTML;

              y = this.parentNode.getElementsByClassName("same-as-selected");

              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
              }

              this.setAttribute("class", "same-as-selected");

              break;
            }
          }

          h.click();
        });

        b.appendChild(c);
      }

      x[i].appendChild(b);

      a.addEventListener("click", function (e) {
        e.stopPropagation();

        helperFunctions.closeAllSelect(this);

        this.nextSibling.classList.toggle("select-hide");

        this.classList.toggle("select-arrow-active");
      });
    }
  };
  scrollToHashSection = () => {
    switch (this.locationHash) {
      case "comanda":
        this.scrollToSection("section_command", null, 0);
        break;
      case "rezervare":
        this.scrollToSection("section3", null, 0);
        break;
    }
  };

  setPersoane = (e) => { };
  setOra = (e) => { };
  goToPage = (e) => {
    e.preventDefault();
    if ($(e.target.getAttribute("href")).length === 0) {
      return;
    }
    var position = $(e.target.getAttribute("href")).offset().top;

    $("body, html").animate(
      {
        scrollTop: position,
      },
      1000
    );
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChangeReservationPerson = (value) => {
    this.setState({ client_persoane: value });
  };
  resetReservationForm = () => {
    this.setState(
      {
        trimitere_ok: false,
        eroare: false,
        hour_reservation: "",
        hour_reservation_stay: "",
        date_string_reservation: "",
        valid_hours_reservation: [],
        valid_hours_reservation_stay: [],
      },
      () => {
        this.fillReservationFormWithValidValues();
      }
    );
  };
  trimiteRezervarea = (e) => {
    if (
      this.state.client_nume != "" &&
      this.state.client_telefon != "" &&
      this.state.client_email != "" &&
      this.state.client_persoane != "" &&
      this.state.date_string_reservation != "" &&
      this.state.hour_reservation != "" &&
      this.state.hour_reservation_stay != ""
    ) {
      this.setState({
        showErrors: false,
        err_nume: "",
        err_telefon: "",
        err_email: "",
      });

      var payload = {
        add: 1,
        client_name: this.state.client_nume,
        client_phone: this.state.client_telefon,
        client_email: this.state.client_email,
        places: this.state.client_persoane,
        date: this.state.date_string_reservation.replace(/\//g, "-"),
        reservation_time: this.state.hour_reservation,
        reservation_end: this.state.hour_reservation_stay,
        observations: this.state.client_mesaj,
        id_venue: this.state.id_venue,
        unique_key: this.state.unique_key,
      };
      this.setState(
        {
          isFetchingSendReservation: true,
        },
        () => {
          axios
            .post("api/", payload)
            .then((response) => {
              if (response.data.success == 0) {
                this.setState({ eroare: true, trimitere_ok: false });
              } else {
                this.setState({
                  eroare: false,
                  trimitere_ok: true,
                });

                $("html, body").animate(
                  {
                    scrollTop:
                      $("#section3").length > 0
                        ? $("#section3").offset().top
                        : 0,
                  },
                  1000
                );
              }
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(() => {
              this.setState({
                isFetchingSendReservation: false,
              });
            });
        }
      );
    } else {
      this.setState({
        showErrors: true,
      });
      //window.scrollTo(0, 0);
      if (this.state.client_nume == "") {
        this.setState({
          err_nume: "err_field",
        });
      } else {
        this.setState({
          err_nume: "",
        });
      }

      if (this.state.client_telefon == "") {
        this.setState({
          err_telefon: "err_field",
        });
      } else {
        this.setState({
          err_telefon: "",
        });
      }

      if (this.state.client_email == "") {
        this.setState({
          err_email: "err_field",
        });
      } else {
        this.setState({
          err_email: "",
        });
      }
    }
  };
  //............
  discount_price_setup = () => {
    //this may be deleted later
    return;
    let { product_items, id_venue, command_cart_products } = this.state;
    axios
      .get(`discount/${id_venue}/id_venue`)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          //array to obj for iterations
          const discounts = {};
          data.forEach(
            ({
              id_product,
              discount_value,
              new_price,
              start_date,
              end_date,
            }) => {
              if (
                command_cart_products[id_product] &&
                !command_cart_products[id_product].targets
              ) {
                command_cart_products[id_product].price =
                  Number(new_price) || 0;
              }
              discounts[id_product] = {
                new_price,
              };
            }
          );
          product_items = product_items.map((product) => {
            if (discounts[product.id]) {
              return {
                ...product,
                old_price: product.price,
                price: Number(discounts[product.id].new_price) || 0,
              };
            }
            return {
              ...product,
            };
          });
          this.setState({ command_cart_products, product_items }, () => {
            this.setCartProductLocalStorage();
          });
        }
      })
      .catch((err) => {
        console.log("error while fetching discounts", err);
      });
  };
  warning_pop_up_command_closed_venues_types = {
    // "18": {
    //   type: "text",
    //   hardcoded_: {
    //     0: {
    //       message:
    //         "Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    //     },
    //     1: {
    //       message:
    //         " Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    //     },
    //     2: {
    //       message:
    //         " Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    //     },
    //     3: {
    //       message:
    //         " Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    //     },
    //     4: {
    //       message:
    //         " În zilele de vineri și sâmbătă facem livrări, așa că te așteptăm să comanzi în avans.",
    //     },
    //     5: {
    //       message:
    //         " În zilele de vineri și sâmbătă facem livrări, așa că te așteptăm să comanzi în avans.",
    //     },
    //     6: {
    //       message:
    //         "Comandă în avans și noi livrăm vineri sau sâmbătă, între 17:00 - 21:00.",
    //     },
    //   },
    // },
    "16": {
      type: "program",
    },
  };
  facebook_pixels_setup = () => {
    const { facebook_pixel_id, facebook_pixel_email } = this.state;
    if (!facebook_pixel_id) {
      return;
    }
    const advancedMatching = facebook_pixel_email
      ? { em: facebook_pixel_email }
      : {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: true, // enable logs
    };
    ReactPixel.init(facebook_pixel_id, advancedMatching, options);

    ReactPixel.pageView(); // For tracking page view
  };
  google_tag_setup = () => {
    const { google_tag_gtm_id } = this.state;
    if (google_tag_gtm_id) {
      const tagManagerArgs = {
        gtmId: google_tag_gtm_id,
      };
      TagManager.initialize(tagManagerArgs);
    }
  };
  google_analytics_setup = () => {
    const { analytics_user_id, analytics_tracking_id } = this.state;
    if (!analytics_user_id || !analytics_tracking_id) {
      return;
    }
    ReactGA.initialize(analytics_tracking_id, {
      debug: true,
      titleCase: false,
      gaOptions: {
        userId: analytics_user_id,
      },
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  convertProgramToStringFormat = (_program, includeClosedDays) => {
    if (!Array.isArray(_program)) return [];
    let program = _program;
    const programWithCounty = _program.find((item) => item.id_county);
    if (programWithCounty) {
      program = _program.filter(
        (item) => item.id_county === programWithCounty.id_county
      );
    }

    program = program.sort((a, b) => {
      const num_a = Number(a.day_of_the_week) || 0;
      const num_b = Number(b.day_of_the_week) || 0;
      if (num_a === num_b) {
        return 0;
      }
      return num_a > num_b ? 1 : -1;
    });
    const db_to_day_week_name = {
      "0": "Luni",
      "1": "Marți",
      "2": "Miercuri",
      "3": "Joi",
      "4": "Vineri",
      "5": "Sâmbătă",
      "6": "Duminică",
    };
    let command_time_program = [];
    let c_start = null;
    let c_final = null;
    for (let i = 0; i < program.length; i++) {
      const { day_of_the_week, program_start, program_end, closed } = program[
        i
      ];
      if (closed) {
        if (c_start) {
          command_time_program.push({
            day_st: c_start.day_st,
            day_end: c_final ? c_final.day_end : null,
            h_st: c_start.h_st,
            h_end: c_start.h_end,
          });
          c_start = null;
          c_final = null;
        }
        if (includeClosedDays) {
          command_time_program.push({
            day_st: db_to_day_week_name[day_of_the_week],
            closed: true,
          });
        }
      } else {
        if (c_start === null) {
          c_start = {
            day_st: db_to_day_week_name[day_of_the_week],

            h_st: program_start,
            h_end: program_end,
          };
        } else {
          if (c_start.h_st === program_start && c_start.h_end === program_end) {
            c_final = {
              day_end: db_to_day_week_name[day_of_the_week],
            };
          } else {
            command_time_program.push({
              day_st: c_start.day_st,
              day_end: c_final ? c_final.day_end : null,
              h_st: c_start.h_st,
              h_end: c_start.h_end,
            });
            c_start = {
              day_st: db_to_day_week_name[day_of_the_week],
              h_st: program_start,
              h_end: program_end,
            };
            c_final = null;
          }
        }
      }
    }
    if (c_start) {
      command_time_program.push({
        day_st: c_start.day_st,
        day_end: c_final ? c_final.day_end : null,
        h_st: c_start.h_st,
        h_end: c_start.h_end,
      });
    }
    //generate closed days
    if (includeClosedDays) {
      const time_program_with_closed_days = [];
      for (let i = 0; i < command_time_program.length; i++) {
        const item = { ...command_time_program[i] };
        while (
          item.closed &&
          i + 1 < command_time_program.length &&
          command_time_program[i + 1].closed
        ) {
          item.day_end = command_time_program[i + 1].day_st;
          i += 1;
        }
        time_program_with_closed_days.push(item);
      }
      return time_program_with_closed_days.map((item) => ({
        ...item,
        h_st: (item.h_st || "").slice(0, 5),
        h_end: (item.h_end || "").slice(0, 5),
      }));
    }
    return command_time_program.map((item) => ({
      ...item,
      h_st: (item.h_st || "").slice(0, 5),
      h_end: (item.h_end || "").slice(0, 5),
    }));
  };
  program_delivery_to_string = (delivery_program, id_venue) => {
    const del_display_type =
      objectPath.get(
        this.warning_pop_up_command_closed_venues_types,
        id_venue + ".type"
      ) || "program";
    if (del_display_type === "program") {
      return {
        type: "program",
        command_time_program: this.convertProgramToStringFormat(
          delivery_program
        ),
      };
    } else {
      let text = "Comenzile se află în afara programului.";
      const day_ind = new Date().getDay();
      if (
        this.warning_pop_up_command_closed_venues_types[id_venue] &&
        objectPath.get(
          this.warning_pop_up_command_closed_venues_types[id_venue],

          "hardcoded_." + day_ind + ".message"
        )
      ) {
        text = this.warning_pop_up_command_closed_venues_types[id_venue]
          .hardcoded_[day_ind].message;
      }
      return {
        type: "text",
        text,
      };
    }
  };
  convert_to_real_interval = (delivery_program, id_venue) => {
    const running_time = [
      // "Sunday"
      [],
      // "Monday"
      [],
      // "Tuesday"
      [],
      // "Wednesday"
      [],
      // "Thursday"
      [],
      // "Friday"
      [],
      // "Saturday"
      [],
    ];

    for (let i = 0; i < delivery_program.length; i++) {
      const {
        day_of_the_week,
        program_start_real,
        program_end_real,
        closed,
      } = delivery_program[i];
      const ind_day_of_week = constants.db_to_datejs_ind[day_of_the_week];
      if (ind_day_of_week !== null && ind_day_of_week !== undefined) {
        if (!closed) {
          running_time[ind_day_of_week].push({
            start: program_start_real,
            end: program_end_real,
          });
        }
      }
    }
    return running_time;
  };
  test_right_interval = () => {
    return new Promise((resolve) => {
      const { id_venue } = this.state;
      const delivery_program = this.state.program_delivery;
      if (
        !delivery_program ||
        !Array.isArray(delivery_program) ||
        delivery_program.length === 0
      ) {
        resolve();
        return;
      }
      const command_closed_content_popup = this.program_delivery_to_string(
        delivery_program,
        id_venue
      );
      const ok = this.test_if_command_inactive(delivery_program, id_venue);
      this.setState(
        {
          forbiden_interval: !ok,
          forbiden_interval_allow_btns: true,
          command_closed_content_popup,
        },
        () => {
          if (this.state.forbiden_interval) {
            this.open_schedule_warning();
          }
          resolve();
        }
      );
    });
  };
  test_if_command_inactive = (delivery_program, id_venue) => {
    const running_time = this.convert_to_real_interval(
      delivery_program,
      id_venue
    );
    const today = new Date();

    const c_day_of_week = today.getDay();
    const limits = running_time[c_day_of_week];
    if (!limits || !Array.isArray(limits)) {
      return false;
    }
    return helperFunctions.is_between_time_intervals(
      helperFunctions.date_to_time(today),
      limits
    );
  };
  /**
   *
   * @param {string} date_string  - short date - Ex. "2021/03/15"
   * @param {object} additional - this helps pass things that you want to overwrite instead of use date from this state
   *                            - Ex. pass additional.shipping_form => the function will use this instead of this.state.shipping_form
   * @returns {string[]} - generate a valid set of hours (Ex. "20:00:00") that are allowed for shipping for a given short date
   */
  generate_valid_hours = (date_string, additional = {}) => {
    /**
     * some products if they are added to the cart they restrict the shipping
     */
    if (
      this.state.strictDeliveryDateBasedOnCart &&
      date_string + " 00:00:00" !== this.state.strictDeliveryDateBasedOnCart
    ) {
      return [];
    }

    const venueProgramIntervalThisDate = HomeHelper.computeProgramIntervals(
      this.state
    )(date_string, additional);

    // compute the date blocked for this date
    const venueBlockedIntervalsThisDate = HomeHelper.computeBlockedProgram(
      this.state
    )(date_string);

    //dev
    if (window.dev_debug_date && typeof window.dev_debug_date === "object") {
      if (Object.entries(window.dev_debug_date) > 40) {
        window.dev_debug_date = {};
      }
    } else {
      window.dev_debug_date = {};
    }
    window.dev_debug_date[date_string] = {
      venueProgramIntervalThisDate,
      venueBlockedIntervalsThisDate,
    };

    const hours = [];
    for (let i = 0; i < constants.try_hours_delivery.length; i++) {
      const currentHour = constants.try_hours_delivery[i];

      /**
       * hour is included in program for this date
       */
      const hourIsInProgram = HomeHelper.hourIsIncludedInterval()(
        currentHour,
        venueProgramIntervalThisDate
      );

      /**
       * hour is not blocked
       */
      const hourIsNotBlocked = !HomeHelper.hourIsIncludedInterval()(
        currentHour,
        venueBlockedIntervalsThisDate
      );

      /**
       * this date is not today OR this date + hour is valid as today
       */
      const hourIsNotTodayOrIsValidAsToday = HomeHelper.dateIsNotTodayOrIsValidAsToday(
        this.state
      )(currentHour, date_string);

      if (
        hourIsInProgram &&
        hourIsNotBlocked &&
        hourIsNotTodayOrIsValidAsToday
      ) {
        hours.push(currentHour);
      } else {
        //dev
        if (hourIsInProgram) {
          window.dev_debug_date[date_string][currentHour] = {
            hourIsInProgram,
            hourIsNotBlocked,
            hourIsNotTodayOrIsValidAsToday,
          };
        }
      }
    }

    //dev
    window.dev_debug_date[date_string].VALID_INTERVALS = hours;

    return hours;
  };
  test_date_has_open_program = (date_string) => {
    return this.generate_valid_hours(date_string).length > 0;
  };
  hardcodeCondition = () => {
    return false;
    // return this.state.id_venue === "1";
  };

  hardcodedStuffForAVenue = (type, additional) => {
    if (this.state.id_venue === "1") {
      switch (type) {
        case "generate_valid_hours":
          const date_string = additional.date_string;
          if (date_string == null) {
            return constants.try_hours_delivery;
          }
          //delivery
          if (
            additional.shipping_form.delivery_type === 1 ||
            additional.shipping_form.delivery_type === 3
          ) {
            if (date_string === "2020/04/17") {
              additional.shipping_form.hour = "00:00:00";
              return ["00:00:00"];
            } else {
              return [];
            }
          }
          //pickup
          if (additional.shipping_form.delivery_type === 2) {
            if (date_string === "2020/04/18") {
              return [
                "10:00:00",
                "10:30:00",
                "11:00:00",
                "11:30:00",
                "12:00:00",
                "12:30:00",
                "13:00:00",
                "13:30:00",
                "14:00:00",
                "14:30:00",
                "15:00:00",
                "15:30:00",
                "16:00:00",
              ];
            } else {
              return [];
            }
          }
          return [];
          break;
        case "on_change_delivery_type":
          if (additional.value === 1 || additional.value === 3) {
            if (additional.shipping_form.date === "2020/04/17") {
              additional.shipping_form.hour = "00:00:00";
            } else {
              additional.shipping_form.hour = null;
              additional.shipping_form.date = null;
              if (additional.fromSubmit) {
                additional.shipping_form.errors.hour = true;
                additional.shipping_form.errors.date = true;
              }
            }
          } else {
            if (additional.value === 2) {
              if (additional.shipping_form.date === "2020/04/18") {
                if (
                  [
                    "10:00:00",
                    "10:30:00",
                    "11:00:00",
                    "11:30:00",
                    "12:00:00",
                    "12:30:00",
                    "13:00:00",
                    "13:30:00",
                    "14:00:00",
                    "14:30:00",
                    "15:00:00",
                    "15:30:00",
                    "16:00:00",
                  ].indexOf(additional.shipping_form.hour) === -1
                ) {
                  additional.shipping_form.hour = null;
                  if (additional.fromSubmit) {
                    additional.shipping_form.errors.hour = true;
                  }
                }
                this.setState({
                  shipping_hour_items: [
                    "10:00:00",
                    "10:30:00",
                    "11:00:00",
                    "11:30:00",
                    "12:00:00",
                    "12:30:00",
                    "13:00:00",
                    "13:30:00",
                    "14:00:00",
                    "14:30:00",
                    "15:00:00",
                    "15:30:00",
                    "16:00:00",
                  ],
                });
              } else {
                additional.shipping_form.date = null;
                additional.shipping_form.hour = null;
                if (additional.fromSubmit) {
                  additional.shipping_form.errors.hour = true;
                  additional.shipping_form.errors.date = true;
                }
              }
            } else {
              if (additional.fromSubmit) {
                additional.shipping_form.errors.date = true;
                additional.shipping_form.errors.hour = true;
              }
            }
          }

          break;
        case "disable_delivery_hour":
          return (
            additional.shipping_form.delivery_type === 1 ||
            additional.shipping_form.delivery_type === 3
          );
          break;
      }
    }
  };

  test_underage_status = () => {
    const { age_check_needed, id_venue } = this.state;

    if (age_check_needed) {
      let user_is_adult_age = JSON.parse(
        localStorage.getItem("user_is_adult_age") || "{}"
      );

      if (user_is_adult_age == null || typeof user_is_adult_age !== "object") {
        user_is_adult_age = {};
      }

      if (!user_is_adult_age[id_venue]) {
        this.on_open_generic_modal({
          element: (
            <PopupFrame
              no_close_btn={true}
              general_color={this.state.general_color}
              BgStyle={{ ...this.configure_theme_bg_style() }}
            >
              {
                <React.Fragment>
                  <div
                    style={{ color: this.state.general_color }}
                    className="age_conf_modal_sub_container"
                  >
                    <div className="age_conf_modal_title">
                      Confirmare vârstă
                    </div>
                    <div className="age_conf_modal_content">
                      Pentru acest site,
                      <br /> este nevoie de confirmarea vârstei
                    </div>

                    <div className="age_conf_modal_btns_container">
                      <Button
                        color={this.state.general_color}
                        borderColor={this.state.general_color}
                        text="Am sub 18 ani"
                        onClick={() => {
                          const modal_key_b = "age_confirmation_blocked";
                          this.on_close_generic_modal(
                            "age_confirmation_",
                            () => {
                              this.on_open_generic_modal({
                                element: (
                                  <PopupFrame
                                    no_close_btn={true}
                                    general_color={this.state.general_color}
                                    BgStyle={{
                                      ...this.configure_theme_bg_style(),
                                    }}
                                  >
                                    {
                                      <div
                                        style={{
                                          color: this.state.general_color,
                                          width: "auto",
                                          height: "auto",
                                        }}
                                        className="age_conf_modal_sub_container"
                                      >
                                        <div className="age_conf_modal_content desk_only__">
                                          Ne pare rău, dar conținutul site-ului
                                          <br />
                                          este disponibil doar pentru persoanele
                                          <br />
                                          cu vârsta minimă de 18 ani.
                                        </div>
                                        <div className="age_conf_modal_content mob_only__">
                                          Ne pare rău, dar
                                          <br /> conținutul site-ului
                                          <br />
                                          este disponibil doar
                                          <br /> pentru persoanele
                                          <br />
                                          cu vârsta minimă de
                                          <br /> 18 ani.
                                        </div>
                                      </div>
                                    }
                                  </PopupFrame>
                                ),
                                key: modal_key_b,
                                priority: 9,
                              });
                            },
                            true
                          );
                          user_is_adult_age[id_venue] = null;
                          localStorage.setItem(
                            "user_is_adult_age",
                            JSON.stringify(user_is_adult_age)
                          );
                        }}
                      />
                      <Button
                        variant="var_outline_"
                        backgroundColor={this.state.general_color}
                        color={
                          this.state.white_theme
                            ? this.state.text_color
                            : undefined
                        }
                        text="Am peste 18 ani"
                        onClick={() => {
                          user_is_adult_age[id_venue] = 1;
                          localStorage.setItem(
                            "user_is_adult_age",
                            JSON.stringify(user_is_adult_age)
                          );

                          this.on_close_generic_modal(
                            "age_confirmation_",
                            null,
                            true
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              }
            </PopupFrame>
          ),
          key: "age_confirmation_",
        });
      }
    }
  };

  open_schedule_warning = () => {
    if (this.state.disable_program_popup) {
      return;
    }
    const modal_key = "schedule_warning";
    this.on_open_generic_modal({
      element: (
        <PopupFrame
          on_close_generic_modal={() => {
            this.on_close_generic_modal(modal_key, null, true);
          }}
          general_color={this.state.general_color}
          BgStyle={{ ...this.configure_theme_bg_style() }}
        >
          {this.state.command_closed_content_popup.type === "program" ? (
            <ContentProgramModal
              color={this.state.general_color}
              command_time_program={
                objectPath.get(
                  this.state.command_closed_content_popup,
                  "command_time_program"
                ) || []
              }
              // color={this.state.general_color}
              secondary_color={this.state.text_color}
            />
          ) : (
            <ContentPlainTextModal
              color={this.state.general_color}
              text={
                objectPath.get(
                  this.state.command_closed_content_popup,
                  "text"
                ) || ""
              }
            />
          )}
        </PopupFrame>
      ),
      key: modal_key,
    });
  };

  set_loader = (open, duration) => {
    this.props.setLayoutLoader({ layoutLoader: { open, duration } });
  };

  setMinDateDeliveryRule = () => {
    return new Promise((resolve, reject) => {
      const { program_delivery_rules } = this.state;
      const c_day_of_week = new Date().getDay();
      const today_timeset = helperFunctions.date_to_time(new Date());
      const todaysRule = (program_delivery_rules || []).find(
        ({ day_of_the_week, hour_start, hour_end }) => {
          return (
            c_day_of_week === constants.db_to_datejs_ind[day_of_the_week] &&
            helperFunctions.compare_timesets(today_timeset, hour_start) >= 0 &&
            helperFunctions.compare_timesets(today_timeset, hour_end) < 0
          );
        }
      );

      if (todaysRule) {
        const date = new Date();
        let delivery_rule = todaysRule.delivery_rule || "+0";
        delivery_rule =
          Number(delivery_rule.slice(1, delivery_rule.length)) || 0;

        date.setDate(date.getDate() + delivery_rule);
        const date_string = helperFunctions.date_to_string(date) + " 00:00:00";
        this.setState(
          {
            minDeliveryDate: date_string,
          },
          () => {
            resolve();
          }
        );
      } else {
        resolve();
      }
    });
  };
  getMinDeliveryDateAndStrictDeliveryDate = (lines) => {
    const productDeliveryRules = this.state.productDeliveryRules;

    let strictDeliveryDateBasedOnCart;
    let minDeliveryDateBasedOnCart;

    lines.forEach(({ id_product }) => {
      if (productDeliveryRules[id_product]) {
        const product_rules = productDeliveryRules[id_product];
        product_rules.forEach((rule) => {
          if (rule.delivery_rule === "+1") {
            const date = new Date();
            const offset =
              Number(rule.delivery_rule.slice(1, rule.delivery_rule.length)) ||
              0;
            date.setDate(date.getDate() + offset);
            const date_string =
              helperFunctions.date_to_string(date) + " 00:00:00";

            if (rule.starting_from !== "1") {
              strictDeliveryDateBasedOnCart = date_string;
            } else {
              if (
                !minDeliveryDateBasedOnCart ||
                helperFunctions.compare_dates(
                  new Date(date_string),
                  new Date(minDeliveryDateBasedOnCart)
                ) > 0
              ) {
                minDeliveryDateBasedOnCart = date_string;
              }
            }
          }
        });
      }
    });
    if (strictDeliveryDateBasedOnCart) {
      if (
        !minDeliveryDateBasedOnCart ||
        helperFunctions.compare_dates_string(
          minDeliveryDateBasedOnCart,
          strictDeliveryDateBasedOnCart
        ) > 0
      ) {
        minDeliveryDateBasedOnCart = strictDeliveryDateBasedOnCart;
      }
    }
    return {
      strictDeliveryDateBasedOnCart,
      minDeliveryDateBasedOnCart,
    };
  };
  setMinDateDeliveryRulesBasedOnCart = (customRule) => {
    return new Promise((resolve, reject) => {
      const lines = this.getIndividualCartLines(
        this.state.command_cart_products
      );
      let minDeliveryDateBasedOnCart;
      let strictDeliveryDateBasedOnCart;
      if (customRule) {
        minDeliveryDateBasedOnCart = customRule.minDeliveryDateBasedOnCart;
        strictDeliveryDateBasedOnCart =
          customRule.strictDeliveryDateBasedOnCart;
      } else {
        const cartRule = this.getMinDeliveryDateAndStrictDeliveryDate(lines);
        minDeliveryDateBasedOnCart = cartRule.minDeliveryDateBasedOnCart;
        strictDeliveryDateBasedOnCart = cartRule.strictDeliveryDateBasedOnCart;
      }

      const minDateIsBigger =
        !this.state.minDeliveryDate ||
        (minDeliveryDateBasedOnCart &&
          helperFunctions.compare_dates(
            new Date(minDeliveryDateBasedOnCart),
            new Date(this.state.minDeliveryDate)
          ) > 0);
      this.setState(
        {
          minDeliveryDateBasedOnCart: minDateIsBigger
            ? minDeliveryDateBasedOnCart
            : null,
          strictDeliveryDateBasedOnCart: strictDeliveryDateBasedOnCart,
          currentMinDeliveryDate: minDateIsBigger
            ? minDeliveryDateBasedOnCart
            : this.state.minDeliveryDate,
        },
        () => {
          resolve();
        }
      );
    });
  };
  get_info_parsed_l_st_venue(id_venue, property) {
    switch (property) {
      case "command_cart_products": {
        let all_command_cart_local_st = null;
        try {
          all_command_cart_local_st = JSON.parse(
            localStorage.getItem("all_command_cart_products_v2")
          );
        } catch (err) {
          all_command_cart_local_st = null;
        }

        if (
          helperFunctions.is_object(all_command_cart_local_st) &&
          Array.isArray(all_command_cart_local_st[id_venue])
        ) {
          return all_command_cart_local_st[id_venue];
        }
        return null;
      }
      case "shipping_form": {
        const all_shipping_form = JSON.parse(
          localStorage.getItem("all_shipping_form")
        );
        if (
          helperFunctions.is_object(all_shipping_form) &&
          helperFunctions.is_object(all_shipping_form[id_venue])
        ) {
          return all_shipping_form[id_venue];
        }
        return null;
      }
      case "user_is_adult_age": {
        const user_is_adult_age = JSON.parse(
          localStorage.getItem("user_is_adult_age")
        );
        if (
          helperFunctions.is_object(user_is_adult_age) &&
          user_is_adult_age[id_venue]
        ) {
          return user_is_adult_age[id_venue];
        }
        return null;
      }
      case "tips": {
        const all_tips = JSON.parse(localStorage.getItem("all_tips"));
        if (
          helperFunctions.is_object(all_tips) &&
          helperFunctions.is_object(all_tips[id_venue])
        ) {
          return all_tips[id_venue];
        }
        return null;
      }
      case "cookies": {
        const all_cookies = JSON.parse(localStorage.getItem("all_cookies"));
        if (helperFunctions.is_object(all_cookies)) {
          return all_cookies[id_venue];
        }
        return null;
      }
    }
    return null;
  }
  set_info_parsed_l_st_venue(id_venue, property, value) {
    switch (property) {
      case "command_cart_products":
        {
          let all_command_cart_local_st = JSON.parse(
            localStorage.getItem("all_command_cart_products_v2")
          );
          if (helperFunctions.is_object(all_command_cart_local_st)) {
            all_command_cart_local_st[id_venue] = value;
          } else {
            all_command_cart_local_st = { [id_venue]: value };
          }
          localStorage.setItem(
            "all_command_cart_products_v2",
            JSON.stringify(all_command_cart_local_st)
          );
        }
        break;
      case "shipping_form":
        {
          let all_shipping_form = localStorage.getItem("all_shipping_form")
            ? JSON.parse(localStorage.getItem("all_shipping_form"))
            : null;
          if (helperFunctions.is_object(all_shipping_form)) {
            all_shipping_form[id_venue] = value;
          } else {
            all_shipping_form = {};
            all_shipping_form[id_venue] = { ...value };
          }
          localStorage.setItem(
            "all_shipping_form",
            JSON.stringify(all_shipping_form)
          );
        }
        break;
      case "user_is_adult_age": {
        let user_is_adult_age = JSON.parse(
          localStorage.getItem("user_is_adult_age")
        );
        if (helperFunctions.is_object(user_is_adult_age)) {
          user_is_adult_age[id_venue] = value;
        } else {
          user_is_adult_age = { [id_venue]: value };
        }
        localStorage.setItem(
          "user_is_adult_age",
          JSON.stringify(user_is_adult_age)
        );
      }
      case "tips":
        let all_tips = localStorage.getItem("all_tips")
          ? JSON.parse(localStorage.getItem("all_tips"))
          : null;
        if (helperFunctions.is_object(all_tips)) {
          all_tips[id_venue] = value;
        } else {
          all_tips = {};
          all_tips[id_venue] = { ...value };
        }
        localStorage.setItem("all_tips", JSON.stringify(all_tips));
        break;
      case "cookies":
        let all_cookies = localStorage.getItem("all_cookies")
          ? JSON.parse(localStorage.getItem("all_cookies"))
          : null;
        if (helperFunctions.is_object(all_cookies)) {
          all_cookies[id_venue] = value;
        } else {
          all_cookies = {};
          all_cookies[id_venue] = value;
        }
        localStorage.setItem("all_cookies", JSON.stringify(all_cookies));
        break;
    }
  }
  check_for_prev_transaction = (callback) => {
    const { id_venue } = this.state;
    const { payment, id_delivery, t, order } = helperFunctions.return_parameter(
      ["payment", "id_delivery", "t", "order"]
    );
    //get link without getparams
    const queryString = window.location.search;
    let hrf = window.location.href;
    hrf = hrf.split(queryString)[0];

    if (id_delivery) {
      const modal_key = "payment_modal";
      const close_dialog = () => {
        this.on_close_generic_modal(modal_key, null, true);
        window.location.href = helperFunctions.clear_href_of_parameters([
          "payment",
          "order",
          "id_delivery",
        ]);
      };

      if (payment === "ok" || order === "ok") {
        this.setState(
          {
            command_cart_products: {},
            tipValue: null,
          },
          () => {
            this.set_info_parsed_l_st_venue(
              this.state.id_venue,
              "command_cart_products",
              {}
            );
            this.set_info_parsed_l_st_venue(this.state.id_venue, "tips", null);
            if (callback) {
              callback();
            }
          }
        );
        window.scrollTo(0, 0);
        const paymentPopupStyleClassName = css`
          p,
          h1 {
            color: ${this.state.general_color}!important;
          }
        `;
        this.on_open_generic_modal({
          element: (
            <div
              className="_payment_modal paymentPopupStyle"
              style={{ ...this.configure_theme_bg_style() }}
            >
              <div
                style={{ borderColor: this.state.general_color }}
                className={cx(
                  "payment_conf_modal_container",
                  paymentPopupStyleClassName
                )}
              >
                <div className="pop_up_close_btn">
                  <svg
                    onClick={close_dialog}
                    style={{ fill: this.state.general_color }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 104.79 104.65"
                  >
                    <g>
                      <g>
                        <polygon points="0.06 12.11 12.13 0.01 52.38 40.34 92.68 0 104.72 12.02 64.32 52.49 104.79 92.62 92.78 104.65 52.43 64.39 12.05 104.61 0 92.58 40.39 52.38 0.06 12.11" />
                      </g>
                    </g>
                  </svg>
                </div>
                {this.state.custom_order_popup_text ? (
                  <React.Fragment>
                    {" "}
                    {this.state.custom_order_popup_text}{" "}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p>
                      Comanda ta a fost plasată. <br /> Vei fi contactat pe
                      e-mail în cel mai scurt timp.
                    </p>
                    <p>Mulțumim!</p>
                  </React.Fragment>
                )}
              </div>
            </div>
          ),
          key: modal_key,
          priority: 10,
        });
      } else {
        if (payment === "failed") {
          window.scrollTo(0, 0);
          this.on_open_generic_modal({
            element: (
              <div
                className="_payment_modal"
                style={{ ...this.configure_theme_bg_style() }}
              >
                <div
                  style={{ borderColor: this.state.general_color }}
                  className="payment_conf_modal_container"
                >
                  <div className="pop_up_close_btn">
                    <svg
                      onClick={close_dialog}
                      style={{ fill: this.state.general_color }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 104.79 104.65"
                    >
                      <g>
                        <g>
                          <polygon points="0.06 12.11 12.13 0.01 52.38 40.34 92.68 0 104.72 12.02 64.32 52.49 104.79 92.62 92.78 104.65 52.43 64.39 12.05 104.61 0 92.58 40.39 52.38 0.06 12.11" />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div
                    className="popup_paragraf"
                    style={{
                      color: this.state.general_color,
                    }}
                  >
                    Din păcate,
                    <br /> a fost o problemă la procesarea plății,
                    <br /> iar comanda nu a fost plasată.
                  </div>
                  <div
                    className="popup_paragraf"
                    style={{
                      color: this.state.general_color,
                    }}
                  >
                    Te rugăm sa încerci din nou.
                  </div>
                  <div className={"btn_container____"}>
                    <div
                      style={{
                        backgroundColor: this.state.general_color,
                        textAlign: "center",
                      }}
                      className={"sub_btn____"}
                      onClick={() => {
                        window.location.href = `https://api.thefootballbrain.app/euplatesc/ep_send.php?id_delivery=${id_delivery}&id_venue=${id_venue}`;
                      }}
                    >
                      <a style={{ color: this.state.inner_text_color }}>
                        Încearcă din nou să{" "}
                        <span className="mob_only__">
                          <br />
                        </span>{" "}
                        efectuezi plata
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ),
            key: modal_key,
            priority: 10,
          });
        }
      }
    }
    if (t) {
      axios.get(`feedback/${this.state.id_venue}/${t}`).then(({ data }) => {
        if (data.success === 1) {
          const modal_key = "feedback_modal_";
          this.on_open_generic_modal({
            element: (
              <FeedbackPopup
                id_venue={this.state.id_venue}
                id_delivery={data.result.id_delivery}
                token={t}
                key={t}
                general_color={this.state.general_color}
                setLoader={(active) => this.set_loader(active)}
                text_color={this.state.text_color}
                frameProps={{
                  on_close_generic_modal: () => {
                    this.on_close_generic_modal(modal_key, null, true);
                  },
                  general_color: this.state.general_color,
                  BgStyle: { ...this.configure_theme_bg_style() },
                }}
                product_separator_color={this.state.product_separator_color}
                white_theme={this.state.white_theme}
                is_mobile={this.state.is_mobile}
              />
            ),
            key: modal_key,
            priority: 9,
            modalClassName: "__feedback__modal",
          });
        }
      });
    }
  };
  on_focus_input_mobile_receipt = (e) => {
    // if (
    //   document.getElementById("receipt_command__") &&
    //   document
    //     .getElementById("receipt_command__")
    //     .classList.contains("show_cart_command_for_mobile") &&
    //   !this.mob_input_focus
    // ) {
    //   this.mob_input_focus = true;
    // }
  };
  on_blur_input_mobile_receipt = (e) => {
    // if (e.currentTarget.tagName === "INPUT") {
    //   return;
    // }
    // if (
    //   document.getElementById("receipt_command__") &&
    //   document
    //     .getElementById("receipt_command__")
    //     .classList.contains("show_cart_command_for_mobile")
    // ) {
    //   // document.getElementById("receipt_command__").style.height = "";
    //   this.mob_input_focus = false;
    //   if(window.innerWidth <= 1024){
    //    setTimeout(this.show_cart_mobile_dialog, 500);
    //   }
    // }
  };
  focus_next_input_on_enter = (event) => {
    if (event.key === "Enter") {
      const parentSibling = event.target.parentNode.nextSibling;
      let node_input;
      if (parentSibling && parentSibling.children.length > 0) {
        for (let i = 0; i < parentSibling.children.length; i++) {
          const child = parentSibling.children[i];
          if (child.tagName === "INPUT") {
            node_input = child;
            break;
          }
        }
      }
      if (node_input) {
        node_input.focus();
      }
    }
  };
  on_resize_window = (e) => {
    this.run_dropdown_resfresh_positioning();
    if (
      this.state.touchDeviceClientHeight &&
      window.innerHeight > this.state.touchDeviceClientHeight
    ) {
      this.state.touchDeviceClientHeight = window.innerHeight;
    }
    if (
      this.scroll_y_locked &&
      document.getElementById("receipt_command__") &&
      document
        .getElementById("receipt_command__")
        .classList.contains("show_cart_command_for_mobile")
    ) {
      if (window.innerWidth > 1024) {
        this.hide_cart_mobile_dialog();
      } else {
        this.show_cart_mobile_dialog();
      }
    }
    this.updateModalsHeight();
    this.detect_mobile_screen_size();
  };
  updateModalsHeight = () => {
    if (document.getElementsByClassName("___modal")) {
      let modals = document.getElementsByClassName("___modal");
      for (let i = 0; i < modals.length; i++) {
        modals[i].style.height =
          (this.state.touchDeviceClientHeight || window.innerHeight) + "px";
      }
    }
  };
  run_dropdown_resfresh_positioning = () => {
    const {
      refresh_opening_callback,
      refresh_opening_callback_target,
    } = this.state;
    if (refresh_opening_callback)
      refresh_opening_callback(refresh_opening_callback_target);
  };
  getCoords = (elem) => {
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft =
      window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  };
  on_scroll_window = (e) => {
    this.updateModalsHeight();
    if (!this.navScrollListenerDisabled && !e.currentTarget.tagName) {
      const categoryNodes = document.querySelectorAll(
        "div[category-node-ref='true']"
      );
      const scrollY = Math.floor(window.scrollY * 100) / 100 + 20;
      if (categoryNodes.length && scrollY) {
        let indexFound = 0;

        for (let i = 0; i < categoryNodes.length; i++) {
          const thisTop = this.getCoords(categoryNodes[i]).top - 50;
          if (thisTop && scrollY >= thisTop) {
            indexFound = i;
          } else {
            break;
          }
        }

        if (indexFound !== this.state.sel_command_cat_index) {
          this.setState({ sel_command_cat_index: indexFound });
        }
      }
    }
    if (!this.scroll_y_locked) {
      this.scroll_Y_window = window.scrollY;
    }

    if (e.currentTarget.tagName) {
      if (this.scroll_container_receipt.current.contains(e.currentTarget)) {
        e.preventDefault();
      }

      if (
        e.currentTarget.classList.contains("command_receipt_items_container")
      ) {
        this.on_close_receipt_dropdown();
      } else {
        this.run_dropdown_resfresh_positioning();
      }
      return;
    }
    if (this.scroll_y_locked) {
      return;
    }
    if (!this._command_section_ref.current) {
      return;
    }
    //see when scroll top hit the command section
    const offsetTop_win_relative = Math.floor(
      this._command_section_ref.current.getBoundingClientRect().top +
      window.scrollY +
      (5.5 / 100) * window.innerWidth
    );
    const command_section_height = this._command_section_ref.current
      .clientHeight;
    const win_top = Math.floor(e.currentTarget.pageYOffset);

    let sticky = false;
    let command_section_is_higher = false;
    if (
      win_top > offsetTop_win_relative &&
      win_top + window.innerHeight <
      offsetTop_win_relative + command_section_height &&
      command_section_height >
      window.innerHeight + Math.floor(window.innerHeight / 3)
    ) {
      sticky = true;

      // //see which category
      // const { command_categories } = this.state;
      // for (let i = command_categories.length - 1; i >= 0; i--) {
      //   const id_item_name =
      //     "" + "command_item_scroll_ref_" + command_categories[i].id;
      //   const el = document.getElementById(id_item_name);
      //   if (el) {
      //     const offset = Math.floor(
      //       el.getBoundingClientRect().top + window.scrollY
      //     );
      //     if (win_top < offset) {
      //       this.onChangeNavBarCommand(i, true);
      //       break;
      //     }
      //   }
      // }
    } else {
      if (win_top > offsetTop_win_relative) {
        command_section_is_higher = true;
      }
    }
    if (this.state.sticky_command_navbar !== sticky) {
      this.setState({
        sticky_command_navbar: sticky,
        command_section_is_higher: command_section_is_higher,
      });
    }
  };
  iterateCategoryProduct = (mul) => {
    const { sel_command_cat_index, command_categories } = this.state;

    if (
      sel_command_cat_index + mul >= 0 &&
      sel_command_cat_index + mul < command_categories.length
    ) {
      this.onChangeNavBarCommand(sel_command_cat_index + mul);
    }
  };
  scrollToSection = (
    id,
    callback,
    duration = 600,
    inc_position = 0,
    horizoltal
  ) => {
    if (document.getElementById(id)) {
      let scroll_state = {};
      if (horizoltal) {
        const position = $("#" + id).offset().left + inc_position;
        scroll_state = {
          scrollLeft: position,
        };
      } else {
        const position = $("#" + id).offset().top + inc_position;
        scroll_state = {
          scrollTop: position,
        };
      }

      this.setState(
        {
          isFetchingScrollAnim: {
            ...(this.state.isFetchingScrollAnim || {}),
            [id]: true,
          },
        },
        () => {
          if (duration < 0) {
            if (scroll_state.scrollTop) {
              $("body, html").scrollTop(scroll_state.scrollTop);
            }
            if (scroll_state.scrollLeft) {
              $("body, html").scrollLeft(scroll_state.scrollLeft);
            }
            if (callback) callback();
            this.setState({
              isFetchingScrollAnim: {
                ...(this.state.isFetchingScrollAnim || {}),
                [id]: false,
              },
            });
          } else {
            $("body, html").animate(scroll_state, duration, () => {
              if (callback) callback();
              this.setState({
                isFetchingScrollAnim: {
                  ...(this.state.isFetchingScrollAnim || {}),
                  [id]: false,
                },
              });
            });
          }
        }
      );
    } else {
      if (callback) callback();
    }
  };
  scroll_nav_bar_horizonally = (
    cat_index,
    callback,
    duration = 600,
    inc_position = 0
  ) => {
    if (
      this._nav_bar_ref.current &&
      this._nav_bar_ref.current.children.length > 1 &&
      this._nav_bar_ref.current.children[1].classList.contains(
        "navBarOverflowContainer"
      ) &&
      this._nav_bar_ref.current.children[1].children.length > cat_index
    ) {
      const cat_el_with = this._nav_bar_ref.current.children[1].children[
        cat_index
      ].clientWidth;
      const cat_el_offset_left =
        this._nav_bar_ref.current.children[1].children[cat_index].offsetLeft +
        inc_position;

      const scroll_state = {
        scrollLeft: cat_el_offset_left - cat_el_with,
      };
      $(this._nav_bar_ref.current.children[1]).animate(
        scroll_state,
        duration,
        () => {
          if (callback) callback();
        }
      );
    }
  };
  onChangeNavBarCommand = (index, no_scrolling_anim) => {
    const { command_categories, sel_command_cat_index } = this.state;
    if (!command_categories[index] || index === sel_command_cat_index) {
      return;
    }
    //get item category id where we want to scroll to
    const command_id = command_categories[index].id;
    const id_item_to_scroll_to = "command_item_scroll_ref_" + command_id;
    const callback = () => {
      this.setState({
        sel_command_cat_index: index,
      });
    };
    let nav_bar_height_if_sticky = 0;
    if (this.state.sticky_command_navbar && this._nav_bar_ref.current) {
      nav_bar_height_if_sticky = this._nav_bar_ref.current.clientHeight * -1;
      if (this.state.is_mobile) {
        if (index === 0) {
          nav_bar_height_if_sticky = -20;
        } else {
          nav_bar_height_if_sticky = -20;
        }
      } else {
        if (index === 0) {
          nav_bar_height_if_sticky = -100;
        } else {
          nav_bar_height_if_sticky = -100;
        }
      }
    }
    callback();
    if (!no_scrolling_anim) {
      this.navScrollListenerDisabled = true;
      const thisDuration = this.state.category_nav_bar_scroll_duration || 600;
      setTimeout(() => {
        this.navScrollListenerDisabled = false;
      }, thisDuration + 100);

      this.scrollToSection(
        id_item_to_scroll_to,
        null,
        thisDuration,
        nav_bar_height_if_sticky
      );
    }
    this.scroll_nav_bar_horizonally(index, null, 100);
  };
  get_array_of_prop_names = (arr, prop_name = "id") => {
    if (!Array.isArray(arr)) {
      return [];
    }
    let keys = [];
    arr.forEach((item) => {
      if (item[prop_name]) {
        keys.push(item[prop_name]);
      }
    });
    return keys;
  };
  get_indexing_obj = (arr, prop_name = "id") => {
    let keys = {};
    arr.forEach((item, index) => {
      if (item[prop_name]) {
        keys[item[prop_name]] = index;
      }
    });
    return keys;
  };
  setCartProductLocalStorage = () => {
    const { command_cart_products } = this.state;
    this.set_info_parsed_l_st_venue(
      this.state.id_venue,
      "command_cart_products",
      this.getIndividualCartLines().map((line) => {
        return {
          id: line.id,
          quantity: line.quantity,
          id_bundle: line.id_bundle,
          id_size: line.id_size,
          ...(line.id_target ? { id_target: line.id_target } : {}),
        };
      })
    );
  };
  setFormDataLocalStorage = () => {
    const { shipping_form } = this.state;
    this.set_info_parsed_l_st_venue(
      this.state.id_venue,
      "shipping_form",
      shipping_form
    );
  };
  onChangeShippingForm = (property, value, hideError) => {
    let { shipping_form } = this.state;
    let state_load = {};
    switch (property) {
      case "email":
        value = (value || "").trim();
        shipping_form = {
          ...this.state.shipping_form,
          email: value,
        };
        break;
      case "delivery_type":
        shipping_form = {
          ...this.state.shipping_form,
          delivery_type: value,
        };
        break;
      case "delivery_asap":
        shipping_form = {
          ...this.state.shipping_form,
          delivery_asap: value,
        };
        break;
      case "date":
        shipping_form = {
          ...this.state.shipping_form,
          date: value,
        };
        break;
      default:
        shipping_form = {
          ...this.state.shipping_form,
          [property]: value,
        };
        break;
    }
    const errorValue = this.find_error_for_shipping_field(
      property,
      value,
      shipping_form
    );

    if (!hideError || !errorValue) {
      shipping_form.errors[property] = errorValue;
    }
    state_load.shipping_form = shipping_form;

    const validateDateOnDeliveryTypeChange = () => {
      if (property === "delivery_type" && state_load.shipping_form.date) {
        let validHours = this.generate_valid_hours(
          state_load.shipping_form.date,
          state_load.shipping_form
        );
        if (
          !state_load.shipping_form.date ||
          helperFunctions.compare_dates(
            new Date(state_load.shipping_form.date),
            new Date(helperFunctions.date_to_string(new Date()))
          ) < 0 ||
          validHours.length === 0
        ) {
          state_load.shipping_form.date = null;
          state_load.shipping_form.hour = null;
          validHours = [];
        } else {
          if (validHours.indexOf(state_load.shipping_form.hour) === -1) {
            state_load.shipping_form.hour = null;
          }
        }

        state_load.shipping_hour_items =
          validHours.length === 0 ? constants.try_hours_delivery : validHours;

        return new Promise((resolve) => {
          this.setState(
            {
              ...state_load,
            },
            () => {
              this.setFormDataLocalStorage();

              resolve();
            }
          );
        });
      }

      return new Promise((resolve) => {
        resolve();
      });
    };

    this.setState(
      {
        ...state_load,
      },
      () => {
        this.setFormDataLocalStorage();
        validateDateOnDeliveryTypeChange().finally(() => {
          this.checkPriceIntervals();
        });
      }
    );
  };
  redo_hour_and_date_form = (shipping_form) => { };

  find_error_for_shipping_field(field, value, shipping_form, additional) {
    if (shipping_form.delivery_asap && this.state.forbiden_interval) {
      shipping_form.delivery_asap = false;
    }
    if (helperFunctions.find_error_for_inputs[field]) {
      if (helperFunctions.find_error_for_inputs[field](value)) {
        return true;
      }
      return false;
    }

    if (field === "date" || field === "hour" || field === "delivery_asap") {
      if (this.state.disable_delivery) {
        this.disableDeliverySetup(shipping_form);
      }
      let non_empty_date_has_errors = shipping_form.date
        ? !this.test_date_has_open_program(shipping_form.date)
        : false;

      if (non_empty_date_has_errors) {
        if (this.check_disable_delivery_hour()) {
          shipping_form.hour = "00:00:00";
        } else {
          shipping_form.hour = null;
        }
        shipping_form.date = null;
        shipping_form.errors.date = true;
        shipping_form.errors.hour = true;
      }
      if (field === "delivery_asap") {
        return false;
      } else {
        if (field === "date") {
          const valid_hours = this.generate_valid_hours(value);
          const shipping_hour_items = valid_hours;
          if (this.check_disable_delivery_hour()) {
            shipping_form.hour = "00:00:00";
          } else {
            if (!valid_hours.includes(shipping_form.hour)) {
              shipping_form.hour = null;
            }
            if (valid_hours.length === 1) {
              shipping_form.hour = valid_hours[0];
            }
          }
          this.setState({ shipping_hour_items: shipping_hour_items });
        }

        if (shipping_form.delivery_asap) {
          return false;
        }

        return !shipping_form[field];
      }
    }
    switch (field) {
      case "address":
        return !value && shipping_form.delivery_type !== 2;
      case "id_venue_location":
        const all_id_venue_location = Object.keys(this.state.venue_locations);
        if (!value) {
          if (all_id_venue_location.length > 0) {
            if (all_id_venue_location.length === 1) {
              shipping_form.id_venue_location = all_id_venue_location[0];
              return false;
            }
            return true;
          }
          return false;
        } else {
          if (!this.state.venue_locations[value]) {
            shipping_form.id_venue_location = null;
            return true;
          }
          return false;
        }
      case "delivery_type":
        const posible_types = this.get_delivery_options_filtered();
        if (posible_types.length === 1) {
          shipping_form.delivery_type = posible_types[0].id;
          return false;
        }
        return constants.delivery_type_names[value] == null;
      case "required_terms_checkbox":
        if (this.state.use_cart_required_terms_checkbox) {
          if (value !== true) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
    }
    return false;
  }
  disableDeliverySetup(shipping_form) {
    shipping_form.delivery_asap = true;
    shipping_form.delivery_type = this.state.disable_delivery_with_delivery
      ? 1
      : 2;
    if (this.state.disable_delivery_with_delivery) {
      shipping_form.delivery_asap = true;
    }
  }
  submitShippingFormular = () => {
    const { shipping_form } = this.state;
    shipping_form["email"] = (shipping_form["email"] || "").trim();
    if (this.check_disable_delivery_hour()) {
      shipping_form.hour = "00:00:00";
    }
    if (this.state.disable_delivery) {
      this.disableDeliverySetup(shipping_form);
    }
    const errors = {};
    for (let field in shipping_form) {
      errors[field] = this.find_error_for_shipping_field(
        field,
        shipping_form[field],
        shipping_form,
        { fromSubmit: true }
      );
    }

    // extra check for the hour (damage control)
    // find_error_for_shipping_field has seconday effects on hour (makes it null sometimes, when the 'date' field is checked, after the hour has been already validated) and this is rechecked here
    ((field) => {
      errors[field] = this.find_error_for_shipping_field(
        field,
        shipping_form[field],
        shipping_form,
        { fromSubmit: true }
      );
    })("hour");

    //recheck location for errors
    if (
      Object.keys(this.state.venue_locations).length === 0 ||
      (shipping_form.delivery_type !== 2 && !this.state.always_show_location)
    ) {
      errors["id_venue_location"] = false;
    }
    //recheck county for errors
    if (shipping_form.delivery_type === constants.DELIVERY_TYPES.pickup) {
      errors["id_county"] = false;
    } else {
      errors["id_county"] =
        !shipping_form.id_county ||
        !this.state.venue_counties.some(
          (_item) => _item.id_county === shipping_form.id_county
        );
    }

    //recheck required_terms_checkbox for errors
    if (this.state.use_cart_required_terms_checkbox) {
      if (this.state.shipping_form.required_terms_checkbox !== true) {
        errors["required_terms_checkbox"] = true;
      } else {
        errors["required_terms_checkbox"] = false;
      }
    } else {
      errors["required_terms_checkbox"] = false;
    }

    if (this.state.delivery_asap_only) {
      shipping_form.delivery_asap = true;
      shipping_form.hour = null;
      shipping_form.date = null;
      errors["delivery_asap"] = false;
      errors["hour"] = false;
      errors["date"] = false;
    }

    if (Object.keys(errors).every((er) => errors[er] == false)) {
      const {
        totalPriceReferenceForMinOrder,
      } = this.get_command_cart_products_stats();
      if (this.state.min_order > totalPriceReferenceForMinOrder) {
        this.open_warning_popup(
          `Valoarea minimă a comenzii pentru adresa introdusă este de ${this.state.min_order
          } lei.`
        );
        return;
      }

      //submit
      const { command_cart_products, id_venue } = this.state;

      const payload = {
        id_venue: id_venue,
        client_data: {
          email: shipping_form["email"],
          name: shipping_form["name"],
          address: shipping_form["address"],
          id_county: shipping_form["id_county"],
          phone: shipping_form["phone"],
          message: shipping_form["message"],
          delivery_type:
            constants.delivery_type_names[shipping_form["delivery_type"]],
          receive_emails: shipping_form["receive_emails"] ? 1 : 0,
          ...(shipping_form["delivery_asap"]
            ? { delivery_asap: 1 }
            : {
              delivery_asap: 0,
              delivery_date: `${(shipping_form["date"] || "").replace(
                /\//gi,
                "-"
              )} ${shipping_form["hour"]}`,
            }),
          id_venue_location: shipping_form["id_venue_location"],
          payment_type: shipping_form["payment_type"] || "online",
        },
        products: this.getIndividualCartLines().reduce((final, line) => {
          const { id_product, price, quantity, title, id_tax } = line;
          const __item = {
            id_product,
            price,
            quantity,
            id_tax,
            product_name: title,
            ...line,
            product_description: null,
            id_tax: line.id_tax || 1,
            is_size: "0",
          };

          delete __item["description"];
          delete __item["assocCategoryInitialState_json"];

          final.push(__item);

          /**
           * each target product has the selected sizeItem (if has sizes)
           */
          if (line.sizeItem?.id_assoc && line.sizeItem.is_size === "1") {
            final.push({
              id_product: line.sizeItem.id_assoc,
              price: line.sizeItem.assoc_price,
              id_tax: line.sizeItem.assoc_tax,
              product_name: line.sizeItem.assoc_name,

              id_bundle: line.id_bundle,
              id_target: id_product,
              quantity: 1,
              is_size: "1",
            });
          }

          return final;
        }, []),

        delivery_price:
          this.getIndividualCartLines().length === 0
            ? 0
            : this.get_sub_total_price() >= this.state.free_delivery_from
              ? 0
              : shipping_form["delivery_type"] === 2
                ? 0
                : this.state.delivery_price,
        tips: this.state.enable_tips ? this.calculateTip() : 0,
        total_package: this.calculateTotalPackagePrice(),
        id_promo: objectPath.get(this.state, "currentPromo.id_promo") || "",
      };
      const state_load = {
        errors: {},
      };
      if (window.dev === 1 && window.disableSubmit === 1) {
        alert("Succes!");
        console.log("DevEnv-submitShippingForm", payload);
        if (!this.state.id_venue === "17") {
          //timeless
          return;
        }
      }
      this.set_loader(true);
      axios
        .post(`https://api.thefootballbrain.app/shop/placeorder/index.php`, payload)
        .then(({ data }) => {
          this.setState(
            {
              shipping_form: {
                ...this.state.shipping_form,
                message: "",
              },
            },
            () => {
              this.setFormDataLocalStorage();
              
              //temporar: for when we will receive data as {id_delivery, id_client} (not just data as integer)
              if (!Number.isInteger(data)) {
                if (
                  helperFunctions.is_object(data) &&
                  objectPath.get(data, "id_delivery")
                ) {
                  const responseData = JSON.parse(JSON.stringify(data));
                  data = responseData.id_delivery;
                  const id_client = responseData.id_client;
                  if (id_client) {
                    helperFunctions.set_info_parsed_l_st_venue(
                      this.state.id_venue,
                      "clients",
                      id_client
                    );
                  }
                }
              }
              if (Number.isInteger(data)) {
                this.setState(
                  {
                    ...state_load,
                  },
                  () => {
                    this.setFormDataLocalStorage();
                  }
                );
                if (payload.client_data.payment_type !== "online") {
                  window.location.href = helperFunctions.appendGetParamToLink(
                    helperFunctions.clear_href_of_parameters([
                      "order",
                      "id_delivery",
                    ]),
                    [["order", "ok"], ["id_delivery", data]]
                  );
                } else {
                  window.location.href = `https://api.thefootballbrain.app/euplatesc/ep_send.php?id_delivery=${data}&id_venue=${id_venue}`;
                }
              } else {
                console.log(
                  "Something wrong while submitting the command request"
                );
                this.set_loader(false);
                this.setState(
                  {
                    ...state_load,
                  },
                  () => {
                    this.setFormDataLocalStorage();
                  }
                );
              }
            }
          );
        })
        .catch((err) => {
          console.log("Error sending command.", err);
          this.setState(
            {
              ...state_load,
            },
            () => {
              this.setFormDataLocalStorage();
            }
          );
          this.set_loader(false);
        });
    } else {
      this.setState(
        {
          shipping_form: {
            ...shipping_form,
            errors: errors,
          },
        },
        () => {
          if (this.state.shipping_form.errors["required_terms_checkbox"]) {
            this.open_warning_popup_checkbox(
              this.state.required_terms_checkbox_warning ||
              "Te rugam să bifezi acordul pentru termeni și condiții."
            );
          }
          this.setFormDataLocalStorage();
        }
      );
      if (window.dev === 1 && window.disableSubmit === 1) {
        alert(
          "Errors:" +
          Object.keys(errors)
            .filter((prop) => errors[prop])
            .join(", ")
        );
      }
    }
  };
  inc_command_cart_qty = (id_bundle, inc, id_target) => {
    const { command_cart_products } = this.state;
    if (
      !command_cart_products[id_bundle] ||
      command_cart_products[id_bundle].markedForDeleting
    ) {
      return;
    }
    let deleteBundle;
    Object.entries(command_cart_products[id_bundle]).map(
      ([id_product, product_line]) => {
        command_cart_products[id_bundle][id_product].quantity += inc;
        if (command_cart_products[id_bundle][id_product].quantity == 0) {
          deleteBundle = true;
        }
      }
    );

    if (deleteBundle) {
      // command_cart_products[id_bundle].markedForDeleting = true;
      this.deleteBundle(id_bundle);
    } else {
      this.setState({ command_cart_products: command_cart_products }, () => {
        this.setCartProductLocalStorage();
        this.onOrderTotalChange();
        //###price_change
      });
    }
  };
  deleteBundle = (id_bundle) => {
    const { command_cart_products } = this.state;
    delete command_cart_products[id_bundle];

    this.setState({ command_cart_products }, () => {
      this.setCartProductLocalStorage();
      this.setMinDateDeliveryRulesBasedOnCart().then(() => {
        this.shippingFormInitialValidation();
        this.onOrderTotalChange();
        //###price_change
      });
    });
  };

  getIndividualCartLines = (custom_command_cart_products) => {
    const command_cart_products =
      custom_command_cart_products || this.state.command_cart_products;
    const lines = [];
    Object.entries(command_cart_products).forEach(([id_bundle, bundle]) => {
      Object.entries(bundle)
        .sort(([id_product1, productLine1], [id_product2, productLine2]) => {
          if (productLine1.id_target && productLine2.id_target) {
            return 0;
          }
          if (!productLine1.id_target) {
            return -1;
          }
          if (!productLine2.id_target) {
            return 1;
          }
          return 0;
        })
        .map(([id_product, productLine]) => {
          lines.push(productLine);
        });
    });
    return lines;
  };
  get_command_cart_products_stats = () => {
    const { command_cart_products, general_color } = this.state;

    let rec_total_fixed = new Decimal(0);
    let rec_sub_total_fixed = new Decimal(0);
    let rec_total_shipping = new Decimal(
      Number(this.state.delivery_price) || 0
    );
    const rec_total_package = new Decimal(this.calculateTotalPackagePrice());
    let rec_nr_cart_products = new Decimal(0);
    const TOTAL_TIPS = new Decimal(
      this.state.enable_tips ? this.calculateTip() : 0
    );

    let rec_btn_disabled = true;

    let rec_product_elements = "";
    if (Object.keys(command_cart_products).length > 0) {
      const itemsToMap = this.getIndividualCartLines();
      rec_product_elements = (
        <React.Fragment>
          {Object.entries(command_cart_products).map(
            ([id_bundle, bundle], index) => {
              const bundleEntries = Object.entries(bundle);
              const targetProduct = bundleEntries.find(
                ([id_product, productLine]) => !productLine.id_target
              );
              if (!targetProduct) {
                //fatal
                console.log("Unexpected bundle with no targe product");
                this.set_info_parsed_l_st_venue(
                  this.state.id_venue,
                  "command_cart_products",
                  null
                );
                // window.location.reload();
                return;
              }
              const {
                id_product,
                price,
                quantity,
                title,
                id_target,
                sizeItem,
              } = targetProduct[1];
              let sizeLabel = sizeItem?.assoc_name || "";

              const totalProductBundle = bundleEntries.reduce(
                (sum, [id, { price, quantity }]) => {
                  price = Number(price) || 0;
                  quantity = Number(quantity) || 0;

                  const aux = new Decimal(price)
                    .times(quantity)
                    .times(10000)
                    .dividedBy(10000);
                  return sum.plus(aux);
                },
                new Decimal(0)
              );

              let assocText = bundleEntries
                .filter(([id_product_, productLine_]) =>
                  Number(productLine_.id_target)
                )
                .reduce((text, [id_product_, productLine_], indexLine) => {
                  return `${text}${productLine_.title}${indexLine < bundleEntries.length - 2 ? ", " : ""
                    }`;
                }, "");

              if (sizeLabel) {
                assocText = `${sizeLabel}${assocText ? `, ${assocText}` : ""}`;
              }

              return (
                <AnimationItem
                  key={`command_cart_products__${id_bundle}_${id_product}`}
                  timeout={150}
                  classNames="command_receipt_item"
                  id={`command_cart_products__${id_bundle}_${id_product}`}
                  markedForDeleting={bundle.markedForDeleting}
                  unmountElement={() => {
                    this.deleteBundle(id_bundle);
                  }}
                >
                  <div
                    id={`command_cart_products__${id_bundle}_${id_product}`}
                    // key={targetProduct[1]}
                    // key={`command_cart_products__${id_bundle}_${id_product}`}
                    className={"command_receipt_item _user_select_none"}
                  >
                    <div className="command_left_side_receipt">
                      <div
                        className="command_receipt_item_name"
                        style={{ color: general_color }}
                      >
                        {title}
                      </div>
                      <div className="command_receipt_item_qty">
                        <div
                          className="arthm_op_btn"
                          a={id_target}
                          onClick={() => {
                            this.inc_command_cart_qty(id_bundle, -1, id_target);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131 16.99"
                            style={{ fill: general_color }}
                          >
                            <defs />
                            <g data-name="Layer 2">
                              <g data-name="Layer 1">
                                <polygon points="0 0 0 16.98 131 16.99 131 0.04 0 0" />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div
                          style={{ color: this.state.text_color }}
                          className="command_receipt_qty_num _user_select_none"
                        >
                          {bundle.markedForDeleting ? "1" : quantity}
                        </div>
                        <div
                          className="arthm_op_btn"
                          onClick={() => {
                            this.inc_command_cart_qty(id_bundle, 1, id_target);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131 131"
                            style={{ fill: general_color }}
                          >
                            <defs />
                            <g data-name="Layer 2">
                              <g data-name="Layer 1">
                                <polygon points="57 0 74.09 0 73.98 56.97 131 57 131 74.01 73.81 74.01 74 131 57 131 57 74 0 73.83 0 56.81 56.99 57 57 0" />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      className="command_receipt_item_price _user_select_none"
                      style={{ color: general_color }}
                    >
                      {Number(totalProductBundle)} lei
                    </div>
                    {assocText && (
                      <div
                        style={{ ...this.configure_theme_text_color() }}
                        className="bundle-assoc-item-sub-list"
                      >
                        {assocText}
                      </div>
                    )}
                  </div>
                </AnimationItem>
              );
            }
          )}
        </React.Fragment>
      );
      //##subtotal   + all items quantity
      this.getIndividualCartLines().forEach(({ price, quantity }) => {
        rec_sub_total_fixed = rec_sub_total_fixed.plus(
          new Decimal(price).times(new Decimal(quantity))
        );
        rec_nr_cart_products = rec_nr_cart_products.plus(new Decimal(quantity));
      });
    } else {
      rec_product_elements = (
        <div
          style={{ ...this.configure_theme_text_color() }}
          className="empty_message_item_receipt"
        >
          {"Coș gol."}
        </div>
      );
    }

    let show_null_delivery = false;

    /** make the shipping price 0 if delivery type if pickup or no delivery is chosen (this might never happen but is handled anyway) */
    if (
      this.state.shipping_form.delivery_type ===
      constants.delivery_ids_from_name.pickup ||
      !this.state.shipping_form.delivery_type
    ) {
      rec_total_shipping = new Decimal(0);
    }

    /** make the shipping price 0 if a minim order value has been reached */
    if (this.state.free_delivery_from <= Number(rec_sub_total_fixed)) {
      rec_total_shipping = new Decimal(0);
      show_null_delivery = true;
    }

    /** make shipping price 0 if no product has been added */
    if (Number(rec_sub_total_fixed) === 0) {
      rec_total_shipping = new Decimal(0);
    }

    let hide_delivery_price =
      !this.state.delivery_price_text &&
      (Number(rec_total_shipping) === 0 &&
        !show_null_delivery &&
        Number(TOTAL_TIPS) === 0);

    //TOTAL (subtotal + package + tips + shipping)
    rec_total_fixed = rec_sub_total_fixed
      .plus(rec_total_package)
      .plus(TOTAL_TIPS)
      .plus(rec_total_shipping);

    let totalPriceReferenceForMinOrder = 0;
    if (this.state.exclude_shipping_for_min_order) {
      totalPriceReferenceForMinOrder = rec_total_fixed.minus(
        rec_total_shipping
      );
    } else {
      totalPriceReferenceForMinOrder = rec_total_fixed;
    }
    let min_order_error;
    if ((this.state.min_order || 0) === 0) {
      rec_btn_disabled =
        Number(totalPriceReferenceForMinOrder) <= 0 ? true : false;
      min_order_error =
        Number(totalPriceReferenceForMinOrder) <= 0 ? true : false;
    } else {
      rec_btn_disabled =
        Number(totalPriceReferenceForMinOrder) < this.state.min_order
          ? true
          : false;
      min_order_error =
        Number(totalPriceReferenceForMinOrder) < this.state.min_order
          ? true
          : false;
    }
    if (!this.state.deliver) {
      min_order_error = false;
    }

    if (
      objectPath.get(this.state, "currentPromo.price_reduction_calculated") &&
      Number(rec_total_fixed)
    ) {
      const PROMO_PRICE_REDUCTION = new Decimal(
        objectPath.get(this.state, "currentPromo.price_reduction_calculated") ||
        0
      );
      // if(Number(PROMO_PRICE_REDUCTION) && hide_delivery_price && (Number(rec_total_shipping) > 0 || Number(this.state.delivery_price) > 0) ) {
      //   hide_delivery_price = false;
      // }
      rec_total_fixed = rec_total_fixed.minus(PROMO_PRICE_REDUCTION);
    }

    return {
      rec_total_fixed: String(Math.floor(Number(rec_total_fixed) * 100) / 100),
      rec_sub_total_fixed: String(
        Math.floor(Number(rec_sub_total_fixed) * 100) / 100
      ),
      rec_total_shipping: String(
        Math.floor(Number(rec_total_shipping) * 100) / 100
      ),
      rec_total_package: String(
        Math.floor(Number(rec_total_package) * 100) / 100
      ),
      rec_nr_cart_products: Number(rec_nr_cart_products),

      rec_product_elements,
      rec_btn_disabled,
      min_order_error,
      hide_delivery_price,
      totalPriceReferenceForMinOrder: Number(totalPriceReferenceForMinOrder),
    };
  };
  onChangeSizeItem = (option, productItemIndex) => {
    this.setState({
      product_items: this.state.product_items.map((item, index) => {
        if (productItemIndex === index) {
          const assocWithSize = Array.isArray(
            item.assocSizes[option.assocIndex].assoc_size
          )
            ? item.assocSizes[option.assocIndex].assoc_size
            : [];
          return {
            ...item,
            assocSizeSelectedIndex: option.assocIndex,
            assocWithSize,
            price: item.assocSizes[option.assocIndex].price,
            product_price: item.assocSizes[option.assocIndex].price,
            assocCategoryInitialState_json: this.computeAssocCategories(
              item,
              item.assocNoSize,
              assocWithSize
            ),
          };
        }
        return item;
      }),
    });
  };
  addNewProductsToCart = (products) => {
    const {
      command_cart_products,
      forbiden_interval,
      forbiden_interval_allow_btns,
    } = this.state;
    if (forbiden_interval && !forbiden_interval_allow_btns) {
      this.open_schedule_warning();
      return;
    }
    //create new bundle with the products
    const newIdBundle = Date.now();
    const bundleNew = {};
    products.forEach((product) => {
      bundleNew[product.id] = {
        ...product,
        quantity: 1,
        id_bundle: newIdBundle,
      };
    });

    //check if a bundle with the same items exists (with the same size)
    const bundleKeys = Object.keys(bundleNew);
    const bundleExistent = Object.entries(command_cart_products).find(
      ([id_bundle, bundle]) => {
        const cBundleKeys = Object.keys(bundle);
        if (cBundleKeys.length !== bundleKeys.length) {
          return false;
        }
        //check if the same product ids
        for (let i = 0; i < bundleKeys.length; i++) {
          if (cBundleKeys.indexOf(bundleKeys[i]) === -1) {
            return false;
          }
        }

        //check size
        const targetProduct = Object.entries(bundle).find(
          ([id_product, productLine]) => !productLine.id_target
        );
        const targetProductToPush = Object.entries(bundleNew).find(
          ([id_product, productLine]) => !productLine.id_target
        );
        if (targetProductToPush && targetProduct) {
          if (!targetProductToPush[1].id_size && !targetProduct[1].id_size) {
            return true;
          }
          return targetProductToPush[1].id_size === targetProduct[1].id_size;
        }
        return true;
      }
    );
    if (bundleExistent) {
      const [id_bundle, bundle] = bundleExistent;
      Object.entries(bundle).forEach(([id_product]) => {
        command_cart_products[id_bundle][id_product].quantity += 1;
      });
      this.setState({ command_cart_products: command_cart_products }, () => {
        this.setCartProductLocalStorage();
        this.onOrderTotalChange();
        //###price_change
      });
    } else {
      const callBackYes = ({ bundleNew, newIdBundle }) => {
        return new Promise((resolve, reject) => {
          const { command_cart_products } = this.state;
          command_cart_products[newIdBundle] = bundleNew;
          this.setState({ command_cart_products }, () => {
            this.setCartProductLocalStorage();
            this.onOrderTotalChange();
            //###price_change
            resolve();
          });
        });
      };

      const lines = Object.entries(bundleNew).map(
        ([id_product, productLine]) => productLine
      );
      const bundleProductRule = this.getMinDeliveryDateAndStrictDeliveryDate(
        lines
      );

      if (
        bundleProductRule.minDeliveryDateBasedOnCart ||
        bundleProductRule.strictDeliveryDateBasedOnCart
      ) {
        const minDeliveryDateOk =
          !bundleProductRule.minDeliveryDateBasedOnCart ||
          !this.state.minDeliveryDateBasedOnCart ||
          helperFunctions.compare_dates_string(
            bundleProductRule.minDeliveryDateBasedOnCart,
            this.state.minDeliveryDateBasedOnCart
          ) <= 0;

        const strictDateOk =
          !bundleProductRule.strictDeliveryDateBasedOnCart ||
          !this.state.strictDeliveryDateBasedOnCart ||
          bundleProductRule.strictDeliveryDateBasedOnCart ===
          this.state.strictDeliveryDateBasedOnCart;
        //open popup
        let content = "";
        if (bundleProductRule.strictDeliveryDateBasedOnCart) {
          content = `Acest produs va fi putea fi livrat doar mâine.`;
        } else {
          content = "Acest produs va fi livrat doar de mâine.";
        }
        if (
          this.state.strictDeliveryDateBasedOnCart &&
          !bundleProductRule.strictDeliveryDateBasedOnCart
        ) {
          bundleProductRule.strictDeliveryDateBasedOnCart = this.state.strictDeliveryDateBasedOnCart;
        }
        const finalCallback = () => {
          callBackYes({ bundleNew, newIdBundle })
            .then(() => {
              return this.setMinDateDeliveryRulesBasedOnCart();
            })
            .then(() => {
              return this.filterCommandCartProductsBasedOnRules();
            })
            .then(() => {
              return this.shippingFormInitialValidation();
            })
            .then(() => {
              this.onOrderTotalChange();
              //###price_change
            });
        };
        finalCallback();

        // this.openProceedPopup({
        //   content,
        //   callbackYes: finalCallback,
        //   callbackNo: () => {},
        //   showOnlyOneOption: true,
        // });
      } else {
        callBackYes({ bundleNew, newIdBundle });
      }
    }
  };
  scrollToBundleItem = (id_bundle) => {
    // const id = `command_cart_product_bundle_${id_bundle}`;
    // if(document.getElementById(id) && document.getElementById("receipt-bundle-items-container")) {
    //   const offsetTop = document.getElementById(id).offsetTop;
    //   const containerHeight = document.getElementById("receipt-bundle-items-container").clientHeight;
    //   const scrollTop = document.getElementById("receipt-bundle-items-container").scrollTop;
    //   if(offsetTop > scrollTop + containerHeight || offsetTop < scrollTop) {
    //     $("#receipt-bundle-items-container").animate({
    //       scrollTop: offsetTop,
    //     }, 400, () => {});
    //   }
    // }
  };
  show_cart_mobile_dialog = () => {
    if (document.getElementById("receipt_command__")) {
      if (
        !document
          .getElementById("receipt_command__")
          .classList.contains("show_cart_command_for_mobile")
      ) {
        document
          .getElementById("receipt_command__")
          .classList.add("show_cart_command_for_mobile");
        this.mobile_cart_is_open = true;

        document.getElementById("receipt_command__").scrollTop = 0;
      }
      document.getElementById("receipt_command__").style.height =
        (this.state.touchDeviceClientHeight || window.clientHeight) + "px";
    }
    // this.mobile_cart_is_open = true;
    this.set_scroll_lock(true);
  };
  hide_cart_mobile_dialog = () => {
    this.scroll_y_locked = false;
    if (document.getElementById("receipt_command__")) {
      document
        .getElementById("receipt_command__")
        .classList.remove("show_cart_command_for_mobile");
      document.getElementById("receipt_command__").style.height = "";
      this.mob_input_focus = false;
    }
    this.mobile_cart_is_open = false;
    this.set_scroll_lock(false);
  };
  set_scroll_lock = (locked, onMount) => {
    if (locked) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "relative";
      document.body.style.height = window.innerHeight + "px";
    } else {
      document.body.style.overflow = "";
      document.body.style.position = "";
      document.body.style.height = "";
      if (onMount) {
        this.scrollToHashSection();
      } else {
        window.scrollTo(0, this.scroll_Y_window);
      }
    }
    this.scroll_y_locked = locked;
  };
  on_open_generic_modal = (new_popup, callback) => {
    this.set_scroll_lock(true);
    let { modals } = this.state;
    modals.push(new_popup);
    modals = modals.sort((a, b) => {
      const priority1 = a.priority || 0;
      const priority2 = b.priority || 0;
      if (priority1 === priority2) {
        return 0;
      }
      return priority1 > priority2 ? -1 : 1;
    });
    this.setState(
      {
        modals: modals,
      },
      () => {
        if (callback) callback();
      }
    );
  };
  openProceedPopup = ({
    content,
    callbackYes,
    callbackNo,
    showOnlyOneOption,
  }) => {
    const modal_key = "proceed_modal_key";
    this.on_open_generic_modal({
      element: (
        <ProceedPopup
          general_color={this.state.general_color}
          text_color={this.state.text_color}
          frameProps={{
            on_close_generic_modal: () => {
              this.on_close_generic_modal(modal_key);
            },
            general_color: this.state.general_color,
            BgStyle: { ...this.configure_theme_bg_style() },
          }}
          product_separator_color={this.state.product_separator_color}
          white_theme={this.state.white_theme}
          showOnlyOneOption={showOnlyOneOption}
          callbackYes={() => {
            this.on_close_generic_modal(modal_key);
            callbackYes();
          }}
          callbackNo={() => {
            this.on_close_generic_modal(modal_key);
            callbackNo();
          }}
          content={content}
        />
      ),
      key: modal_key,
      priority: 3,
    });
  };

  on_close_generic_modal = (key, callback, onMount) => {
    let { modals } = this.state;
    modals = modals.filter((mod) => mod.key !== key);
    if (modals.length === 0) {
      this.set_scroll_lock(false, onMount);
    }

    this.setState(
      {
        modals: modals,
      },
      () => {
        if (callback) callback();
      }
    );
  };
  open_optional_pop_up = (id_venue) => {
    if (this.venues_optional_popup[id_venue]) {
      const ind_day = new Date().getDay() - 1;
      if (!objectPath.get(this.venues_optional_popup[id_venue], ind_day)) {
        return;
      }
      const message = this.venues_optional_popup[id_venue][ind_day].message;
      const modal_key = id_venue + "_optional_popoup";
      this.on_open_generic_modal({
        element: (
          <PopupFrame
            on_close_generic_modal={() => {
              this.on_close_generic_modal(modal_key, null, true);
            }}
            general_color={this.state.general_color}
            BgStyle={{ ...this.configure_theme_bg_style() }}
          >
            {
              <div
                style={{ color: this.state.general_color }}
                className="optional_mesasge_popup"
              >
                {message}
              </div>
            }
          </PopupFrame>
        ),
        key: modal_key,
      });
    }
  };
  open_warning_popup = (message) => {
    const modal_key = Date.now() + "_optional_popoup";
    this.on_open_generic_modal({
      element: (
        <PopupFrame
          on_close_generic_modal={() => {
            this.on_close_generic_modal(modal_key);
          }}
          general_color={this.state.general_color}
          BgStyle={{ ...this.configure_theme_bg_style() }}
        >
          {
            <React.Fragment>
              <div
                className="optional_mesasge_popup"
                style={{
                  color: this.state.general_color,
                  marginBottom: "30px",
                }}
              >
                {message}
              </div>
              <Button
                text={"Ok"}
                color={this.state.general_color}
                borderColor={this.state.general_color}
                onClick={() => {
                  this.on_close_generic_modal(modal_key);
                }}
              />
            </React.Fragment>
          }
        </PopupFrame>
      ),
      key: modal_key,
    });
  };
  open_warning_popup_checkbox = (message) => {
    const modal_key = Date.now() + "_optional_popoup";
    this.on_open_generic_modal({
      element: (
        <PopupFrame
          on_close_generic_modal={() => {
            this.on_close_generic_modal(modal_key);
          }}
          general_color={this.state.general_color}
          BgStyle={{ ...this.configure_theme_bg_style() }}
        >
          {
            <React.Fragment>
              <div
                className="optional_mesasge_popup"
                style={{
                  color: this.state.general_color,
                  marginBottom: "30px",
                }}
              >
                {message}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  alignItems: "center",
                  minWidth: "70%",
                  maxWidth: "90%",
                  justifyContent: "center",
                }}
              >
                <div style={{ padding: "0 10px" }}>
                  <Button
                    text={"Nu sunt de acord"}
                    color={this.state.general_color}
                    borderColor={this.state.general_color}
                    onClick={() => {
                      this.on_close_generic_modal(modal_key);
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                </div>
                <div style={{ padding: "0 10px" }}>
                  <Button
                    color={this.state.general_color}
                    borderColor={this.state.general_color}
                    text={"Sunt de acord"}
                    onClick={() => {
                      this.on_close_generic_modal(modal_key, () => {
                        this.onChangeShippingForm(
                          "required_terms_checkbox",
                          true
                        );
                      });
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          }
        </PopupFrame>
      ),
      key: modal_key,
    });
  };
  get_delivery_options_filtered = () => {
    return (
      constants.delivery_options.filter(({ id }) => {
        if (id === 2 && this.state.disable_pickup) {
          return false;
        }
        if (id === 3 && this.state.disable_non_contact) {
          return false;
        }
        if (id === 1 && this.state.disable_delivery_option) {
          return false;
        }
        return true;
      }) || []
    );
  };
  configure_theme_bg_style = () => {
    return {
      ...(this.state.background_color
        ? {
          backgroundColor: this.state.background_color,
          backgroundImage: "none",
        }
        : {
          backgroundColor: "transparent",
          backgroundRepeat: "repeat",
          backgroundImage: `url(${this.state.background_image ||
            defaultBgImage})`,
        }),
    };
  };
  configure_theme_text_color = () => {
    return {
      ...(this.state.text_color
        ? {
          color: this.state.text_color,
        }
        : {
          color: "white",
        }),
    };
  };
  date_picker_styling = () => {
    $(".react-datepicker-wrapper input").css(
      "cssText",
      "border-bottom: 6px solid " + this.state.general_color
    );

    $(".react-datepicker").css(
      "border-color: 6px solid " + this.state.general_color + "!important"
    );
  };
  on_close_receipt_dropdown = () => {
    const dropdwons = document.getElementsByClassName(
      "dropdown_target_class_name"
    );
    for (let i = 0; i < dropdwons.length; i++) {
      dropdwons[i].classList.remove("dropdown_target_class_name");
    }
    this.setState({
      receipt_dropdown: JSON.parse(constants.lazy_receipt_dropdown_init),
      refresh_opening_callback: null,
      refresh_opening_callback_target: null,
    });
  };
  make_opening_receipt_dropdown_func = () => {
    return ({
      target,
      items,
      onSelectCallback,
      additionalStyleContainer = {},
      additionalStyleInnerCont = {},
      containerClassName = "",
      innerContainerClassName = "",
      optionClassName = "",
      hover_color = "unset",
      type = "",
    }) => {
      if (!target) {
        this.on_close_receipt_dropdown();
        return;
      }

      let top = -1;
      try {
        if (type === "county") {
          const nodesForHeight = document.querySelectorAll(
            ".command_receipt_delivery_mg_el, .command_receipt_upper_side .command_receipt_items_container > div"
          );

          let _top = 0;
          for (let i = 0; i < nodesForHeight.length; i++) {
            _top += nodesForHeight[i].scrollHeight;
            if (nodesForHeight[i].getAttribute("role") === "county") {
              const borderBottom = nodesForHeight[i].querySelector(
                ".section_divider.abs_pos_divider"
              );
              if (borderBottom) {
                _top -= borderBottom.scrollHeight;
              }
              break;
            }
          }
          top = _top;
        }
      } catch (err) { }

      if (top < 0) {
        const container_offset =
          target.offsetTop -
          target.parentNode.scrollTop +
          target.clientHeight -
          5;
        let offset_prev_siblings = 0;
        const upperSide_node = target.parentNode.parentNode;
        const item_parent = target.parentNode;

        top = container_offset + offset_prev_siblings;
      }

      // for (let i = 0; i < upperSide_node.children.length; i++) {
      //   if (upperSide_node.children[i] === item_parent) {
      //     break;
      //   }
      //   offset_prev_siblings += upperSide_node.children[i].clientHeight;
      // }
      const position = {
        top,
        left: 0,
      };
      if (!target.classList.contains("dropdown_target_class_name")) {
        target.classList.add("dropdown_target_class_name");
      }
      this.setState({
        receipt_dropdown: {
          items,
          onSelectCallback,
          open: true,
          position,
          parent_el_classname: "dropdown_target_class_name",
          containerClassName:
            "command_receipt_sub_container_padding _box_sizing_border_box_ width_minus_scoll_bar_items" +
            (containerClassName ? " " + containerClassName : ""),
          innerContainerClassName: innerContainerClassName,
          additionalStyleContainer: {
            paddingBottom: 0,
            ...additionalStyleContainer,
          },
          optionClassName,
          additionalStyleInnerCont: {
            borderColor: this.state.general_color,
            ...additionalStyleInnerCont,
          },
          hover_color: hover_color,
          scroll_bar_color: this.state.product_separator_color,
        },
      });
    };
  };
  onClickDropdownReceipt = (
    event,
    items,
    onSelectCallback,
    additional = {},
    otherparams = {}
  ) => {
    const dropdwons = document.getElementsByClassName(
      "dropdown_target_class_name"
    );
    for (let i = 0; i < dropdwons.length; i++) {
      dropdwons[i].classList.remove("dropdown_target_class_name");
    }
    let my_target = event.target;
    if (!my_target.classList.contains("is_dropdown_class")) {
      let thisTag = my_target;
      let correct_target_found = false;
      while (thisTag.parentNode && thisTag.parentNode.tagName !== "BODY") {
        // uppercase in HTML, lower in XML
        if (thisTag.classList.contains("is_dropdown_class")) {
          correct_target_found = true;
          break;
        }
        thisTag = thisTag.parentNode;
      }
      if (correct_target_found) {
        my_target = thisTag;
      } else {
        return;
      }
    }
    const is_the_same =
      my_target === this.state.refresh_opening_callback_target;
    this.setState(
      {
        receipt_dropdown: JSON.parse(constants.lazy_receipt_dropdown_init),
        refresh_opening_callback: null,
        refresh_opening_callback_target: null,
      },
      () => {
        if (!is_the_same || otherparams.allow_reopen) {
          const refresh_opening_callback = this.make_opening_receipt_dropdown_func();
          refresh_opening_callback({
            target: my_target,
            items,
            onSelectCallback,
            ...additional,
          });
          if (otherparams.callback_after_node_insertion) {
            otherparams.callback_after_node_insertion(my_target);
          }
          this.setState({
            refresh_opening_callback,
            refresh_opening_callback_target: my_target,
          });
        }
      }
    );
  };
  calculateTip = () => {
    const tipValue = this.state.tipValue || {};
    if (tipValue.percentage_value) {
      return Math.ceil(
        Number((tipValue.percentage_value * this.get_sub_total_price()) / 100)
      );
    } else {
      return Number(tipValue.custom_value) || 0;
    }
  };

  get_sub_total_price = () => {
    let rec_sub_total_fixed = new Decimal(0);
    const lines = this.getIndividualCartLines();
    if (lines.length > 0) {
      lines.forEach((productLine) => {
        const { price, quantity } = productLine;
        rec_sub_total_fixed = rec_sub_total_fixed.plus(
          new Decimal(price).times(new Decimal(Number(quantity) || 1))
        );
      });
    }
    return Number(rec_sub_total_fixed);
  };
  //NUMBER
  calculateTotalPackagePrice = () => {
    let totalPackage = new Decimal(0);
    const lines = this.getIndividualCartLines();
    if (lines.length > 0) {
      lines.forEach((productLine) => {
        const { product_package, quantity } = productLine;
        totalPackage = totalPackage.plus(
          new Decimal(Number(product_package) || 0).times(
            new Decimal(Number(quantity) || 1)
          )
        );
      });
    }
    return Number(totalPackage);
  };
  scroll_to_top_receipt = (duration) => {
    const node = this.state.is_mobile
      ? this.scroll_container_receipt.current
      : this.command_receipt_items_container_ref.current;

    if (node) {
      $(node).animate(
        {
          scrollTop: 0,
        },
        duration !== 0 && !duration ? 300 : duration,
        () => { }
      );
    }
  };
  generate_classname_receipt = (no_delivery_row) => {
    let upper_side_receipt_classname = "";
    let items_container_classname = "";
    let container_receipt_classname = "";
    let downer_side_receipt_classname = "";

    if (this.state.is_mobile) {
      upper_side_receipt_classname = "upperside_receipt_mobile_";
      items_container_classname = "items_container_receipt_mobile_";
      container_receipt_classname = "container_receipt_mobile_";
      downer_side_receipt_classname = "downer_side_receipt_mobile_";
    } else {
      const { deliver, delivery_price_text } = this.state;
      if (!deliver) {
        container_receipt_classname = "";
        items_container_classname = "items_container_fr_step";
        if (no_delivery_row) {
          upper_side_receipt_classname =
            "upperside_receipt_fr_step_no_del_row ";
          downer_side_receipt_classname =
            "downer_side_receipt_fr_step_no_del_row";
        } else {
          if (!delivery_price_text) {
            upper_side_receipt_classname = "upperside_receipt_fr_step_with_del";
            downer_side_receipt_classname =
              "downer_side_receipt_fr_step_with_del";
          } else {
            upper_side_receipt_classname =
              "upperside_receipt_fr_step_with_del_as_text";
            downer_side_receipt_classname =
              "downer_side_receipt_fr_step_with_del_as_text";
          }
        }
      } else {
        items_container_classname = "items_container_sec_step";
        container_receipt_classname = "";
        if (no_delivery_row) {
          upper_side_receipt_classname =
            "upperside_receipt_sec_step_no_del_row";
          downer_side_receipt_classname =
            "downer_side_receipt_sec_step_no_del_row";
        } else {
          if (!delivery_price_text) {
            upper_side_receipt_classname =
              "upperside_receipt_sec_step_with_del";
            downer_side_receipt_classname =
              "downer_side_receipt_sec_step_with_del";
          } else {
            upper_side_receipt_classname =
              "upperside_receipt_sec_step_with_del_as_text";
            downer_side_receipt_classname =
              "downer_side_receipt_sec_step_with_del_as_text";
          }
        }
      }

      //replace upperside configuration for desktop only to fit with flex (code above needs to be redraw)
      upper_side_receipt_classname = "command_receipt_upper_side-desktop";
      items_container_classname = "";
      downer_side_receipt_classname = "downer_side_flex";
    }
    return {
      upper_side_receipt_classname,
      items_container_classname,
      container_receipt_classname,
      downer_side_receipt_classname,
    };
  };
  check_disable_delivery_hour = () => {
    return (
      this.state.disable_delivery_hour ||
      (this.hardcodeCondition() &&
        this.hardcodedStuffForAVenue("disable_delivery_hour", {
          shipping_form: this.state.shipping_form,
        }))
    );
  };

  selectTipPercentage = (tipValue) => {
    this.setState({ tipValue }, () => {
      this.set_info_parsed_l_st_venue(
        this.state.id_venue,
        "tips",
        this.state.tipValue
      );
      this.onOrderTotalChange();
      //###price_change
    });
  };
  // ##payment methods
  getAvailablePaymentTypes = () => {
    const {
      enable_card_payment,
      enable_cash_payment,
      disable_online_payment,
    } = this.state;

    return constants.paymentMethods.filter(
      (item) =>
        (item === "cash" && enable_cash_payment) ||
        (item === "card" && enable_card_payment) ||
        (item === "online" && disable_online_payment !== true)
    );
  };
  onSelectPaymentType = (payment_type) => {
    this.onChangeShippingForm("payment_type", payment_type);
  };

  calculateTotalOrder = () => {
    const SUBTOTAL = new Decimal(this.get_sub_total_price());
    const TOTAL_TIPS = new Decimal(this.calculateTip());
    const PACKAGE_PRICE = new Decimal(this.calculateTotalPackagePrice());
    const DELIVERY_PRICE = new Decimal(this.state.delivery_price || 0);
    return Number(
      SUBTOTAL.plus(TOTAL_TIPS)
        .plus(PACKAGE_PRICE)
        .plus(DELIVERY_PRICE)
    );
  };

  onChangePromo = (event) => {
    this.setState({
      promoValue: event.target.value,
    });
  };

  calculatePromoCodePriceReduction = (promo) => {
    if (promo.promo_percentage) {
      const ORDER_TOTAL = new Decimal(this.calculateTotalOrder());
      const PERCENTAGE = new Decimal(Number(promo.promo_percentage) || 0);
      return Number(ORDER_TOTAL.times(PERCENTAGE));
    }
    return Number(promo.promo_value) || 0;
  };

  checkExistingPromo = () => {
    if (!this.state.currentPromo) {
      return new Promise((resolve, reject) => resolve());
    }
    const order_total = Number(
      new Decimal(this.calculateTotalOrder())
      // .minus(
      //   new Decimal(this.state.delivery_price || 0)
      // )
    );
    const payload = {
      promo_code: this.state.currentPromo.promo_name,
      id_venue: this.state.id_venue,
      order_total,
      client_phone: this.state.shipping_form.phone,
      check_used_promo_code: 1,
    };
    return axios
      .post("delivery", payload)
      .then(({ data }) => {
        if (
          objectPath.get(data, "success") !== 1 &&
          objectPath.get(this.state.currentPromo, "id_promo")
        ) {
          this.setState(
            {
              currentPromo: null,
            },
            () => {
              const errorMessage =
                objectPath.get(data, "error") ||
                `Promoția asociată codului ${objectPath.get(
                  this.state.currentPromo,
                  "promo_name"
                )} a expirat.`;
              this.open_warning_popup(errorMessage);
              this.onOrderTotalChange(["promo"]);
              //###price_change
            }
          );
        } else {
          if (
            objectPath.get(this.state.currentPromo, "id_promo") &&
            payload.order_total === this.calculateTotalOrder()
          ) {
            this.setState(
              {
                currentPromo: {
                  ...this.state.currentPromo,
                  price_reduction_calculated: this.calculatePromoCodePriceReduction(
                    this.state.currentPromo
                  ),
                },
              },
              () => {
                this.onOrderTotalChange(["promo"]);
                //###price_change
              }
            );
          }
        }
        return new Promise((resolve, reject) => resolve());
      })
      .catch((err) => {
        return new Promise((resolve, reject) => resolve());
      });
  };
  submitPromoCode = (noLoader) => {
    if (!noLoader) {
      this.setState(
        {
          isFetchingPromo: true,
        },
        () => {
          this.submitPromoCode(true);
        }
      );
      return;
    }
    const order_total = Number(
      new Decimal(this.calculateTotalOrder())

      // .minus(
      //   new Decimal(this.state.delivery_price || 0)
      // )
    );
    axios
      .post("delivery", {
        promo_code: this.state.promoValue,
        id_venue: this.state.id_venue,
        order_total,
        client_phone: this.state.shipping_form.phone,
        check_promo_code: 1,
      })
      .then(({ data }) => {
        if (
          objectPath.get(data, "success") === 1 &&
          objectPath.get(data, "promo.id_promo")
        ) {
          this.setState(
            {
              currentPromo: {
                ...data.promo,
                price_reduction_calculated: this.calculatePromoCodePriceReduction(
                  data.promo
                ),
              },
              shipping_form: {
                ...this.state.shipping_form,
              },

              promoValue: "",
              isFetchingPromo: false,
            },
            () => {
              this.setFormDataLocalStorage();
              this.onOrderTotalChange(["promo"]);
              //###price_change
            }
          );
        } else {
          this.setState(
            {
              isFetchingPromo: false,
            },
            () => {
              const errorMessage =
                objectPath.get(data, "error") ||
                `Nicio promoție asociată codului „${this.state.promoValue}”`;
              this.open_warning_popup(errorMessage);
              this.onOrderTotalChange(["promo"]);
              //###price_change
            }
          );
        }
      })
      .catch((err) => {
        this.open_warning_popup(
          `Nicio promoție asociată codului „${this.state.promoValue}”`
        );
        this.setState({ isFetchingPromo: false });
      });
  };
  deletePromoCode = () => {
    this.setState(
      {
        currentPromo: null,
      },
      () => {
        this.onOrderTotalChange(["promo"]);
        //###price_change
      }
    );
  };

  /**
   * get fitting rule based on the total order of the cart (with no shipping!)
   * @returns {object} - get a rule to use for calculating shipping
   */
  getCurrentPriceDeliveryRule = () => {
    const totalMinusShipping = Number(
      new Decimal(this.calculateTotalOrder()).minus(
        new Decimal(this.state.delivery_price || 0)
      )
    );

    /** overwrite all rules if the delivery is pickup */
    if (
      this.state.no_min_order_for_pickup &&
      this.state.shipping_form.delivery_type === constants.DELIVERY_TYPES.pickup
    ) {
      return {
        priority: Number.MAX_SAFE_INTEGER,
        delivery_price: 0,
        min_order: 0,
      };
    }

    return (
      this.state.delivery_price_array_intervals.find((item, index) => {
        return (
          (item.distance_end >= totalMinusShipping &&
            item.distance_start <= totalMinusShipping) ||
          /** verify if this is the last item */
          (index === this.state.delivery_price_array_intervals.length - 1 &&
            item.distance_end === 0 &&
            item.distance_start <= totalMinusShipping)
        );
      }) || { priority: -1, delivery_price: this.state.delivery_price }
    );
  };

  /**
   * gets the next price rules that indicates how much the cart subtotal needs to be raised for the shipping to be free
   * @returns {object[]} - get a rule
   */
  getCurrentPriceDeliveryRulesOrdered = (currentDistanceRule) => {
    const totalMinusShipping = Number(
      new Decimal(this.calculateTotalOrder()).minus(
        new Decimal(this.state.delivery_price || 0)
      )
    );

    return this.state.delivery_price_array_intervals
      .filter((item) => {
        /** filter by:
         * 1. the price rules that have 0 delivery price
         * 2. price start (alias "distance_start") is bigger than the cart
         * 3. current overlapping distance rule won't overwrite the rule (has a lower priority)
         * */
        return (
          item.priority > currentDistanceRule.priority &&
          item.distance_start > totalMinusShipping &&
          !Number(item.delivery_price)
        );
      })
      .sort((item1, item2) => {
        if (item1.distance_start === item2.distance_start) {
          return 0;
        }
        return item1.distance_start > item2.distance_start ? 1 : -1;
      });
  };

  /**
   * gets the next price one RULE that indicates how much the cart subtotal needs to be raised for the shipping to be free
   * @returns {number} - amount
   */
  getNextPriceRuleZeroDelivery = (currentDistanceRule, FINAL_RULE) => {
    /** if setting enabled */
    if (!this.state.free_delivery_rule_indicator) {
      return {};
    }

    /** a rule with 0 for delivery price is already in place */
    if (FINAL_RULE && !Number(FINAL_RULE.delivery_price)) {
      return {};
    }

    const rulesOrdered = this.getCurrentPriceDeliveryRulesOrdered(
      currentDistanceRule
    );
    window.debugRuleFreeDeliveryNext = { rulesOrdered, currentDistanceRule };

    console.log(window.debugRuleFreeDeliveryNext, "rulesOrdered");

    if (rulesOrdered.length) {
      const totalMinusShipping = Number(
        new Decimal(this.calculateTotalOrder()).minus(
          new Decimal(this.state.delivery_price || 0)
        )
      );

      return {
        nextDeliveryZeroRule: rulesOrdered[0],
        priceRemainingForZeroDelivery:
          rulesOrdered[0].distance_start - totalMinusShipping,
      };
    }
    return {};
  };

  getCurrentPriceDistanceRule = async () => {
    const valueFallback = {
      priority: -100,
      delivery_price: this.state.delivery_price,
    };
    const { delivery_price_array } = this.state;
    if (delivery_price_array.length === 0) {
      return valueFallback;
    }
    return await axios
      .post("https://api.thefootballbrain.app/euplatesc/distance.php", {
        destination: this.state.shipping_form.address,
        id_venue: this.state.id_venue,
        county_name: this.state.venue_county_names?.[
          this.state.shipping_form.id_county
        ],
      })
      .then(async ({ data }) => {
        if (data >= 0) {
          this.setState({
            currentDistance: data,
          });

          for (let i = 0; i < delivery_price_array.length; i++) {
            const {
              id_delivery_price,
              id_venue,
              distance_start,
              distance_end,
              date_added,
            } = delivery_price_array[i];

            if (
              data >= distance_start &&
              (data <= distance_end || !distance_end)
            ) {
              return delivery_price_array[i];
            }
          }
        }

        return valueFallback;
      })
      .catch(async (err) => {
        console.log(err);
        return valueFallback;
      });
  };

  /**
   * calculate min_oder -> by which the shipping price and min_order
   *
   * based on one of this ways:
   * 1. delivery rules: distance or price
   * 2. based on special single preference - free_delivery_from
   * @returns
   */
  checkPriceIntervals = async () => {
    const free_delivery_from = Number(this.state.free_delivery_from);
    if (free_delivery_from) {
      const totalMinusShipping = Number(
        new Decimal(this.calculateTotalOrder()).minus(
          new Decimal(this.state.delivery_price || 0)
        )
      );
      if (totalMinusShipping >= free_delivery_from) {
        return new Promise((resolve) => {
          this.setState(
            {
              delivery_price: 0,
              FINAL_RULE: "free_from_price",
            },
            () => {
              resolve();
            }
          );
        });
      }
    }

    /**
     * 1. calculate the distance and price rules that fits with the state
     * 2. choose only one (the non-null one or based on priority)
     */
    const suitable_price_rule = this.getCurrentPriceDeliveryRule();
    const suitable_delivery_rule = await this.getCurrentPriceDistanceRule();

    const FINAL_RULE =
      suitable_price_rule.priority >= suitable_delivery_rule.priority
        ? suitable_price_rule
        : suitable_delivery_rule;

    const {
      nextDeliveryZeroRule,
      priceRemainingForZeroDelivery,
    } = this.getNextPriceRuleZeroDelivery(suitable_delivery_rule, FINAL_RULE);

    /**
     * calculate min_oder -> by which the shipping price and min_order
     */
    if (FINAL_RULE) {
      let min_order = this.state.min_order_initial;
      if (
        FINAL_RULE.min_order !== undefined &&
        FINAL_RULE.min_order !== null &&
        FINAL_RULE.min_order !== "" &&
        Number(FINAL_RULE.min_order) !== NaN
      ) {
        min_order = Number(FINAL_RULE.min_order);
      }

      return new Promise((resolve) => {
        this.setState(
          {
            delivery_price: Number(FINAL_RULE.delivery_price) || 0,
            FINAL_RULE,
            priceRemainingForZeroDelivery,
            nextDeliveryZeroRule: nextDeliveryZeroRule,
            min_order,
          },
          () => {
            resolve();
          }
        );
      });
    }
    return new Promise((resolve, reject) => resolve());
  };

  onOrderTotalChange = (skipList = []) => {
    return new Promise((resolve, reject) => resolve())
      .then(() => {
        if (skipList.indexOf("delivery_price_intervals") === -1) {
          return this.checkPriceIntervals();
        }
        return new Promise((resolve, reject) => resolve());
      })
      .then(() => {
        if (skipList.indexOf("promo") === -1) {
          return this.checkExistingPromo();
        }
        return new Promise((resolve, reject) => resolve());
      });
  };
  /**
   * check if the hour and date selected is right for the new selected county
   */
  shippingDataBasedOnCountyChange = () => {
    let { date, hour } = this.state.shipping_form;
    let { shipping_hour_items } = this.state;

    if (date) {
      const validHours = this.generate_valid_hours(date);
      console.log({ validHours });
      if (validHours.length === 0) {
        date = "";
        hour = "";
      } else {
        if (hour && validHours.indexOf(hour) === -1) {
          hour = "";
        }
      }
      shipping_hour_items =
        validHours.length === 0 ? constants.try_hours_delivery : validHours;
    }
    return {
      date,
      hour,
      shipping_hour_items,
    };
  };
  onChangeCounty = (id_county) => {
    this.setState(
      {
        shipping_form: {
          ...this.state.shipping_form,
          id_county,
        },
      },
      () => {
        const {
          date,
          hour,
          shipping_hour_items,
        } = this.shippingDataBasedOnCountyChange();
        this.setState(
          {
            shipping_hour_items,
            shipping_form: {
              ...this.state.shipping_form,
              date,
              hour,
            },
          },
          () => {
            this.setFormDataLocalStorage();
          }
        );
      }
    );
  };
  renderAddressFormDropdown = () => {
    return this.state.shipping_form.delivery_type ===
      constants.DELIVERY_TYPES.pickup ? (
      ""
    ) : (
      <div className="command-receipt-form-right is_dropdown_class">
        <div
          style={{ ...this.configure_theme_text_color() }}
          onClick={(event) => {
            const items = this.state.venue_counties.map((item, index) => ({
              Value: <div> {item.county_name} </div>,
              id_county: item.id_county,
            }));
            const onSelectCallback = (event, option) => {
              this.onChangeCounty(option.id_county);
            };
            this.onClickDropdownReceipt(
              event,
              items,
              onSelectCallback,
              {
                optionClassName: "receipt_hour_dr_option",
                hover_color: this.state.text_color,
                type: "county",
              },
              { allow_reopen: true }
            );
          }}
        >
          <div
            style={{
              backgroundColor: this.state.text_color,
            }}
            className={`section_divider abs_pos_divider${this.state.shipping_form.errors["id_county"]
              ? " error_red_bg_color"
              : ""
              }`}
          />
          {this.state.venue_county_names[this.state.shipping_form.id_county] ||
            "Județ*"}
        </div>
      </div>
    );
  };
  render() {
    const emptyLocationOptionName = this.state.always_show_location
      ? this.state.shipping_form.delivery_type === 2
        ? "Alege magazinul unde ridici comanda"
        : "Alege de unde vrei să comanzi"
      : "Alege magazinul unde ridici comanda";
    const tips = this.calculateTip();

    const dropdown_inner_cont_style = {
      borderColor: "#bfbc8e",
      backgroundColor: "#111111",
      backgroundImage: "none",
    };

    let last_screen_email = this.state.last_screen_email;
    let ind_arrr = 20;
    if (
      last_screen_email != "" &&
      last_screen_email !== undefined &&
      last_screen_email.length > 70 &&
      last_screen_email.indexOf("@") > -1
    ) {
      ind_arrr = last_screen_email.indexOf("@");
    }
    this.date_picker_styling();
    const theme_bg_style = this.configure_theme_bg_style();
    const theme_text_style = this.configure_theme_text_color();
    const {
      sel_command_cat_index,
      command_categories,
      product_items,
      forbiden_interval,
      forbiden_interval_allow_btns,
    } = this.state;
    const selected_command_category = command_categories[sel_command_cat_index]
      ? command_categories[sel_command_cat_index].id
      : 0;

    const renderDatePickerHeader = ({
      date,
      changeYear,
      changeMonth,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    }) => {
      return (
        <div className="dropdown_datepicker_custom_header">
          <div className="react-datepicker__header">
            <div
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <button
                style={{
                  top: 0,
                  left: 0,
                  visibility: prevMonthButtonDisabled ? "hidden" : "",
                }}
                onClick={decreaseMonth}
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
              >
                Prev month
              </button>
              <div className="react-datepicker__current-month">{`${constants.month_names[date.getMonth()]
                } ${date.getFullYear()}`}</div>
              <button
                style={{
                  top: 0,
                  right: 0,
                  visibility: nextMonthButtonDisabled ? "hidden" : "",
                }}
                onClick={increaseMonth}
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--next"
              >
                Next month
              </button>
            </div>
          </div>
        </div>
      );
    };

    //receipt's products
    const {
      rec_sub_total_fixed,
      rec_total_fixed,
      rec_total_shipping,
      rec_total_package,
      rec_product_elements,
      rec_nr_cart_products,
      rec_btn_disabled,
      min_order_error,
      hide_delivery_price,
    } = this.get_command_cart_products_stats();

    const receipt_back_btn =
      !this.state.is_mobile && !this.state.deliver ? null : (
        <div
          onClick={() => {
            if (this.state.is_mobile && !this.state.deliver) {
              this.hide_cart_mobile_dialog();
            } else {
              this.setState({ deliver: false }, () => { });
              this.scroll_to_top_receipt(0);
            }
          }}
          style={{ borderColor: this.state.general_color }}
          className="command_receipt_back_btn _box_sizing_border_box_ fill_anim"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.5 28.36">
            <defs />
            <polyline
              stroke={`${this.state.general_color}`}
              className="back_btn_svg_path_"
              points="16.54 26.22 4.28 14.22 16.54 2.14"
            />

            <line
              stroke={`${this.state.general_color}`}
              className="back_btn_svg_path_"
              x1="16.54"
              y1="14.18"
              x2="42.5"
              y2="14.22"
            />
          </svg>
        </div>
      );

    const { receipt_dropdown } = this.state;
    const delivery_selected =
      this.get_delivery_options_filtered().find(({ id }) => {
        return id === this.state.shipping_form.delivery_type;
      }) || {};

    const scrollBarClassname = css`
      &::-webkit-scrollbar-thumb {
        background-color: ${this.state.product_separator_color}!important;
      }
    `;
    const {
      upper_side_receipt_classname,
      items_container_classname,
      container_receipt_classname,
      downer_side_receipt_classname,
    } = this.generate_classname_receipt(hide_delivery_price);

    const disable_delivery_hour_recalculated = this.check_disable_delivery_hour();

    const availablePaymentTypes = this.getAvailablePaymentTypes();
    const payOrPlaceOrderText =
      this.state.shipping_form.payment_type === "online"
        ? "Plătește acum"
        : "Plasează comanda";
    let cartElementNode = "";
    if (!this.state.disable_order_section) {
      cartElementNode = (
        <div
          id="receipt_command__"
          className={
            "command_receipt_container" +
            (this.state.sticky_command_navbar
              ? " command_receipt_fixed"
              : this.state.command_section_is_higher
                ? " command_receipt_container_flex_col_end"
                : "") +
            (container_receipt_classname
              ? " " + container_receipt_classname
              : "")
            // (this.state.id_venue === "19" || 1
            //   ? " cuptor_moldovei_receipt_scroll"
            //   : "")
          }
          style={{ ...theme_bg_style }}
          ref={this.scroll_container_receipt}
        >
          <div
            className={`command_receipt_sub_container command_receipt_sub_container_padding${this.state.delivery_price_text
              ? " command_custom_text_shipping_pain" +
              (!this.state.deliver
                ? " command_custom_text_shipping_pain_garb"
                : "")
              : ""
              }`}
            style={{ borderColor: this.state.general_color }}
          >
            {this.state.receipt_dropdown.open && (
              <Dropdown
                parent_el_classname={receipt_dropdown.parent_el_classname || ""}
                position={receipt_dropdown.position}
                options={receipt_dropdown.items}
                containerClassName={receipt_dropdown.containerClassName}
                isOpen={true}
                additionalStyleContainer={{
                  ...(receipt_dropdown.additionalStyleContainer
                    ? receipt_dropdown.additionalStyleContainer
                    : {}),
                }}
                additionalStyleInnerCont={{
                  ...theme_bg_style,
                  borderColor: this.state.general_color,
                  color: this.state.general_color,
                  ...(receipt_dropdown.additionalStyleInnerCont
                    ? receipt_dropdown.additionalStyleInnerCont
                    : {}),
                }}
                innerContainerClassName={
                  receipt_dropdown.innerContainerClassName
                }
                value={
                  objectPath.get(
                    receipt_dropdown.items,
                    receipt_dropdown.index_selected + ".Value"
                  ) || 0
                }
                optionClassName={receipt_dropdown.optionClassName}
                onSelect={(event, option) => {
                  this.state.receipt_dropdown.onSelectCallback(event, option);
                  this.on_close_receipt_dropdown();
                }}
                toggle={(event) => {
                  this.on_close_receipt_dropdown();
                }}
                close={() => {
                  this.on_close_receipt_dropdown();
                }}
                hover_color={receipt_dropdown.hover_color}
                scroll_bar_color={receipt_dropdown.scroll_bar_color}
                disabled
                required
              />
            )}
            <div
              className={
                "command_receipt_upper_side" +
                (upper_side_receipt_classname
                  ? " " + upper_side_receipt_classname
                  : "")
                // (this.state.deliver === true
                //   ? " command_receipt_upper_side_delivery"
                //   : "") +
                // (this.state.id_venue === "19" || 1
                //   ? " cuptorul_moldovei"
                //   : "")
              }
            >
              {this.state.deliver === true ? (
                <div className="command_receipt_delivery_mg_el" />
              ) : (
                <div
                  className="command_receipt_title"
                  style={{ color: this.state.general_color }}
                >
                  <div className="command_receipt_tile_txt">
                    <span>Comanda ta</span>
                    {this.state.min_order_text ? (
                      <span
                        style={{ ...theme_text_style }}
                        className="command_receipt_title_warning min_order_text"
                      >
                        {this.state.min_order_text}
                      </span>
                    ) : (
                      <React.Fragment>
                        {this.state.min_order > 0 && (
                          <span
                            style={{ ...theme_text_style }}
                            className="command_receipt_title_warning"
                          >
                            *Valoarea minimă a comenzii este de{" "}
                            {this.state.min_order} lei
                          </span>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}
              {this.state.deliver === true ? (
                <React.Fragment>
                  <div
                    onScroll={() => {
                      this.on_close_receipt_dropdown();
                    }}
                    ref={this.command_receipt_items_container_ref}
                    className={cx(
                      "command_receipt_items_container command_receipt_items_delivery_container" +
                      (items_container_classname
                        ? " " + items_container_classname
                        : ""),
                      scrollBarClassname
                    )}
                  >
                    {constants.shipping_form_inputs.map(
                      ({ form_prop, placeholder, type }, index) => {
                        if (form_prop === "address") {
                          return (
                            <React.Fragment key={index}>
                              {!this.state.disable_delivery && (
                                <React.Fragment>
                                  {
                                    //  <ReceiptDropdown
                                    //  text_color={this.state.text_color}
                                    //  valueSelected={delivery_selected.text || "Alege metoda de livrare"}
                                    //   error={this.state.shipping_form.errors['delivery_type']}
                                    //   onOpenDropdown={(event) => {
                                    //     const items = this.get_delivery_options_filtered().map(
                                    //       ({ id, text }, index) => ({
                                    //         Value: <div> {text} </div>,
                                    //         key: `delivery_dropdown_${id}_${index}`,
                                    //         id,
                                    //       })
                                    //     );
                                    //     const onSelectCallback = (event, option) => {
                                    //       this.onChangeShippingForm("delivery_type", option.id);
                                    //     };
                                    //     this.onClickDropdownReceipt(event, items, onSelectCallback, {
                                    //       optionClassName: "receipt_hour_dr_option",
                                    //       hover_color: this.state.text_color,
                                    //     });
                                    //   }}
                                    //  />
                                  }
                                  <ReceiptCheckboxSelect
                                    svgColor={
                                      this.state.product_separator_color
                                    }
                                    items={this.get_delivery_options_filtered().map(
                                      ({ id, text }, index) => ({
                                        text,
                                        key: `delivery_dropdown_${id}_${index}`,
                                        id,
                                      })
                                    )}
                                    onSelect={(event, option) => {
                                      this.onChangeShippingForm(
                                        "delivery_type",
                                        option.id
                                      );
                                    }}
                                    general_color={this.state.general_color}
                                    theme_text_style={theme_text_style}
                                    selected={
                                      this.state.shipping_form.delivery_type
                                    }
                                  />

                                  {/* dropdown location */}
                                  {(this.state.shipping_form.delivery_type ===
                                    2 ||
                                    this.state.always_show_location) &&
                                    Object.keys(this.state.venue_locations)
                                      .length > 0 && (
                                      <div
                                        style={{ ...theme_text_style }}
                                        className={`is_dropdown_class _box_sizing_border_box_ command_receipt_delivery_item_cont c_r_dropdown_selected_item ${this.state.shipping_form.errors[
                                          "id_venue_location"
                                        ]
                                          ? " error_red_color"
                                          : ""
                                          }`}
                                        onClick={(event) => {
                                          const venue_loc_ids = Object.keys(
                                            this.state.venue_locations
                                          );
                                          if (venue_loc_ids.length > 1) {
                                            const items = [
                                              {
                                                Value: (
                                                  <div>
                                                    {emptyLocationOptionName}
                                                  </div>
                                                ),
                                                id_venue_location: null,
                                              },
                                            ].concat(
                                              venue_loc_ids.map(
                                                (id_venue_location, index) => ({
                                                  Value: (
                                                    <div>
                                                      {
                                                        this.state
                                                          .venue_locations[
                                                          id_venue_location
                                                        ].location_name
                                                      }
                                                    </div>
                                                  ),
                                                  ...this.state.venue_locations[
                                                  id_venue_location
                                                  ],
                                                })
                                              )
                                            );
                                            const onSelectCallback = (
                                              event,
                                              option
                                            ) => {
                                              this.onChangeShippingForm(
                                                "id_venue_location",
                                                option.id_venue_location,
                                                true
                                              );
                                            };
                                            this.onClickDropdownReceipt(
                                              event,
                                              items,
                                              onSelectCallback,
                                              {
                                                optionClassName:
                                                  "receipt_hour_dr_option",

                                                hover_color: this.state
                                                  .text_color,
                                                additionalStyleInnerCont: {
                                                  maxHeight: "1000px",
                                                },
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        <div>
                                          {objectPath.get(
                                            this.state.venue_locations,
                                            this.state.shipping_form
                                              .id_venue_location +
                                            ".location_name"
                                          ) || emptyLocationOptionName}
                                        </div>
                                        <div className="c_r_dropdown_selected_item_arrow">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 14.17 9.27"
                                            style={{
                                              fill: this.state.text_color,
                                            }}
                                            className={
                                              this.state.shipping_form.errors[
                                                "id_venue_location"
                                              ]
                                                ? " error_red_color_fill"
                                                : ""
                                            }
                                          >
                                            <defs />
                                            <g data-name="Layer 2">
                                              <g data-name="Layer 1">
                                                <polygon points="2.15 0 0 2.06 7.08 9.27 14.17 2.06 12.04 0 7.08 5.02 2.15 0" />
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                        <div
                                          style={{
                                            backgroundColor: this.state
                                              .text_color,
                                          }}
                                          className={`section_divider abs_pos_divider${this.state.shipping_form.errors[
                                            "id_venue_location"
                                          ]
                                            ? " error_red_bg_color"
                                            : ""
                                            }`}
                                        />
                                      </div>
                                    )}

                                  {!this.state.delivery_asap_only && (
                                    <div
                                      style={{ ...theme_text_style }}
                                      className="command_receipt_delivery_item_cont asap_delivery_option_cont"
                                    >
                                      <Checkbox
                                        key={"checkbox_anticipation"}
                                        onClick={(event) => {
                                          this.onChangeShippingForm(
                                            "delivery_asap",
                                            true
                                          );
                                        }}
                                        disabled={
                                          this.state.forbiden_interval ||
                                          this.state.disable_asap ||
                                          this.state.disable_asap_from_state ||
                                          this.state.disable_asap_from_blocked
                                        }
                                        containerClassName={
                                          "asap_checkbox_cont"
                                        }
                                        labelClassName={"asap_checkbox_label"}
                                        squareClassName={"asap_square_checkbox"}
                                        label={"În cel mai scurt timp"}
                                        label_style={{ ...theme_text_style }}
                                        borderColor={this.state.general_color}
                                        checked={
                                          this.state.shipping_form.delivery_asap
                                        }
                                        svgColor={this.state.inner_text_color}
                                      />
                                      <Checkbox
                                        key={"checkbox_anticipation_2"}
                                        onClick={(event) => {
                                          this.onChangeShippingForm(
                                            "delivery_asap",
                                            false
                                          );
                                        }}
                                        containerClassName={
                                          "asap_checkbox_cont"
                                        }
                                        labelClassName={"asap_checkbox_label"}
                                        squareClassName={"asap_square_checkbox"}
                                        label={"La o dată viitoare"}
                                        label_style={{ ...theme_text_style }}
                                        borderColor={this.state.general_color}
                                        checked={
                                          !this.state.shipping_form
                                            .delivery_asap
                                        }
                                        svgColor={this.state.inner_text_color}
                                      />
                                    </div>
                                  )}

                                  {(() => {
                                    if (
                                      this.state.shipping_form.delivery_type ===
                                      constants.DELIVERY_TYPES.pickup
                                    ) {
                                      return "";
                                    }
                                    return (
                                      <div
                                        key={
                                          "input_form_venue_" +
                                          this.state.id_venue +
                                          "_" +
                                          index
                                        }
                                        style={{ ...theme_text_style }}
                                        role="county"
                                        className={`command_receipt_delivery_item_cont command-reciept-input-with-dropdown`}
                                      >
                                        <div className="command-receipt-form-line-left">
                                          <input
                                            style={{ ...theme_text_style }}
                                            className="command_receipt_input"
                                            onFocus={
                                              this.on_focus_input_mobile_receipt
                                            }
                                            onBlur={(event) => {
                                              this.on_blur_input_mobile_receipt(
                                                event
                                              );
                                              this.checkPriceIntervals();
                                            }}
                                            type={type}
                                            placeholder={placeholder}
                                            value={
                                              this.state.shipping_form[
                                              form_prop
                                              ]
                                            }
                                            onWheel={(event) => {
                                              if (type === "number") {
                                                $(event.target).blur();
                                              }
                                            }}
                                            onChange={(event) => {
                                              this.onChangeShippingForm(
                                                form_prop,
                                                event.target.value
                                              );
                                            }}
                                            onKeyDown={
                                              this.focus_next_input_on_enter
                                            }
                                          />

                                          {this.state.shipping_form.errors[
                                            form_prop
                                          ] && (
                                              <div
                                                style={{ ...theme_bg_style }}
                                                className="receipt_right_err_icon_form"
                                              >
                                                <svg
                                                  style={{
                                                    enableBackground:
                                                      "new 0 0 512 512",
                                                  }}
                                                  viewBox="0 0 512 512"
                                                  xmlSpace="preserve"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <defs />
                                                  <g>
                                                    <g>
                                                      <path
                                                        className="error_red_color_fill"
                                                        d="M476.7,422.2L270.1,72.7c-2.9-5-8.3-8.7-14.1-8.7c-5.9,0-11.3,3.7-14.1,8.7L35.3,422.2c-2.8,5-4.8,13-1.9,17.9   c2.9,4.9,8.2,7.9,14,7.9h417.1c5.8,0,11.1-3,14-7.9C481.5,435.2,479.5,427.1,476.7,422.2z M288,400h-64v-48h64V400z M288,320h-64   V176h64V320z"
                                                      />
                                                    </g>
                                                  </g>
                                                </svg>
                                              </div>
                                            )}

                                          <div
                                            className={
                                              this.state.shipping_form.errors[
                                                form_prop
                                              ]
                                                ? "error_red_bg_color"
                                                : ""
                                            }
                                            style={{
                                              backgroundColor: this.state
                                                .product_separator_color,
                                            }}
                                          // className={`section_divider abs_pos_divider${
                                          //   this.state.shipping_form.errors[
                                          //     form_prop
                                          //   ]
                                          //     ? " error_red_bg_color"
                                          //     : ""
                                          // }`}
                                          />
                                        </div>
                                        {this.renderAddressFormDropdown()}
                                      </div>
                                    );
                                  })()}

                                  {/*shipping hour and datepicker
                                   */}
                                  {!this.state.shipping_form.delivery_asap &&
                                    !this.state.delivery_asap_only && (
                                      <div
                                        style={{ ...theme_text_style }}
                                        className="command_receipt_delivery_item_cont r_c_time_selector_cont is_dropdown_class"
                                      >
                                        <div
                                          onClick={(event) => {
                                            const items = [
                                              {
                                                Value: (
                                                  <div
                                                    className={css`
                                                      ${this.state
                                                        .reservationDatepickerCss}
                                                    `}
                                                  >
                                                    <DatePicker
                                                      key={
                                                        "date_picker_" +
                                                        this.state.shipping_form
                                                          .delivery_type
                                                      }
                                                      style={{
                                                        borderBottom: `6px solid ${this.state
                                                          .general_color
                                                          }`,
                                                        ...theme_text_style,
                                                      }}
                                                      renderCustomHeader={
                                                        renderDatePickerHeader
                                                      }
                                                      calendarClassName={
                                                        "dropdown_datepicker_calendar"
                                                      }
                                                      popperClassName={
                                                        "dropdown_datepicker_popper"
                                                      }
                                                      open={true}
                                                      selected={
                                                        this.state.shipping_form
                                                          .date
                                                          ? new Date(
                                                            this.state.shipping_form.date
                                                          )
                                                          : new Date()
                                                      }
                                                      onSelect={(date) => {
                                                        if (
                                                          this.test_date_has_open_program(
                                                            helperFunctions.date_to_string(
                                                              date
                                                            )
                                                          )
                                                        ) {
                                                          this.onChangeShippingForm(
                                                            "date",
                                                            helperFunctions.date_to_string(
                                                              date
                                                            )
                                                          );
                                                        }
                                                      }}
                                                      value={
                                                        this.state.shipping_form
                                                          .date
                                                      }
                                                      onChange={(date) => {
                                                        this.onChangeShippingForm(
                                                          "date",
                                                          helperFunctions.date_to_string(
                                                            date
                                                          )
                                                        );
                                                      }}
                                                      minDate={
                                                        this.state
                                                          .currentMinDeliveryDate
                                                          ? new Date(
                                                            this.state.currentMinDeliveryDate
                                                          )
                                                          : new Date()
                                                      }
                                                      dateFormat="dd MMMM, YYYY"
                                                      locale="ro"
                                                      filterDate={(date) =>
                                                        this.test_date_has_open_program(
                                                          helperFunctions.date_to_string(
                                                            date
                                                          )
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                ),
                                                is_datepicker: true,
                                              },
                                            ];
                                            const onSelectCallback = (
                                              event,
                                              option
                                            ) => { };
                                            this.onClickDropdownReceipt(
                                              event,
                                              items,
                                              onSelectCallback,
                                              {
                                                additionalStyleInnerCont: {
                                                  border: 0,
                                                  backgroundImage: "none",
                                                  backgroundColor:
                                                    "transparent",
                                                },
                                                innerContainerClassName:
                                                  "datepicker_dropdown_inner_container",
                                                additionalStyleContainer: {
                                                  cursor: "auto",
                                                },
                                              },
                                              {
                                                allow_reopen: true,
                                                callback_after_node_insertion: (
                                                  target
                                                ) => {
                                                  // setTimeout(
                                                  //   ()=>{
                                                  //   //   if(document.getElementsByClassName("dropdown_datepicker_calendar") &&
                                                  //   // document.getElementsByClassName("dropdown_datepicker_calendar")[0]
                                                  //   // ){
                                                  //   //     document.getElementsByClassName("dropdown_datepicker_calendar")[0].style.border =
                                                  //   //     `4px solid ${this.state.general_color}`
                                                  //   //     ;
                                                  //   // }}, 100
                                                  // )
                                                },
                                              }
                                            );
                                          }}
                                          style={{
                                            ...theme_text_style,
                                            ...(disable_delivery_hour_recalculated
                                              ? { width: "100%" }
                                              : {}),
                                          }}
                                        >
                                          {this.state.shipping_form.date ? (
                                            <React.Fragment>
                                              {" "}
                                              {this.get_text_date(
                                                this.state.shipping_form.date
                                              )}{" "}
                                            </React.Fragment>
                                          ) : (
                                            "Data*"
                                          )}
                                          <div
                                            style={{
                                              backgroundColor: this.state
                                                .text_color,
                                            }}
                                            className={`section_divider abs_pos_divider${this.state.shipping_form.errors[
                                              "date"
                                            ]
                                              ? " error_red_bg_color"
                                              : ""
                                              }`}
                                          />
                                        </div>
                                        {!disable_delivery_hour_recalculated && (
                                          <div
                                            style={{ ...theme_text_style }}
                                            onClick={(event) => {
                                              const items = (
                                                this.state
                                                  .shipping_hour_items ||
                                                constants.try_hours_delivery
                                              ).map((hour, index) => ({
                                                Value: (
                                                  <div>
                                                    {" "}
                                                    {hour.slice(0, 5)}{" "}
                                                  </div>
                                                ),
                                                hour,
                                              }));
                                              const onSelectCallback = (
                                                event,
                                                option
                                              ) => {
                                                this.onChangeShippingForm(
                                                  "hour",
                                                  option.hour
                                                );
                                              };
                                              this.onClickDropdownReceipt(
                                                event,
                                                items,
                                                onSelectCallback,
                                                {
                                                  optionClassName:
                                                    "receipt_hour_dr_option",
                                                  hover_color: this.state
                                                    .text_color,
                                                },
                                                { allow_reopen: true }
                                              );
                                            }}
                                          >
                                            <div
                                              style={{
                                                backgroundColor: this.state
                                                  .text_color,
                                              }}
                                              className={`section_divider abs_pos_divider${this.state.shipping_form.errors[
                                                "hour"
                                              ]
                                                ? " error_red_bg_color"
                                                : ""
                                                }`}
                                            />
                                            {this.state.shipping_form.hour
                                              ? this.state.shipping_form.hour.slice(
                                                0,
                                                5
                                              )
                                              : "Ora*"}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        }

                        return (
                          <div
                            key={
                              "input_form_venue_" +
                              this.state.id_venue +
                              "_" +
                              index
                            }
                            style={{ ...theme_text_style }}
                            className={`command_receipt_delivery_item_cont${form_prop === "address"
                              ? " command-reciept-input-with-dropdown"
                              : ""
                              }`}
                          >
                            <input
                              style={{ ...theme_text_style }}
                              className="command_receipt_input"
                              onFocus={this.on_focus_input_mobile_receipt}
                              onBlur={(event) => {
                                this.on_blur_input_mobile_receipt(event);
                              }}
                              type={type}
                              placeholder={placeholder}
                              value={this.state.shipping_form[form_prop]}
                              onWheel={(event) => {
                                if (type === "number") {
                                  $(event.target).blur();
                                }
                              }}
                              onChange={(event) => {
                                this.onChangeShippingForm(
                                  form_prop,
                                  event.target.value
                                );
                              }}
                              onKeyDown={this.focus_next_input_on_enter}
                            />

                            {this.state.shipping_form.errors[form_prop] && (
                              <div
                                style={{ ...theme_bg_style }}
                                className="receipt_right_err_icon_form"
                              >
                                <svg
                                  style={{
                                    enableBackground: "new 0 0 512 512",
                                  }}
                                  viewBox="0 0 512 512"
                                  xmlSpace="preserve"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <defs />
                                  <g>
                                    <g>
                                      <path
                                        className="error_red_color_fill"
                                        d="M476.7,422.2L270.1,72.7c-2.9-5-8.3-8.7-14.1-8.7c-5.9,0-11.3,3.7-14.1,8.7L35.3,422.2c-2.8,5-4.8,13-1.9,17.9   c2.9,4.9,8.2,7.9,14,7.9h417.1c5.8,0,11.1-3,14-7.9C481.5,435.2,479.5,427.1,476.7,422.2z M288,400h-64v-48h64V400z M288,320h-64   V176h64V320z"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                            )}

                            <div
                              className={
                                this.state.shipping_form.errors[form_prop]
                                  ? "error_red_bg_color"
                                  : ""
                              }
                              style={{
                                backgroundColor: this.state
                                  .product_separator_color,
                              }}
                            // className={`section_divider abs_pos_divider${
                            //   this.state.shipping_form.errors[form_prop]
                            //     ? " error_red_bg_color"
                            //     : ""
                            // }`}
                            />
                          </div>
                        );
                      }
                    )}
                    <div
                      style={{ ...theme_text_style }}
                      className="command_receipt_delivery_item_cont flex_all_"
                    >
                      <div> * câmpuri obligatorii </div>
                    </div>
                  </div>
                  {availablePaymentTypes.length > 0 &&
                    !(
                      availablePaymentTypes.length === 1 &&
                      availablePaymentTypes[0] === "online"
                    ) && (
                      <PaymentTypeForm
                        general_color={this.state.general_color}
                        product_separator_color={
                          this.state.product_separator_color
                        }
                        inner_text_color={this.state.inner_text_color}
                        theme_text_style={theme_text_style}
                        paymentTypes={availablePaymentTypes}
                        id_delivery_type={
                          this.state.shipping_form.delivery_type
                        }
                        onSelectPaymentType={this.onSelectPaymentType}
                        selected={this.state.shipping_form.payment_type}
                      />
                    )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    id="receipt-bundle-items-container"
                    className={cx(
                      "command_receipt_items_container" +
                      (items_container_classname
                        ? " " + items_container_classname
                        : ""),
                      scrollBarClassname
                    )}
                  >
                    <TransitionGroup
                      className="item-list"
                      transitionEnter={true}
                      transitionleave={String(true)}
                    >
                      {rec_product_elements}
                    </TransitionGroup>
                  </div>
                  {this.state.enable_tips && this.state.tipValue && (
                    <Tips
                      tips_main_text={this.state.tips_main_text}
                      tips_other_text={this.state.tips_other_text}
                      tip_percentages={this.state.tip_percentages}
                      general_color={this.state.general_color}
                      product_separator_color={
                        this.state.product_separator_color
                      }
                      text_color={this.state.text_color}
                      inner_text_color={this.state.inner_text_color}
                      theme_text_style={theme_text_style}
                      selectTipPercentage={this.selectTipPercentage}
                      selected={{ ...this.state.tipValue }}
                      is_mobile={this.state.is_mobile}
                    />
                  )}
                </React.Fragment>
              )}
            </div>

            <div
              className={`command_receipt_downer_side${downer_side_receipt_classname
                ? " " + downer_side_receipt_classname
                : ""
                } flex_col_sp_between`}
            >
              <div
                className="section_divider mg_top_"
                style={{
                  backgroundColor: this.state.general_color,
                  marginBottom: "5px",
                }}
              />
              <div
                style={{ color: this.state.general_color }}
                className="command_receipt_total_line"
              >
                <div>Subtotal</div>
                <div>{rec_sub_total_fixed} lei</div>
              </div>

              {rec_total_package > 0 && (
                <div
                  style={{ color: this.state.general_color }}
                  className={`command_receipt_total_line`}
                >
                  <div>Ambalaj</div>
                  <div>{rec_total_package} lei</div>
                </div>
              )}
              <div
                style={{
                  color: this.state.general_color,
                  ...(hide_delivery_price ? { display: "none" } : {}),
                }}
                className={`command_receipt_total_line _r_shipping_price${this.state.delivery_price_text
                  ? " custom_delivery_text__"
                  : ""
                  }`}
              >
                {this.state.delivery_price_text ? (
                  <React.Fragment>
                    {" "}
                    <div>Livrare</div>
                    <div> {this.state.delivery_price_text} </div>{" "}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div>Livrare</div>
                    <div>{rec_total_shipping} lei</div>
                  </React.Fragment>
                )}
              </div>

              {/* SHOW REMAINING PRICE FOR THE DELIVERY TO BE FREE */}
              {!hide_delivery_price &&
                Boolean(this.state.nextDeliveryZeroRule) && (
                  <div
                    style={{
                      color: this.state.general_color,
                    }}
                    className="cart-total-line-sub-info"
                  >
                    <div>{`Adaugă produse de ${this.state.priceRemainingForZeroDelivery
                      } ${this.state.priceRemainingForZeroDelivery === 1
                        ? "leu"
                        : "lei"
                      } și ai livrare gratuită.`}</div>
                  </div>
                )}

              {/* TIPS */}
              <div
                style={{
                  color: this.state.general_color,
                  ...(!this.state.enable_tips || !this.state.tipValue
                    ? { display: "none" }
                    : {}),
                }}
                className={`command_receipt_total_line`}
              >
                <div>Tips</div>
                <div> {tips} lei</div>
              </div>
              {this.state.enable_promocode && (
                <PromoCode
                  deliveryStep={this.state.deliver ? 2 : 1}
                  general_color={this.state.general_color}
                  product_separator_color={this.state.product_separator_color}
                  text_color={this.state.text_color}
                  inner_text_color={this.state.inner_text_color}
                  theme_text_style={theme_text_style}
                  is_mobile={this.state.is_mobile}
                  promoValue={this.state.promoValue}
                  validCode={objectPath.get(
                    this.state,
                    "currentPromo.promo_name"
                  )}
                  priceReduction={
                    !Number(rec_total_fixed)
                      ? 0
                      : objectPath.get(
                        this.state,
                        "currentPromo.price_reduction_calculated"
                      )
                  }
                  onChangePromo={this.onChangePromo}
                  submitPromoCode={this.submitPromoCode}
                  deletePromoCode={this.deletePromoCode}
                  isFetching={this.state.isFetchingPromo}
                />
              )}
              {!this.state.deliver && (
                <div
                  className="section_divider"
                  style={{
                    backgroundColor: this.state.general_color,
                    marginTop: "5px",
                  }}
                />
              )}

              <div
                style={{
                  ...theme_text_style,
                  ...(this.state.deliver
                    ? { paddingTop: 0, paddingBottom: "10px" }
                    : { paddingTop: 10, paddingBottom: "10px" }),
                }}
                className="command_receipt_total_line _r_total_price"
              >
                <div>Total</div>
                <div>{rec_total_fixed} lei</div>
              </div>
              {this.state.deliver && (
                <div
                  className="section_divider"
                  style={{ backgroundColor: this.state.general_color }}
                />
              )}
              {this.state.deliver === true ? (
                <div
                  style={{ ...theme_text_style }}
                  className="receipt_agreement"
                >
                  {[
                    ...(this.state.use_cart_required_terms_checkbox
                      ? [
                        {
                          shipping_form_prop: "required_terms_checkbox",
                          label:
                            this.state.cart_required_terms_text ||
                            "Sunt de acord să primesc mesaje promoționale din partea " +
                            this.state.name_venue,
                          key:
                            "receive_emails__receipt_required" +
                            this.state.id_venue,
                          className: "receipt_agreement_text",
                        },
                      ]
                      : []),
                    ...(this.state.hide_cart_optional_checkbox
                      ? []
                      : [
                        {
                          shipping_form_prop: "receive_emails",
                          label:
                            "Opțional: Vreau să primesc mesaje promoționale din partea " +
                            this.state.name_venue,
                          key:
                            "receive_emails__receipt" + this.state.id_venue,
                        },
                      ]),
                  ].map(({ shipping_form_prop, label, key }) => (
                    <Checkbox
                      key={key}
                      onClick={(event) => {
                        this.onChangeShippingForm(
                          shipping_form_prop,
                          !this.state.shipping_form[shipping_form_prop]
                        );
                      }}
                      color={
                        !this.state.shipping_form[shipping_form_prop] &&
                          this.state.shipping_form.errors[shipping_form_prop]
                          ? "red"
                          : this.state.general_color
                      }
                      borderColor={
                        !this.state.shipping_form[shipping_form_prop] &&
                          this.state.shipping_form.errors[shipping_form_prop]
                          ? "red"
                          : this.state.general_color
                      }
                      boxBackgroundColor={this.state.general_color}
                      labelClassName="agr_label_cls"
                      squareClassName="agr_square_cls"
                      containerClassName="agr_check_cont_cls"
                      label={label}
                      label_style={{ ...theme_text_style }}
                      checked={this.state.shipping_form[shipping_form_prop]}
                      svgColor={this.state.inner_text_color}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}
              <div
                style={this.state.delivery_price_text ? { marginTop: 0 } : {}}
                className={"command_receipt_total_line _r_total_conf_btn"}
              >
                {receipt_back_btn ? (
                  <React.Fragment> {receipt_back_btn} </React.Fragment>
                ) : (
                  ""
                )}
                <Button
                  id={
                    this.state.deliver === true
                      ? "sumbmit-order-btn"
                      : "go-to-formular-receipt-btn"
                  }
                  key={
                    "_receipt_command_lower_btn_" +
                    (this.state.deliver === true
                      ? "platest_acum"
                      : "confirm_comanda")
                  }
                  backgroundColor={this.state.general_color}
                  color={this.state.inner_text_color}
                  containerClassName={
                    receipt_back_btn
                      ? "command_receipt_item_button_sliced"
                      : "command_receipt_item_button"
                  }
                  disabledClassName={
                    (!min_order_error && rec_btn_disabled) ||
                      (this.state.deliver === true && !this.state.venue_active)
                      ? "command_receipt_item_button_disabled_"
                      : null
                  }
                  borderColor={this.state.product_separator_color}
                  disabled={
                    (!min_order_error && rec_btn_disabled) ||
                    (this.state.deliver === true && !this.state.venue_active)
                  }
                  onClick={(event) => {
                    if (this.state.deliver) {
                      this.scroll_to_top_receipt();
                      this.submitShippingFormular();
                    } else {
                      if (!rec_btn_disabled) {
                        this.setState({ deliver: true }, () => {
                          this.scroll_to_top_receipt(0);
                        });
                      }
                    }
                  }}
                  text={
                    this.state.deliver === true
                      ? payOrPlaceOrderText
                      : "Confirmă comanda"
                  }
                />
              </div>
              {this.state.deliver &&
                !this.state.use_cart_required_terms_checkbox && (
                  <div
                    style={{ ...theme_text_style }}
                    className="receipt_agreement_text"
                  >
                    Apăsând butonul “{payOrPlaceOrderText}”, ești de acord cu{" "}
                    <a
                      className="link_no_decoration"
                      href={this.state.termsAndConditonLink}
                      target="_blank"
                    >
                      Termenii și condițiile
                    </a>{" "}
                    și{" "}
                    <a
                      className="link_no_decoration"
                      href={this.state.confTermsLink}
                      target="_blank"
                    >
                      Politica de confidențialitate
                    </a>
                    .
                  </div>
                )}
              {this.state.isIosMobile && <div className="emptySpace" />}
            </div>
          </div>
        </div>
      );
    }
    const sectionsToRender = {
      first_screen_section: (
        <div
          className="section"
          id="section1"
          data-anchor=""
          style={{
            ...(this.state.first_screen_background_image
              ? {
                backgroundImage:
                  "url(" + this.state.first_screen_background_image + ")",
              }
              : { backgroundImage: "url(" + defaultBgImage + ")" }),
          }}
        >
          <div className="info_location">
            <a href="/">
              <img
                src={this.state.first_screen_logo}
                alt={this.state.name_venue}
                className={cx(
                  "logo_restaurant_cm",
                  this.state.first_screen_logo_className
                )}
              />
            </a>
            <div className="name_of_loc">
              <p>Timeless</p>
            </div>
            <div
              className="linie_meniu"
              style={{ borderTop: `6px solid ${this.state.general_color}` }}
            />
            <div className="meniu_principal">
              {this.state.sectionsOrdered.map((name, _thisIndex) => {
                switch (name) {
                  case "command_section": {
                    return this.state.disable_gallery_section ? (
                      ""
                    ) : (
                      <a
                        key={`${_thisIndex}-link-section--`}
                        onClick={this.goToPage}
                        href="#section_command"
                        item-name="meniu"
                      >
                        Comandă
                      </a>
                    );
                  }
                  case "reservation_section": {
                    return this.state.disable_reservations ? (
                      ""
                    ) : (
                      <a
                        key={`${_thisIndex}-link-section--`}
                        onClick={this.goToPage}
                        href="#section3"
                        item-name="locatie"
                      >
                        Rezervări
                      </a>
                    );
                  }
                  case "gallery_section": {
                    return this.state.disable_gallery_section ? (
                      ""
                    ) : (
                      <a
                        key={`${_thisIndex}-link-section--`}
                        onClick={this.goToPage}
                        href="#section2"
                        item-name="meniu"
                      >
                        {`${this.state.menu_item_text
                          ? this.state.menu_item_text
                          : "Meniu"
                          }`}
                      </a>
                    );
                  }
                  case "contact_section": {
                    return (
                      <a
                        key={`${_thisIndex}-link-section--`}
                        onClick={this.goToPage}
                        href="#section4"
                        item-name="locatie"
                      >
                        Locație
                      </a>
                    );
                  }
                  // case "footer_section": {
                  //   return ();
                  // }
                  default: {
                    return "";
                  }
                }
              })}
            </div>

            <div className="first-screen-last-section-cont">
              <div
                className={
                  this.state.first_screen_description_style_classname
                    ? cx(
                      "description",
                      this.state.first_screen_description_style_classname
                    )
                    : "description"
                }
              >
                <h1>{this.state.first_screen_title}</h1>
              </div>
              <div className="rezerva">
                <a
                  style={{ border: `6px solid ${this.state.general_color}` }}
                  onClick={this.goToPage}
                  href={
                    this.state.first_screen_middle_button_action ||
                    "#section_command"
                  }
                >
                  {`${this.state.header_call_to_action
                    ? this.state.header_call_to_action
                    : "Comandă acum mâncare"
                    }`}
                </a>
              </div>
            </div>
          </div>
        </div>
      ),
      command_section: this.state.disable_order_section ? (
        ""
      ) : (
        <div
          className="section"
          ref={this._command_section_ref}
          id="section_command"
          style={{ ...theme_bg_style }}
          data-anchor="comanda"
        >
          <div
            className="content_section_command _user_select_none"
            style={{ border: `none` }}
          >
            <div className="command_section_content_left">
              <div
                className={
                  "command_category_navbar" +
                  (this.state.sticky_command_navbar
                    ? " command_category_navbar_sticky"
                    : "")
                }
                style={{
                  borderColor: this.state.general_color,
                  visibility:
                    this.state.product_items.length === 0 ? "hidden" : "",
                  ...theme_bg_style,
                }}
                ref={this._nav_bar_ref}
              >
                <div
                  className="comamnd_nav_bar_arrow _user_select_none"
                  onClick={() => {
                    this.iterateCategoryProduct(-1);
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <defs />
                    <polyline
                      stroke={`${this.state.general_color}`}
                      className="arrow_svg__"
                      points="112.33 196.41 17.68 103.41 110.69 8.76"
                    />
                  </svg>
                </div>
                <div className="navBarOverflowContainer">
                  {command_categories.map((category, index) => {
                    const { name, id } = category;
                    return (
                      <div
                        key={"nav_bar_category_menu_" + index + "_" + id}
                        className={
                          "command_nav_bar_item user_select_none" +
                          (id === selected_command_category
                            ? " selected_nav_bar_item"
                            : "")
                        }
                        style={{
                          ...(id === selected_command_category
                            ? { borderColor: this.state.general_color }
                            : {}),
                          ...this.configure_theme_text_color(),
                        }}
                        onClick={() => {
                          this.onChangeNavBarCommand(index);
                        }}
                      >
                        <div>{name}</div>
                      </div>
                    );
                  })}
                </div>

                <div
                  onClick={() => {
                    this.iterateCategoryProduct(1);
                  }}
                  className="comamnd_nav_bar_arrow arrow_right _user_select_none"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <defs />
                    <polyline
                      stroke={`${this.state.general_color}`}
                      className="arrow_svg__"
                      points="112.33 196.41 17.68 103.41 110.69 8.76"
                    />
                  </svg>
                </div>
              </div>
              {// preserve the original to prevent clumsy height change when navbar is fixed
                this.state.sticky_command_navbar && (
                  <div
                    className="command_category_navbar"
                    style={{ visibility: "hidden" }}
                  />
                )}
              <div
                className={cx(
                  "command_items_container",
                  css`
                    ${this.state.productItemCss}
                  `
                )}
              >
                {this.state.product_items.length < 1 ? (
                  <React.Fragment>
                    <div className="command_menu_list_item_column">
                      <div className="command_menu_list_item_container">
                        <div
                          className="command_menu_list_item_title"
                          style={{ color: `${this.state.general_color}` }}
                        >
                          {!this.state.product_items.length &&
                            "Niciun produs disponibil."}
                        </div>
                      </div>
                    </div>
                    <div className="command_menu_list_item_column" />
                  </React.Fragment>
                ) : (
                  this.state.product_items.map((product, index) => {
                    return (
                      <ProductItem
                        key={`product-item-${product.id}-${product.category_id
                          }-${index}-${product.assocSizeSelectedIndex}`}
                        id_venue={this.state.id_venue}
                        product={product}
                        index={index}
                        general_color={this.state.general_color}
                        inner_text_color={this.state.inner_text_color}
                        disabled_color={this.state.disabled_color}
                        command_cart_products={this.state.command_cart_products}
                        forbiden_interval={forbiden_interval}
                        forbiden_interval_allow_btns={
                          forbiden_interval_allow_btns
                        }
                        product_separator_color={
                          this.state.product_separator_color
                        }
                        sizesSelectCss={this.state.sizesSelectCss || ""}
                        default_product_image={this.state.default_product_image}
                        command_categories={command_categories}
                        theme_text_style={theme_text_style}
                        logo_venue={this.state.first_screen_logo}
                        products_have_image={
                          this.state.products_have_image ||
                          (this.state.some_products_have_image &&
                            product.product_image)
                        }
                        addNewProductsToCart={this.addNewProductsToCart}
                        onChangeSizeItem={(option) =>
                          this.onChangeSizeItem(option, index)
                        }
                        theme_bg_style={theme_bg_style}
                        customCssStyle={this.state.productItemCss}
                        set_scroll_lock={this.set_scroll_lock}
                      />
                    );
                  })
                )}
              </div>
            </div>
            {
              // !this.state.is_mobile ?
              <React.Fragment> {cartElementNode} </React.Fragment>
              // : <PopupItemContainer>
              //     {cartElementNode}
              // </PopupItemContainer>
            }
          </div>
          <div
            onClick={() => {
              if (window.innerWidth <= 1024) {
                this.show_cart_mobile_dialog();
              }
            }}
            style={
              forbiden_interval && !forbiden_interval_allow_btns
                ? { display: "none" }
                : {}
            }
            className={
              "command_mobile_cart_opener" +
              (this.state.sticky_command_navbar
                ? " command_mobile_cart_opener_fixed"
                : this.state.command_section_is_higher
                  ? " command_mobile_cart_opener_placed_down"
                  : "")
            }
          >
            <div
              style={{ color: this.state.inner_text_color }}
              className="command_mobile_cart_opener_sub_number"
            >
              <div>{rec_nr_cart_products}</div>
            </div>
            <div className="command_mobile_cart_opener_sub_container">
              <div className="command_purse_icon_container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 75 63"
                >
                  <defs />
                  <g>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          strokeWidth="3"
                          stroke={
                            this.state.white_theme
                              ? this.state.inner_text_color
                              : "#262626"
                          }
                          d="M32.5,7.5"
                        />

                        <polyline
                          strokeWidth="3"
                          stroke={
                            this.state.white_theme
                              ? this.state.inner_text_color
                              : "#262626"
                          }
                          points="31.5 8.5 26.5 20.5 9.5 20.5 20.5 50.5 50.5 50.5 61.5 20.5 45.5 20.5 44.5 20.5 39.5 8.5"
                        />
                        <polyline
                          stroke={
                            this.state.white_theme
                              ? this.state.inner_text_color
                              : "#262626"
                          }
                          strokeWidth="3"
                          points="23.5 25.5 26.29 20.5 44.83 20.5 47.5 25.5"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ fill: this.state.general_color }}
              viewBox="0 0 60 60"
              className="cyrcle_sv_command_opener"
            >
              <defs />
              <g>
                <g>
                  <circle cx="30" cy="30" r="30" />
                </g>
              </g>
            </svg>
          </div>
        </div>
      ),
      reservation_section: this.state.disable_reservations ? (
        ""
      ) : (
        <React.Fragment>
          <div
            className="section"
            style={{ ...theme_bg_style }}
            id="section3"
            data-anchor="rezervare"
          >
            <div
              className="content_section3"
              style={{ border: `6px solid ${this.state.general_color}` }}
            >
              <div
                className={cx(
                  "ecran_formular_initial",
                  css`
                    ${this.state.reservationFormularCss}
                  `
                )}
              >
                <div className="formular_rezervare">
                  {!this.state.trimitere_ok && (
                    <div className="content_formular">
                      <div className="rezerva_masa">
                        <h2 style={{ color: this.state.general_color }}>
                          Rezervă masă
                        </h2>
                      </div>
                      <div className="campuri">
                        <div className="nume_client">
                          <label
                            subset-item="true"
                            htmlFor="client_nume"
                            style={{ color: this.state.general_color }}
                          >
                            Numele meu este
                          </label>
                          <input
                            style={{
                              borderBottom: `6px solid ${this.state.general_color
                                }`,
                              ...theme_text_style,
                            }}
                            type="text"
                            name="client_nume"
                            id="client_nume"
                            className={this.state.err_nume}
                            onChange={this.handleChange}
                            value={this.state.client_nume}
                            placeholder="Numele tău"
                          />
                          <div last-item="true" />
                        </div>
                        <div className="rezervare_persoane">
                          <label
                            subset-item="true"
                            style={{ color: this.state.general_color }}
                          >
                            și vreau să fac o rezervare pentru
                          </label>
                          <SimpleDropdown
                            showError={this.state.showErrors}
                            options={(() => {
                              const maxPeople =
                                Number(this.state.reservation_max_people) || 30;
                              const selectArr = [];
                              for (
                                let i =
                                  Number(this.state.reservation_min_people) ||
                                  1;
                                i <= maxPeople;
                                i++
                              ) {
                                selectArr.push(i);
                              }
                              return selectArr;
                            })().map((number) => ({
                              value: number,
                              label:
                                Number(number) === 1
                                  ? "1 persoană"
                                  : `${number} persoane`,
                              key: `${number}-persoane-select-option-${number}`,
                            }))}
                            customCssStyle={this.state.hourSelectCss}
                            selectOption={(option) =>
                              this.handleChangeReservationPerson(option.value)
                            }
                            selected={
                              this.state.client_persoane
                                ? {
                                  value: this.state.client_persoane,
                                  label:
                                    Number(this.state.client_persoane) === 1
                                      ? "1 persoană"
                                      : `${this.state.client_persoane
                                      } persoane`,
                                }
                                : undefined
                            }
                          />
                          <div last-item="true" />
                        </div>
                        <div
                          className={cx(
                            "data rezervare_persoane",
                            css`
                              ${this.state.cartDatepickerCss}
                            `
                          )}
                        >
                          <p
                            subset-item="true"
                            style={{ color: this.state.general_color }}
                          >
                            pe data de{" "}
                          </p>
                          <span
                            style={{ position: "relative" }}
                            className="datepicker-reservation-container"
                          >
                            <DatePicker
                              style={{
                                borderBottom: `6px solid ${this.state.general_color
                                  }`,
                                ...theme_text_style,
                              }}
                              calendarClassName={
                                "datepicker_reservations_calendar"
                              }
                              popperClassName={"datepicker_reservations_popper"}
                              selected={
                                this.state.date_string_reservation
                                  ? new Date(this.state.date_string_reservation)
                                  : new Date()
                              }
                              onChange={this.handleChangeDateReservation}
                              minDate={new Date()}
                              dateFormat="dd MMMM, YYYY"
                              excludeDates={this.state.date_blocate_array}
                              locale="ro"
                              filterDate={(date) =>
                                this.generateValidHoursRerservation(
                                  helperFunctions.date_to_string(date)
                                ).length > 0
                              }
                            />
                            <div last-item="true" />
                          </span>
                          <div
                            className={`ora push-down-dropdown${this.state.use_reservation_stay_form
                              ? " use_reservation_stay_form"
                              : ""
                              }`}
                            style={{ display: "flex" }}
                          >
                            <p
                              subset-item="true"
                              style={{ color: this.state.general_color }}
                            >
                              {this.state.use_reservation_stay_form && <>de</>}{" "}
                              la ora
                            </p>
                            <SimpleDropdown
                              showError={this.state.showErrors}
                              options={this.state.valid_hours_reservation.map(
                                (value, index) => ({
                                  value,
                                  label: value.slice(0, 5),

                                  key: `${value}-hour-select-option-${index}`,
                                })
                              )}
                              customCssStyle={this.state.hourSelectCss}
                              selectOption={(option) =>
                                this.handleChangeHours(option.value)
                              }
                              selected={
                                this.state.hour_reservation
                                  ? {
                                    value: this.state.hour_reservation,
                                    label: this.state.hour_reservation.slice(
                                      0,
                                      5
                                    ),
                                  }
                                  : undefined
                              }
                            />
                            <div last-item="true" />
                            {this.state.use_reservation_stay_form && (
                              <div
                                className="ora-stay push-down-dropdown"
                                style={{ display: "flex" }}
                              >
                                <p
                                  subset-item="true"
                                  style={{ color: this.state.general_color }}
                                >
                                  până la ora
                                </p>
                                <SimpleDropdown
                                  showError={this.state.showErrors}
                                  options={this.state.valid_hours_reservation_stay.map(
                                    (value, index) => ({
                                      value,
                                      label: value.slice(0, 5),
                                      key: `${value}-hour-select-end-option-${index}`,
                                    })
                                  )}
                                  customCssStyle={this.state.hourSelectCss}
                                  selectOption={(option) =>
                                    this.handleChangeHoursReservationStay(
                                      option.value
                                    )
                                  }
                                  selected={
                                    this.state.hour_reservation_stay
                                      ? {
                                        value: this.state
                                          .hour_reservation_stay,
                                        label: this.state.hour_reservation_stay.slice(
                                          0,
                                          5
                                        ),
                                      }
                                      : undefined
                                  }
                                />
                                <div
                                  last-item="true"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="confirm">
                          <p style={{ color: this.state.general_color }}>
                            Pentru confirmare, vreau să fiu contactat(ă)
                          </p>
                        </div>

                        <div className="phone_and_mail">
                          <label
                            subset-item="true"
                            style={{ color: this.state.general_color }}
                          >
                            la telefon
                          </label>
                          <input
                            last-item="true"
                            style={{
                              borderBottom: `6px solid ${this.state.general_color
                                }`,
                              ...theme_text_style,
                            }}
                            type="text"
                            id="client_telefon"
                            onChange={this.handleChange}
                            value={this.state.client_telefon}
                            name="client_telefon"
                            className={this.state.err_telefon}
                            placeholder="nr. tău de telefon"
                          />
                          <label
                            subset-item="true"
                            style={{ color: this.state.general_color }}
                          >
                            și e-mail
                          </label>
                          <input
                            last-item="true"
                            style={{
                              borderBottom: `6px solid ${this.state.general_color
                                }`,
                              ...theme_text_style,
                            }}
                            type="text"
                            className={this.state.err_email}
                            id="client_email"
                            onChange={this.handleChange}
                            value={this.state.client_email}
                            name="client_email"
                            placeholder="adresa ta de e-mail"
                          />
                        </div>

                        <div className="message">
                          <textarea
                            style={{
                              borderBottom: `6px solid ${this.state.general_color
                                }`,
                              ...theme_text_style,
                            }}
                            onChange={this.handleChange}
                            value={this.state.client_mesaj}
                            name="client_mesaj"
                            rows="1"
                            cols="44"
                            className="message_area"
                            placeholder="Mesajul tău"
                          />
                        </div>
                        <div
                          className="eroare_formular"
                          style={
                            this.state.showErrors == true
                              ? { visibility: "visible" }
                              : { visibility: "hidden" }
                          }
                        >
                          <p className="error_text">
                            Câmpurile marcate sunt obligatorii!
                          </p>
                        </div>

                        {this.state.eroare == true ? (
                          <div className="eroare_formular">
                            <p className="error_text">
                              A apărut o eroare! Încercați din nou.
                            </p>
                          </div>
                        ) : (
                          ""
                        )}

                        <div className="check_agree">
                          {[
                            {
                              label: `Opțional: Vreau să primesc mesaje promoționale
                      din partea ${this.state
                                  .reservation_promotion_message_from ||
                                this.state.second_screen_title}`,
                              state_prop: "check_promotii_email",
                            },
                            //   {
                            //     label: `Opțional: Vreau să primesc mesaje promoționale
                            // din partea Poloniq`,
                            //     state_prop: "check_promotii_email_poloniq",
                            //   },
                          ].map(({ label, state_prop }, index) => (
                            <Checkbox
                              key={"label__rezerv_" + index}
                              onClick={(event) => {
                                this.setState({
                                  [state_prop]: !this.state[state_prop],
                                });
                              }}
                              svgColor={this.state.inner_text_color}
                              color={this.state.general_color}
                              labelClassName="rezerv_check_label check_text"
                              squareClassName="rezerv_check_square"
                              containerClassName="rezerv_check_cont"
                              label={label}
                              label_style={{ ...theme_text_style }}
                              borderColor={this.state.general_color}
                              checked={this.state[state_prop]}
                            />
                          ))}
                        </div>
                        <div className="send">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LdAdVgUAAAAAImZNef69-G6fiKvI3Q-vnFagAwK"
                          />

                          <Button
                            color={this.state.general_color}
                            borderColor={this.state.general_color}
                            text="Trimite rezervarea"
                            disabled={this.state.isFetchingSendReservation}
                            onClick={this.trimiteRezervarea}
                            className="button_vezi_meniu"
                          />
                        </div>
                        <div
                          className="disclaimer"
                          style={{ color: this.state.general_color }}
                        >
                          Apăsând butonul 'Trimite rezervarea', ești de acord cu{" "}
                          <a
                            href={this.state.termsAndConditonLink}
                            style={{ color: this.state.general_color }}
                            target="_blank"
                          >
                            termenii și condițiile
                          </a>{" "}
                          și{" "}
                          <a
                            href={this.state.confTermsLink}
                            style={{ color: this.state.general_color }}
                            target="_blank"
                          >
                            politica de confidențialitate
                          </a>{" "}
                          Poloniq.
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.trimitere_ok == true && (
                    <div className="eroare_formular">
                      <p
                        id="text_formular_ok"
                        style={{ color: this.state.general_color }}
                      >
                        <span className="semibold_text_formular_ok">
                          Cererea ta de rezervare a fost trimisă.
                        </span>{" "}
                        <br />
                        <br /> Vom reveni cu un răspuns{" "}
                        <span className="semibold_text_formular_ok">
                          pe email
                        </span>{" "}
                        <br /> în cel mai scurt timp!
                      </p>
                      <div className="flex-all padding-top-spacing">
                        <Button
                          color={this.state.general_color}
                          borderColor={this.state.general_color}
                          text="Fă o nouă rezervare"
                          onClick={this.resetReservationForm}
                          className="button_vezi_meniu"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="imagine_rezervare">
                  <img
                    src={this.state.third_screen_right_image}
                    alt="rezervare"
                  />
                </div>
              </div>
              <div className="ecran_succes">
                <div className="content_succes">
                  <h3>Masa ta este rezervată.</h3>
                  <p>Te așteptăm în restaurantul nostru!</p>
                  <div className="new_rez">
                    <a href=" #rezervare" className="rezervare_noua">
                      Trimite o rezervare nouă
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ position: "relative", width: "100%" }}>
              <div className="powerd_by" style={{ ...theme_bg_style }}>
                {/* <img src={this.state.powerByPhoto} /> */}
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      gallery_section: this.state.disable_gallery_section ? (
        ""
      ) : (
        <div
          className="section"
          // style={{ ...theme_bg_style }}
          style={
            this.state.menu_section_background_color
              ? {
                backgroundColor: this.state.menu_section_background_color,
                backgroundImage: "none",
              }
              : this.state.white_theme
                ? {
                  backgroundColor: this.state.product_separator_color,
                  backgroundImage: "none",
                }
                : {}
          }
          id="section2"
          data-anchor="meniu"
        >
          <div className="slider_section2">
            <Slider
              {...{
                ...this.state.settings,
                dots: false,
                dotsClass: "dotsClass_hidden",
              }}
            >
              {this.state.sliderImages.map((src, index) => (
                <div key={"slider-image-" + index}>
                  <img src={src} />
                </div>
              ))}
            </Slider>
          </div>
          <div className="descriere_section2">
            <div className="content_section2">
              <div className="rest_name">
                <div className="titlu_restaurant">
                  <h1 style={{ color: this.state.general_color }}>
                    {this.state.second_screen_title}
                  </h1>
                </div>
                <div className="ghilimele">
                  <img src={q} />
                </div>
                <div className="citat_text">
                  <p style={{ color: this.state.general_color }}>
                    {this.state.second_screen_subtitle
                      ? parse(this.state.second_screen_subtitle)
                      : ""}
                  </p>
                </div>
                <div className="ghilimele ghilimele_jos">
                  <img src={q} />
                </div>
                {!this.state.disable_menu_link && (
                  <div
                    style={{ ...theme_text_style }}
                    className="button_vezi_meniu desk"
                  >
                    <a
                      target="_blank"
                      href={this.state.second_screen_menu_link}
                      style={{
                        border: `6px solid ${this.state.general_color}`,
                        ...theme_text_style,
                      }}
                    >
                      {this.state.download_menu_text || "Descoperă meniul"}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ),
      contact_section: (
        <div
          className="section"
          id="section4"
          style={{ ...theme_bg_style }}
          data-anchor="locatie"
        >
          <div className="cont_s4">
            <div className="contact_info">
              <div className="info_1">
                <div className="text_contact program_text pin_img">
                  <h3 style={{ color: this.state.general_color }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      xmlSpace="preserve"
                      width="100%"
                      height="100%"
                      version="1.0"
                      viewBox="0 0 34596 47817"
                      style={{ fill: "none", strokeWidth: "3900" }}
                      className="svg_program__ contact_info_svg"
                      data-src="/static/media/pin.97364383.svg"
                    >
                      <defs />
                      <g>
                        <g>
                          <path
                            d="M17298 2117c8384,0 15181,6797 15181,15181 0,8383 -15181,27153 -15181,27153 0,0 -15181,-18770 -15181,-27153 0,-8384 6797,-15181 15181,-15181z"
                            style={{ stroke: this.state.general_color }}
                          />
                          <path
                            d="M17298 11762c3057,0 5536,2479 5536,5536 0,3057 -2479,5535 -5536,5535 -3057,0 -5536,-2478 -5536,-5535 0,-3057 2479,-5536 5536,-5536z"
                            style={{ stroke: this.state.general_color }}
                          />
                        </g>
                      </g>
                    </svg>
                    <div> Contact</div>
                  </h3>

                  <p>
                    <span style={{ ...theme_text_style }}>A: </span>
                    <label style={{ color: this.state.general_color }}>
                      {this.state.last_screen_address}
                    </label>
                  </p>
                  <a
                    className="phone_contact"
                    href={"tel:" + this.state.last_screen_phone}
                  >
                    <span style={{ ...theme_text_style }}>T: </span>
                    <label style={{ color: this.state.general_color }}>
                      {this.state.last_screen_phone}
                    </label>
                  </a>
                  <a
                    className="email_contact"
                    href={"mailto:" + (this.state.last_screen_email || "")}
                  >
                    <span style={{ ...theme_text_style }}>E: </span>
                    <label style={{ color: this.state.general_color }}>
                      {this.state.id_venue === "18" ? (
                        <React.Fragment>
                          {(this.state.last_screen_email || "").slice(
                            0,
                            ind_arrr
                          )}
                          <br />
                          {(this.state.last_screen_email || "").slice(
                            ind_arrr,
                            (this.state.last_screen_email || "").length
                          )}
                          {/* {this.state.last_screen_email} */}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {this.state.last_screen_email}
                        </React.Fragment>
                      )}
                    </label>
                  </a>
                </div>
                <div className="social_section">
                  <a target="_blank" href={this.state.fb_page_url}>
                    <svg
                      className="svg_img_social_media"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <path
                          d="m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h236.574219v-198h-66.5v-77.5h66.5v-57.035156c0-66.140625 40.378906-102.140625 99.378906-102.140625 28.257813 0 52.542969 2.105469 59.621094 3.046875v69.128906h-40.683594c-32.101562 0-38.316406 15.253906-38.316406 37.640625v49.359375h76.75l-10 77.5h-66.75v198h121.574219c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm0 0"
                          fill={this.state.general_color}
                        />
                      </g>{" "}
                    </svg>
                  </a>
                  {this.state.ig_page_url != null ? (
                    <a target="_blank" href={this.state.ig_page_url}>
                      <svg
                        className="svg_img_social_media"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <path
                            d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"
                            fill={this.state.general_color}
                          />
                          <path
                            d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"
                            fill={this.state.general_color}
                          />
                          <path
                            d="m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h438.148438c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm-72.960938 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"
                            fill={this.state.general_color}
                          />
                        </g>{" "}
                      </svg>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
                {!this.state.disable_venue_program && (
                  <div className="program_text">
                    <h3 style={{ color: this.state.general_color }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        xmlSpace="preserve"
                        width="100%"
                        height="100%"
                        version="1.0"
                        viewBox="0 0 46566 46566"
                        style={{ fill: "none", strokeWidth: "3900" }}
                        className="svg_program__ contact_info_svg"
                        data-src="/static/media/pin.97364383.svg"
                      >
                        <defs />
                        <g>
                          <g>
                            <circle
                              style={{ stroke: this.state.general_color }}
                              cx="23283"
                              cy="23283"
                              r="21166"
                            />
                            <path
                              style={{ stroke: this.state.general_color }}
                              d="M23283 12330l0 10953 -11530 6657"
                            />
                          </g>
                        </g>
                      </svg>
                      <div> Program</div>
                    </h3>
                    <p>
                      <span style={{ general_color: this.state.general_color }}>
                        {(this.state.program || []).map(
                          ({ day_st, day_end, h_st, h_end, closed }, index) => {
                            return (
                              <div
                                className="popup_program_list_item__"
                                key={"progr__" + index}
                              >
                                <div
                                  style={{
                                    ...{ color: this.state.general_color },
                                  }}
                                >
                                  {day_st}
                                  {`${day_end ? " - " + day_end : ""}`}
                                </div>
                                <div style={{ color: this.state.text_color }}>
                                  {closed ? "Închis" : `${h_st} - ${h_end}`}
                                </div>
                              </div>
                            );
                          }
                        )}
                        {/* {this.state.program_list_elements.map(
                        (node, index) => (
                          <React.Fragment
                            key={"program_reserv_element_" + index}
                          >
                            {node}
                          </React.Fragment>
                        )
                      )} */}
                      </span>
                      <label style={{ color: this.state.general_color }} />
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div id="map_canvas" style={{ height: "100%", width: "50%" }}>
              {this.state.center != undefined ? (
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyB7sJemlV7uY3yOd2qWFeNgwQHyVDQNzsQ",
                  }}
                  defaultCenter={this.state.center}
                  defaultZoom={this.state.zoom}
                  position={{
                    lat: this.state.last_screen_latitude,
                    lng: this.state.last_screen_longitude,
                  }}
                >
                  <AnyReactComponent
                    {...{
                      lat: this.state.last_screen_latitude,
                      lng: this.state.last_screen_longitude,
                      img_src: pin_map,
                    }}
                  />
                </GoogleMapReact>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ),
      footer_section: (
        <Footer
          backgroundColor={
            this.state.white_theme ? this.state.product_separator_color : null
          }
          company_name={this.state.company_name}
          cui={this.state.cui}
          reg_com={this.state.reg_com}
          company_address={this.state.company_address}
          bank_account={this.state.bank_account}
        />
      ),
    };



    return this.props.preferenceUpdate === 0 ? (
      <LayoutLoaderFixed />
    ) : (
      <div id="parent">
        <div className="App">
          <input type="hidden" id="id_restaurant" value="" />
          <div id={"fullpage"}>
            {this.state.sectionsOrdered.map((sectionName, index) => (
              <React.Fragment key={`section-rendered-${sectionName}-${index}`}>
                {sectionsToRender[sectionName] || ""}
              </React.Fragment>
            ))}
          </div>
          {Array.isArray(this.state.modals) && this.state.modals.length > 0 && (
            <Popup
              className={objectPath.get(this.state.modals, "0.modalClassName")}
            >
              {this.state.modals.map((popup_, index) => (
                <React.Fragment key={"popup__" + index}>
                  {" "}
                  {popup_.element}{" "}
                </React.Fragment>
              ))}
            </Popup>
          )}
          {this.state.cookies !== 1 && (
            <CookiesRequest
              onClick={() => {
                this.set_info_parsed_l_st_venue(
                  this.state.id_venue,
                  "cookies",
                  1
                );
                this.setState({ cookies: 1 });
              }}
              general_color={this.state.general_color}
              backgroundColor={
                this.state.white_theme
                  ? "rgba(255,255,255,0.8)"
                  : "rgba(0,0,0,0.8)"
              }
              cookiesTermsLink={this.state.cookiesTermsLink}
              color={this.state.inner_text_color}
              labelTextColor={this.state.text_color}
              borderColor={this.state.product_separator_color}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  builder: { preferences, preferenceUpdate, productCategories },
}) => ({
  preferences,
  preferenceUpdate,
  productCategories,
});
const mapDispatchToProps = (dispatch) => ({
  setLayoutLoader: (payload) => dispatch(actions.setLayoutLoader(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
