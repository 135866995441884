// @flow
import * as React from "react";
import "./HomeFooter.css";

export function Footer({
  company_name,
  cui,
  reg_com,
  company_address,
  bank_account,
  backgroundColor,
}) {
  return (
    <div id="page_footer">
      <div
        className="footer_subcontainer"
        style={backgroundColor ? { backgroundColor } : {}}
      >
        {[
          {
            label: "Nume",
            value: company_name,
          },
          {
            label: "CUI",
            value: cui,
          },
          {
            label: "Registrul comerțului",
            value: reg_com,
          },
          {
            label: "Sediu social",
            value: company_address,
          },
          {
            label: "Cont",
            value: bank_account,
          },
        ].map(({ label, value }, index) =>
          value ? (
            <div key={"footer_info_item_" + index} className="footer_item_cont">
              <div> {label}: </div>
              <div> {value} </div>
            </div>
          ) : (
            ""
          )
        )}
      </div>
      <div className="footer_middle_divider" />
      <div className="footer_bottom_container">
        <div className="footor_bottom_it mg__">
          <a href="http://anpc.ro/" target="_blank">
            ANPC
          </a>
        </div>
        <div className="footer_img_cont">
          <div className="footor_bottom_it">
            <a href="https://thefootballbrain.ro/" target="_blank">
              <div className="footer_text_by__">Powered by The Football Brain</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
